<template>
  <div
    id="page-container"
    class="flex flex-col mx-auto w-full min-h-screen min-w-[320px] bg-gray-100 dark:text-gray-100 dark:bg-gray-900"
    :class="{
      'lg:pl-64': store.desktopSidebarOpen,
    }"
  >
    <PageSidebarComponent />
    <PageHeaderComponent />
    <main class="relative flex flex-auto flex-col max-w-full bg-ttp-bg-theme pt-16">
      <div class="lg:pb-8 xl:max-w-7xl container mx-auto px-4 pt-4 w-full">
        <RouterView />
      </div>
    </main>
    <PageFooterComponent />
  </div>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import PageSidebarComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageSidebarComponent.vue";
import PageHeaderComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageHeaderComponent.vue";
import PageFooterComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageFooterComponent.vue";
import { useSharedStore } from "@/stores/PoliceCommandCenter/useSharedStore";

const store = useSharedStore();
</script>
