<script lang="ts">
import { defineComponent } from "vue";

import { usePasswordSettingStore } from "./stores/usePasswordSettingStore";
import type { $string } from "@/configs/types/Shared/typeShare";

import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { fetchAuth } from "@/configs/fetches/PoliceCommandCenter/fetchAuth";
import { useUserFormStore } from "../UserManagement/stores/useUserFormStore";
import { isCommandCenterApp, setLocalStorage } from "@/configs/helpers/MainHelper";
import { useAuthStore as useAuthPoliceCommandCenterStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useAuthStore as useAuthPoliceTouristAndAgentStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { AUTH_TOKEN_KEY as PTAA_AUTH_TOKEN_KEY } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { AUTH_TOKEN_KEY as PCC_AUTH_TOKEN_KEY } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";

export default defineComponent({
  name: "PasswordSettingView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          popup: {
            success: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.passwordSettingStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.confirm_detail"),
                functionProp: () => {
                  this.passwordSettingStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.passwordSettingStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
          },
          input: {
            // nowPassword: {
            //   component: FormInputDefaultComponent,
            //   props: {
            //     isSpecial: false,
            //     isVisible: true,
            //     isError: (this.passwordSettingStore.error.nowPassword || "").length > 0,
            //     isIcon: false,
            //     isDisable: this.loading(),
            //     isRequired: this.passwordSettingStore.isRequired.nowPassword.is,
            //     textError: this.$t(this.passwordSettingStore.isRequired.nowPassword.placeholder),
            //     title: this.$t(this.passwordSettingStore.isRequired.nowPassword.label),
            //     placeholder: this.$t(this.passwordSettingStore.isRequired.nowPassword.placeholder),
            //     value: this.passwordSettingStore.result.nowPassword,
            //     name: this.passwordSettingStore.isRequired.nowPassword.name,
            //     type: "password",
            //   } as typeof FormInputDefaultComponent.props,
            //   onUpdateValue: (value: $string) => {
            //     this.passwordSettingStore.result.nowPassword = value;
            //   },
            // },
            newPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.passwordSettingStore.error.newPassword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.passwordSettingStore.isRequired.newPassword.is,
                textError: this.$t(this.passwordSettingStore.isRequired.newPassword.placeholder),
                title: this.$t(this.passwordSettingStore.isRequired.newPassword.label),
                placeholder: this.$t(this.passwordSettingStore.isRequired.newPassword.placeholder),
                value: this.passwordSettingStore.result.newPassword,
                name: this.passwordSettingStore.isRequired.newPassword.name,
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.passwordSettingStore.result.newPassword = value;
              },
            },
            repeatPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.passwordSettingStore.error.repeatPassword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.passwordSettingStore.isRequired.repeatPassword.is,
                textError: this.$t(this.passwordSettingStore.isRequired.repeatPassword.placeholder),
                title: this.$t(this.passwordSettingStore.isRequired.repeatPassword.label),
                placeholder: this.$t(this.passwordSettingStore.isRequired.repeatPassword.placeholder),
                value: this.passwordSettingStore.result.repeatPassword,
                name: this.passwordSettingStore.isRequired.repeatPassword.name,
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.passwordSettingStore.result.repeatPassword = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                const result = await this.passwordSettingStore.onSubmit(e);
                if (result) {
                  const auth = getAuth();
                  signInWithCustomToken(auth, result?.token).then(async (userCredential) => {
                    this.authStore.token = result?.token;
                    const user = userCredential.user;
                    const key = this.$isCommandCenterApp ? PCC_AUTH_TOKEN_KEY : PTAA_AUTH_TOKEN_KEY;

                    await setLocalStorage(key, result?.token);
                    await setLocalStorage(`${this.storeName}:getProjectType`, this.$getProjectType());
                    await setLocalStorage(
                      key,
                      JSON.stringify({
                        email: user?.email,
                        phoneNumber: user?.phoneNumber,
                        displayName: user?.displayName,
                        provider: "EMAIL",
                      })
                    );
                    this.$router.push({ name: "ResetPasswordView" });
                  });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.passwordSettingStore.loading;
    },
    async fetch() {
      this.userFormStore.loading = true;

      await Promise.all([
        (async () => {
          const fetch = new fetchAuth();
          const user = await fetch.Get();
          this.userFormStore.result = user;
        })(),
      ]);
      this.userFormStore.loading = false;
    },
    async reset() {
      await this.userFormStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const passwordSettingStore = usePasswordSettingStore();
    const userFormStore = useUserFormStore();
    const authStore = isCommandCenterApp ? useAuthPoliceCommandCenterStore() : useAuthPoliceTouristAndAgentStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const storeName = isCommandCenterApp ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent;
    return {
      passwordSettingStore,
      userFormStore,
      authStore,
      sharedStore,
      storeName,
    };
  },
});
</script>

<template>
  <div class="flex flex-col md:flex-row gap-2">
    <div :class="{ 'w-full mt-0': true, 'ttp-layout': !$isCommandCenterApp }">
      <div v-if="!$isCommandCenterApp" class="ttp-page-header-1">
        <h2>{{ $t(($route?.meta as any).i18n) }}</h2>
      </div>

      <div>
        <div class="grid grid-cols-12 gap-2 px-4 pb-4 pt-10">
          <!-- <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.nowPassword.component" v-bind="defaultFormComponents.form.input.nowPassword.props" @updateValue="defaultFormComponents.form.input.nowPassword.onUpdateValue" /> -->
          <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.newPassword.component" v-bind="defaultFormComponents.form.input.newPassword.props" @updateValue="defaultFormComponents.form.input.newPassword.onUpdateValue" />
          <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.repeatPassword.component" v-bind="defaultFormComponents.form.input.repeatPassword.props" @updateValue="defaultFormComponents.form.input.repeatPassword.onUpdateValue" />
          <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ $t("caution.password_1") }}</small>
        </div>
        <div class="ttp-between p-4 border-t-2">
          <div class="flex gap-2">
            <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <component v-if="passwordSettingStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.popup.success.component" v-bind="defaultFormComponents.form.popup.success.props" />
</template>
