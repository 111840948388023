<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";

const { isOpen = false } = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits(["onClose"]);
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="emit('onClose')" class="relative z-90">
      <!-- Modal Backdrop -->
      <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/75 backdrop-blur-sm z-40" />
      </TransitionChild>
      <!-- END Modal Backdrop -->

      <!-- Modal Dialog -->
      <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8 z-50 mt-20">
        <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0 scale-125" enter-to="opacity-100 scale-100" leave="ease-in duration-100" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-125">
          <DialogPanel class="mx-auto flex w-full max-w-md flex-col rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
            <div class="flex items-center justify-between px-5 py-4 dark:bg-gray-700/50 relative">
              <h3 class="font-medium">ตัวอย่างการเขียนรายงาน</h3>
              <div class="absolute -top-3 -right-3">
                <button @click="emit('onClose')" type="button" class="inline-flex items-center justify-center gap-2 rounded-full border border-gray-400 bg-white px-3 py-2 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
                  <svg class="hi-solid hi-x -mx-1 inline-block size-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="grow px-5 pb-5 pt-2">
              <p class="text-sm">
                รายละเอียดต้องประกอบด้วย
              </p>
              <ol class="list-decimal text-sm">
                <li>ผู้ประสบเหตุ ชื่อ อายุ สัญชาติ เลขที่พาสปอร์ต ประเภทวีซ่า หมายเลขโทรศัพท์</li>
                <li>จุดเกิดเหตุ</li>
                <li>เวลาที่เกิดเหตุ</li>
                <li>รายละเอียดเหตุ</li>
                <li>ผลการดำเนินการ</li>
                <li>ขั้นตอนการดำเนินการ</li>
                <li>พนักงานสอบสวน(ถ้ามี)</li>
                <li>แพทย์เวร(ถ้ามี)</li>
              </ol>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
      <!-- END Modal Dialog -->
    </Dialog>
  </TransitionRoot>
</template>
