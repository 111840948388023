<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="emit('close')" class="relative">
      <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/75 backdrop-blur-sm modal-background" />
      </TransitionChild>
      <!-- END Modal Backdrop -->

      <!-- Modal Dialog -->
      <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8 modal-dialog">
        <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0 scale-125" enter-to="opacity-100 scale-100" leave="ease-in duration-100" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-125">
          <DialogPanel class="mx-auto flex w-full max-w-md flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
            <div class="flex items-center justify-between bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
              <h3 class="font-medium">ประวัติการจัดการ (หมายเลขรายงาน {{ incidentNumber }})</h3>
              <div class="-my-4">
                <button @click="emit('close')" type="button" class="inline-flex items-center justify-center gap-2 rounded-lg border border-transparent px-3 py-2 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
                  <svg class="hi-solid hi-x -mx-1 inline-block size-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="grow p-5">
              <div class="relative py-5 dark:text-gray-100">
                <div class="absolute bottom-0 left-0 top-0 flex w-10 flex-col justify-center md:w-12" aria-hidden="true">
                  <div class="mx-auto h-2.5 w-1 grow-0 rounded-t bg-gradient-to-b from-transparent to-teal-100 dark:to-teal-900"></div>
                  <div class="mx-auto w-1 grow bg-teal-100 dark:bg-teal-900"></div>
                  <div class="mx-auto h-2.5 w-1 grow-0 rounded-b bg-gradient-to-t from-transparent to-teal-100 dark:to-teal-900"></div>
                </div>

                <ul class="relative space-y-4 pl-10 md:pl-12">
                  <li class="relative" v-for="(item, index) in progress" :key="item.id">
                    <div class="absolute bottom-0 left-0 top-0 mt-5 flex w-10 -translate-x-full justify-center md:w-12">
                      <div class="size-3 rounded-full bg-teal-500 ring ring-teal-100 ring-offset-2 dark:bg-teal-300 dark:ring-teal-900 dark:ring-offset-gray-900"></div>
                    </div>
                    <div class="rounded-xl bg-gray-100 p-4 hover:ring hover:ring-gray-100 hover:ring-offset-2 dark:bg-gray-800 dark:ring-offset-gray-900 dark:hover:ring-gray-700">
                      <BadgeDefaultComponent :is-special="false" :size="ENUM_SIZE.NORMAL" :title="$formattedStatus($classStatus(item.status))" :classEnumName="$classStatus(item.status)" />
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">ผู้บันทึกข้อมูล : {{ item?.createdBy?.content[0]?.shortName || "-" }}</div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">ผลการดำเนินการ : {{ item?.detail || "-" }}</div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">เวลาบันทึกข้อมูล : {{ item?.createdAt ? dayjs(item?.createdAt).format("DD/MM/YYYY HH:mm") : "-" }}</div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">ระยะเวลา : {{ progress?.[index + 1] ? dayjs(progress?.[index + 1].createdAt).from(dayjs(item.createdAt), true) :  "-" }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import { ENUM_SIZE } from "@/configs/enums/enum";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

defineProps<
  {
    isOpen: boolean;
    incidentNumber: string;
  } & Pick<typeGetIncident, "progress">
>();

const emit = defineEmits(["close"]);
</script>

<style lang="css" scoped>
.modal-background {
  z-index: 99;
}

.modal-dialog {
  z-index: 100;
}
</style>
