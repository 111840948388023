<template>
  <div v-if="display" class="voice-dialog">
    <div class="flex flex-col items-center justify-center gap-2 w-full h-full px-10 bg-image">
      <h2 class="text-black text-center ttp-text-4xl font-bold">
        {{ $t("page.VoiceCallView.hungUp.hero") }}
      </h2>

      <IconVoiceCallComponent style="width: 200px; height: 200px" />

      <p>{{ timer }}</p>

      <h3 class="text-black text-center ttp-text-2xl font-bold mb-4">
        {{ $t("page.VoiceCallView.hungUp.title") }}
      </h3>
      <p class="font-bold text-center">{{ $t("page.VoiceCallView.hungUp.thankYou.title") }}</p>
      <p class="text-center mb-4">{{ $t("page.VoiceCallView.hungUp.thankYou.reportOn") }} {{ incident?.incidentNumber }}</p>
      <p class="text-center">{{ $t("page.VoiceCallView.hungUp.thankYou.description") }}</p>

      <div class="mt-10 grid grid-cols-2 gap-4 w-full">
        <ButtonDefaultComponent :title="$t('page.VoiceCallView.hungUp.button.confirm')" :class-enum-name="ENUM_COLOR.DEFAULT_1" :to="{ name: 'HistoryDetailPoliceTouristAndAgentView', params: { id: incident?.id || '-' } }" tag="router-link" class-tag-name="w-full" @click="hide" />
        <ButtonDefaultComponent :title="$t('page.VoiceCallView.hungUp.button.callAgain')" :class-enum-name="ENUM_COLOR.RED" tag="a" href="tel:1155" class-tag-name="w-full" @click="openHistoryPage">
          <template #icon><PhoneIcon style="height: 1.5em" class="mr-2" /></template>
        </ButtonDefaultComponent>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ENUM_COLOR } from "@/configs/enums/enum";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import ButtonDefaultComponent from "../Forms/Button/ButtonDefaultComponent.vue";
import IconVoiceCallComponent from "@/components/Svgs/IconVoiceCallComponent.vue";
import { PhoneIcon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const incident = ref<typeGetIncident | null>(null);
const counter = ref<number>(0);
const display = ref<boolean>(false);

const timer = computed((): string => {
  const h: number = Math.floor(counter.value.valueOf() / 3600);
  const m: number = Math.floor((counter.value.valueOf() % 3600) / 60);
  const s: number = counter.value.valueOf() % 60;
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
});

const hide = () => {
  display.value = false;
};

const open = (data: { incident: typeGetIncident; counter: number }) => {
  incident.value = data.incident;
  counter.value = data.counter;
  display.value = true;
};

const openHistoryPage = () => {
  router.push({ name: "HistoryDetailPoliceTouristAndAgentView", params: { id: incident.value?.id || "-" } });
};

defineExpose({ open });
</script>
<style scoped>
.voice-dialog {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
}
</style>
