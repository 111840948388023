<template>
  <div class="px-6 pb-6 pt-20 md:pt-24">
    <div class="rounded border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
      <table class="align-middle text-base w-full">
        <thead>
          <tr>
            <th colspan="2" class="px-5 py-4 font-normal text-left">
              <router-link :to="{ name: 'AppraisalList' }">
                <ChevronLeftIcon class="flex-none inline-block ttp-icon-inside-box-02" />
              </router-link>
              <span>{{ ssaStore.appraisal?.name }}</span>
            </th>
            <th class="font-normal text-left">
              <router-link
                :to="{ name: 'AppraisalForm', params: { appraisal: route.params.appraisal } }"
                class="inline-flex items-center justify-center gap-2 rounded-lg border border-blue-700 bg-blue-700 px-2 py-1 text-sm font-semibold leading-5 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90 mb-4 mr-4 text-center"
              >
                ทำการประเมิน
              </router-link>
            </th>
          </tr>
          <tr class="border-b border-gray-100 dark:border-gray-700/50">
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">สถานที่ออกตรวจ</th>
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">วันเวลาที่ตรวจสอบ</th>
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in ssaStore.result.items" :key="item.id" class="border-b border-gray-100 dark:border-gray-700/50">
            <td class="p-3">{{ item.area }}<br><span class="text-xs text-light italic">แก้ไขล่าสุดเมื่อ {{ item.updatedAt ? dayjs(item.updatedAt).format("DD/MM/YYYY HH:mm") : "-" }}</span></td>
            <td class="p-3">{{ item.submitAt ? dayjs(item.submitAt).format("DD/MM/YYYY HH:mm") : "-" }}</td>
            <td class="p-3 text-center">
              <router-link
                :to="{ name: 'AppraisalResultDetail', params: { appraisal: route.params.appraisal, result:item.id } }"
                :class="{
                  ['border-yellow-700 bg-yellow-700  hover:border-yellow-600 hover:bg-yellow-600 focus:ring-yellow-400/50 active:border-yellow-700 active:bg-yellow-700 dark:focus:ring-yellow-400/90']: item.isDraft,
                  ['border-blue-700 bg-blue-700  hover:border-blue-600 hover:bg-blue-600 focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90']: !item.isDraft,
                }"
                class="inline-flex items-center justify-center gap-2 rounded-lg border px-2 py-1 text-sm font-semibold leading-5 text-white hover:text-white focus:ring mb-4 mr-4 text-center"
              >
                <template v-if="item.isDraft">แก้ไขข้อมูล</template>
                <template v-else>ดูข้อมูล</template>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSafetyStandardAppraisalStore } from "@/stores/PoliceTouristAndAgent/useSafetyStandardAppraisalStore";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";

dayjs.extend(utc);
dayjs.extend(timezone);

const route = useRoute();

const ssaStore = useSafetyStandardAppraisalStore();

onMounted(() => {
  ssaStore.findAppraisal((route.params?.appraisal || "") as string);
  ssaStore.getAppraisalResults((route.params?.appraisal || "") as string);
});
</script>
