<script lang="ts" setup>
import { computed } from "vue";
import { ENUM_SIZE, ENUM_COLOR } from "@/configs/enums/enum";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import type { RouteLocationRaw } from "vue-router";

type Prop = {
  tag?: "a" | "button" | "router-link";
  to?: RouteLocationRaw;
  isVisible?: boolean;
  isIcon?: boolean;
  isDisable?: boolean;
  isLoading?: boolean;
  isSubmit?: boolean;
  htmlIcon?: string;
  componentIcon?: Object | Function;
  classIcon?: string;
  title?: string;
  classEnumName?: string;
  classTagName?: string;
  size?: string;
  functionProp?: Function;
};

const props = withDefaults(defineProps<Prop>(), {
  tag: "button",
  isVisible: true,
  isIcon: false,
  isDisable: false,
  isLoading: false,
  isSubmit: false,
  htmlIcon: "",
  componentIcon: () => {},
  classIcon: "",
  title: "",
  classEnumName: ENUM_COLOR.DEFAULT_1,
  classTagName: "",
  size: ENUM_SIZE.NORMAL,
  functionProp: () => {},
});

const classEnumName = computed(() => {
  switch (props.classEnumName) {
    case ENUM_COLOR.DEFAULT_1:
      return "text-white bg-ttp-primary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-primary-20 focus:bg-ttp-primary active:bg-ttp-primary-20 rounded-lg";
    case ENUM_COLOR.DEFAULT_2:
      return "text-white bg-ttp-info disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-info-20 focus:bg-ttp-info active:bg-ttp-info-20 rounded-lg";
    case ENUM_COLOR.DARK:
      return "text-white bg-ttp-secondary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-secondary-20 focus:bg-ttp-secondary active:bg-ttp-secondary-20 rounded-lg";
    case ENUM_COLOR.BLACK:
      return "text-white bg-black disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-black-20 focus:bg-black active:bg-black-20 rounded-lg";
    case ENUM_COLOR.GREEN:
      return "text-white bg-ttp-success disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-success-20 focus:bg-ttp-success active:bg-ttp-success-20 rounded-lg";
    case ENUM_COLOR.RED:
      return "text-white bg-ttp-error disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-error-20 focus:bg-ttp-error active:bg-ttp-error-20 rounded-lg";
    case ENUM_COLOR.YELLOW:
      return "text-white bg-ttp-warning disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-warning-20 focus:bg-ttp-warning active:bg-ttp-warning-20 rounded-lg";
    case ENUM_COLOR.GRAY:
      return "text-ttp-text-secondary bg-ttp-new disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-new-20 focus:bg-ttp-new active:bg-ttp-new-20 rounded-lg";
    case ENUM_COLOR.NONE:
      return "disabled:cursor-not-allowed disabled:opacity-50";
    case ENUM_COLOR.OPACITY:
      return "text-black border border-gray-400 disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled rounded-lg";
    default:
      return "text-white bg-ttp-primary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-primary-20 focus:bg-ttp-primary active:bg-ttp-primary-20 rounded-lg";
  }
});

const size = computed(() => {
  if (props.classEnumName == ENUM_COLOR.NONE) {
    return "px-2";
  }

  switch (props.size) {
    case ENUM_SIZE.SMALL:
      return "ttp-text-xs px-2.5 py-2.5";
    case ENUM_SIZE.NORMAL:
      return "ttp-text-sm px-2.5 py-2.5";
    case ENUM_SIZE.LARGE:
      return "ttp-text-md px-2.5 py-2.5";
    default:
      return "ttp-text-base px-2.5 py-2.5";
  }
});
</script>

<template>
  <component v-if="isVisible" class="inline-block" :to="to" :is="tag" :type="isSubmit ? 'submit' : 'button'" :disabled="isDisable" :class="`${[classEnumName, classTagName, size, 'z-0'].join(' ')}`" @click="props.functionProp" @touchstart="props.functionProp">
    <div class="flex justify-center items-center">
      <div v-if="isLoading">
        <LoadingFormComponent />
      </div>
      <slot v-else-if="$slots.icon" name="icon" />
      <span v-else-if="htmlIcon && isIcon" v-html="htmlIcon" />
      <span v-else-if="isIcon">
        <component v-if="isIcon" :is="componentIcon" :class="classIcon" />
      </span>
      <span v-if="title" class="text-center">{{ title }}</span>
    </div>
  </component>
</template>
