<script lang="ts">
import { defineComponent, reactive } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useNewsInternalSearchStore } from "./stores/useNewsInternalSearchStore";
import type { $string } from "@/configs/types/Shared/typeShare";
import { useInfiniteScroll } from "@vueuse/core";
import _ from "lodash";
import { groupNewsByContentDate } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "NewsInternalView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href,
      profile_image: "" as any,
      isVisibleSearch: false,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.newsSearchStore.error.keyword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                classIcon: "ttp-icon-inside-box-03 text-[#2C3483]",
                componentIcon: this.$solidIcons.NewspaperIcon,
                isDisable: this.loading(),
                isRequired: this.newsSearchStore.isRequired.keyword.is,
                textError: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.newsSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                value: this.newsSearchStore.result.keyword,
                name: this.newsSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: async (value: $string) => {
                this.newsSearchStore.result.keyword = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async newsFetch() {
      if (!this.loading()) {
        this.newsSearchStore.currentPage = this.newsSearchStore.currentPage + 1;

        let results = (await this.newsSearchStore.onUpdateCurrentPage(this.newsSearchStore.currentPage)) as any;

        if (!this.newsSearchStore.tableScrollResults) {
          this.newsSearchStore.tableResults = results;
          this.newsSearchStore.tableScrollResults = _.cloneDeep(results);
          this.newsSearchStore.totalPage = results?.totalPages;
        } else {
          this.newsSearchStore.tableResults.items.push(...results?.items);
          this.newsSearchStore.tableResults.totalPage = this.newsSearchStore.tableResults.totalPages;
          results = this.newsSearchStore.tableResults;
        }

        await results?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.newsSearchStore?.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);

        if (results?.items) {
          this.newsSearchStore.tableScrollResults.items = await groupNewsByContentDate(results?.items);
          this.newsSearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
          this.newsSearchStore.loading = false;
        }
      }
    },
    async newsScrollFetch() {
      useInfiniteScroll(
        this.el,
        () => {
          if (this.newsSearchStore.totalPage > this.newsSearchStore.currentPage) this.newsFetch();
        },
        { distance: 10 }
      );
      // return stop;
    },
    async newsSearchFetch() {
      if (!this.loading()) this.newsSearchStore.onUpdateSearchCurrentPage(this.newsSearchStore.currentPage);
    },
    loading() {
      return this.newsSearchStore.loading;
    },
    async reset() {
      await this.newsSearchStore.$reset();
    },
    async setting() {
      await this.newsFetch();
      await this.newsScrollFetch();
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.reset();
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const newsSearchStore = useNewsInternalSearchStore();

    var state = reactive({
      el: null,
    });

    return {
      authStore,
      masterDataStore,
      newsSearchStore,
      el: state.el,
      state,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div ref="el" class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto pb-20 mt-24">
            <div class="flex">
              <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
              <button
                @click="
                  () => {
                    newsSearchStore.currentPage = 0;
                    newsSearchStore.tableScrollResults = null;
                    newsSearchStore.tableResults = null;
                    newsFetch();
                  }
                "
                class="w-10 text-[#2C3483] text-right"
              >
                <div>
                  <component :is="$solidIcons.MagnifyingGlassIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
                </div>
              </button>
            </div>

            <div v-if="newsSearchStore?.tableResults?.items?.length" v-for="(result, date) in newsSearchStore?.tableScrollResults?.items" :key="date">
              <template v-if="result.length">
                <div class="flex w-full gap-2 mb-2">
                  <span class="ttp-text-md font-bold">Date</span><time>{{ $dayjs(new Date(date)).format("DD MMMM YYYY") }}</time>
                </div>
                <div class="grid md:grid-cols-2 grid-cols-1 gap-2">
                  <router-link :to="{ name: 'NewsInternalDetailView', params: { id: data?.id } }" v-for="data in result" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                    <div class="relative w-full overflow-hidden">
                      <div :class="{ 'absolute inset-0': true, 'w-[90px] m-auto': !data?.thumbnail[0]?.url, 'w-full h-36': data?.thumbnail[0]?.url }">
                        <img v-if="data?.thumbnail[0]?.url" :src="data?.thumbnail[0]?.url" alt="รูปภาพ" class="object-cover w-full h-full" />
                        <img v-else="image_url" :src="image_url" alt="รูปภาพ" class="object-cover w-full h-full" />
                      </div>
                    </div>
                    <div class="flex flex-col w-full justify-between py-2">
                      <time class="w-full ttp-text-xs text-[#4E4E4E] font-bold">{{ $dayjs(new Date(data?.contentAt)).format("DD MMMM YYYY") }}</time>
                      <div class="w-full ttp-text-sm">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.title) }}</div>
                    </div>
                  </router-link>
                </div>
              </template>
            </div>
            <div v-else-if="!newsSearchStore?.tableResults?.items?.length && newsSearchStore.currentPage == 1 && !newsSearchStore.loading" class="flex flex-col h-full justify-center items-center">
              <div class="text-center">
                <component :is="$outlineIcons.NewspaperIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.NewsView.not_found") }}</div>
              </div>
            </div>
            <div v-if="loading()" class="flex flex-col justify-center items-center w-full">
              <div><LoadingFormComponent /></div>
              <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
