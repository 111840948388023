<script lang="ts">
import { defineComponent } from "vue";
import { useSettingSecurityPrivacyChangePasswordStore } from "./stores/useSettingSecurityPrivacyChangePasswordStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { storeName } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { AUTH_TOKEN_KEY } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";

export default defineComponent({
  name: "SettingSecurityPrivacyChangePasswordView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          popup: {
            success: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.settingSecurityPrivacyChangePasswordStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.confirm_detail"),
                functionProp: () => {
                  this.settingSecurityPrivacyChangePasswordStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.settingSecurityPrivacyChangePasswordStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            caution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.settingSecurityPrivacyChangePasswordStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.error_detail"),
                functionProp: () => {
                  this.settingSecurityPrivacyChangePasswordStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.settingSecurityPrivacyChangePasswordStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
          input: {
            // nowPassword: {
            //   component: FormInputDefaultComponent,
            //   props: {
            //     isSpecial: false,
            //     isVisible: true,
            //     isError: (this.settingSecurityPrivacyChangePasswordStore.error.nowPassword || "").length > 0,
            //     isIcon: false,
            //     isLoading: this.loading(),
            //     isDisable: this.loading(),
            //     isRequired: this.settingSecurityPrivacyChangePasswordStore.isRequired.nowPassword.is,
            //     textError: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.nowPassword.placeholder),
            //     title: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.nowPassword.label),
            //     placeholder: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.nowPassword.placeholder),
            //     value: this.settingSecurityPrivacyChangePasswordStore.result.nowPassword,
            //     name: this.settingSecurityPrivacyChangePasswordStore.isRequired.nowPassword.name,
            //     type: "password",
            //   } as typeof FormInputDefaultComponent.props,
            //   onUpdateValue: (value: $string) => {
            //     this.settingSecurityPrivacyChangePasswordStore.result.nowPassword = value;
            //   },
            // },
            newPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingSecurityPrivacyChangePasswordStore.error.newPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.settingSecurityPrivacyChangePasswordStore.isRequired.newPassword.is,
                textError: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.newPassword.placeholder),
                title: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.newPassword.label),
                placeholder: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.newPassword.placeholder),
                value: this.settingSecurityPrivacyChangePasswordStore.result.newPassword,
                name: this.settingSecurityPrivacyChangePasswordStore.isRequired.newPassword.name,
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.settingSecurityPrivacyChangePasswordStore.result.newPassword = value;
              },
            },
            repeatPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingSecurityPrivacyChangePasswordStore.error.repeatPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.settingSecurityPrivacyChangePasswordStore.isRequired.repeatPassword.is,
                textError: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.repeatPassword.placeholder),
                title: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.repeatPassword.label),
                placeholder: this.$t(this.settingSecurityPrivacyChangePasswordStore.isRequired.repeatPassword.placeholder),
                value: this.settingSecurityPrivacyChangePasswordStore.result.repeatPassword,
                name: this.settingSecurityPrivacyChangePasswordStore.isRequired.repeatPassword.name,
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.settingSecurityPrivacyChangePasswordStore.result.repeatPassword = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                const result = await this.settingSecurityPrivacyChangePasswordStore.onSubmit(e);
                if (result) {
                  const auth = getAuth();
                  signInWithCustomToken(auth, result?.token).then(async (userCredential) => {
                    this.authStore.token = result?.token;
                    const user = userCredential.user;

                    await setLocalStorage(AUTH_TOKEN_KEY, result?.token);
                    await setLocalStorage(`${storeName}:getProjectType`, this.$getProjectType());
                    await setLocalStorage(
                      AUTH_TOKEN_KEY,
                      JSON.stringify({
                        email: user?.email,
                        phoneNumber: user?.phoneNumber,
                        displayName: user?.displayName,
                        provider: "EMAIL",
                      })
                    );
                  });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.settingSecurityPrivacyChangePasswordStore.loading;
    },
  },
  setup() {
    const settingSecurityPrivacyChangePasswordStore = useSettingSecurityPrivacyChangePasswordStore();
    const authStore = useAuthStore();
    return {
      settingSecurityPrivacyChangePasswordStore,
      authStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'SettingMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.SettingSecurityPrivacyChangePasswordView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="w-full mt-0 p-4">
              <div>
                <div class="grid grid-cols-12 gap-2 py-4">
                  <!-- <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.nowPassword.component" v-bind="defaultFormComponents.form.input.nowPassword.props" @updateValue="defaultFormComponents.form.input.nowPassword.onUpdateValue" /> -->
                  <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.newPassword.component" v-bind="defaultFormComponents.form.input.newPassword.props" @updateValue="defaultFormComponents.form.input.newPassword.onUpdateValue" />
                  <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.repeatPassword.component" v-bind="defaultFormComponents.form.input.repeatPassword.props" @updateValue="defaultFormComponents.form.input.repeatPassword.onUpdateValue" />
                  <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ $t("caution.password_1") }}</small>
                </div>
                <div class="ttp-between border-t-2 pt-4">
                  <div class="flex gap-2 w-full">
                    <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="settingSecurityPrivacyChangePasswordStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.popup.success.component" v-bind="defaultFormComponents.form.popup.success.props" />
  <component v-else-if="settingSecurityPrivacyChangePasswordStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.popup.caution.component" v-bind="defaultFormComponents.form.popup.caution.props" />
</template>
