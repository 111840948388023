import { ENUM_CHANNEL, ENUM_GENDER, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { fetchMasterData } from "@/configs/fetches/PoliceCommandCenter/fetchMasterData";
import { fetchReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import type { typeGetIncidentCategory } from "@/configs/types/Incident/typeIncidentCategory";
import type { typeGetIncidentTopic } from "@/configs/types/Incident/typeIncidentTopic";
import type { typeGetNationality } from "@/configs/types/MasterData/typeNationality";
import type { typeGetOrganization } from "@/configs/types/MasterData/typeOrganization";
import type { typeGetProvince } from "@/configs/types/MasterData/typeProvince";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { defineStore } from "pinia";

dayjs.extend(timezone)
dayjs.extend(utc)

export type TimeGroup = 'day' | 'month' | 'year'

type GeneralReport<T> = {
  type?: T
  total: number
}

type ChannelCounter = {
  [ENUM_CHANNEL.APPLICATION]: number;
  [ENUM_CHANNEL._1155]: number;
  [ENUM_CHANNEL.WALK_IN]: number;
}

type ChannelGroup = {
  groupName: string;
  report: ChannelCounter;
}

type State = {
  nationalities: typeGetNationality[]
  incidentCategories: typeGetIncidentCategory[]
  incidentTopics: typeGetIncidentTopic[]
  provinces: typeGetProvince[]
  organizations: typeGetOrganization[]
  timeGroup: TimeGroup
  channelCounterReport: ChannelCounter
  channelReport: ChannelGroup[]
  statusReport: {
    [ENUM_INCIDENT_STATUS.NEW]: number;
    [ENUM_INCIDENT_STATUS.ACCEPTED]: number;
    [ENUM_INCIDENT_STATUS.WAITING]: number;
    [ENUM_INCIDENT_STATUS.IN_PROGRESS]: number;
    [ENUM_INCIDENT_STATUS.COMPLETED]: number;
    [ENUM_INCIDENT_STATUS.IN_REVIEW]: number;
    [ENUM_INCIDENT_STATUS.REPORTED]: number;
    [ENUM_INCIDENT_STATUS.CLOSED]: number;
    [ENUM_INCIDENT_STATUS.REJECTED]: number;
  }
  genderReport: {
    [ENUM_GENDER.MALE]: number;
    [ENUM_GENDER.FEMALE]: number;
    [ENUM_GENDER.NONE]: number;
  }
  nationalityReport: GeneralReport<typeGetNationality>[]
  incidentCategotyReport: GeneralReport<typeGetIncidentCategory>[]
  incidentTopicReport: GeneralReport<typeGetIncidentTopic>[]
  incidentProvinceReport: GeneralReport<typeGetProvince>[]
  responsibleAreaReport: GeneralReport<typeGetOrganization>[]
}

const fetch = {
  report: new fetchReport(),
  masterData: new fetchMasterData()
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): State => ({
    nationalities: [],
    incidentCategories: [],
    incidentTopics: [],
    provinces: [],
    organizations: [],
    timeGroup: 'day',
    channelCounterReport: {
      [ENUM_CHANNEL.APPLICATION]: 0,
      [ENUM_CHANNEL._1155]: 0,
      [ENUM_CHANNEL.WALK_IN]: 0,
    },
    channelReport: [],
    statusReport: {
      [ENUM_INCIDENT_STATUS.NEW]: 0,
      [ENUM_INCIDENT_STATUS.ACCEPTED]: 0,
      [ENUM_INCIDENT_STATUS.WAITING]: 0,
      [ENUM_INCIDENT_STATUS.IN_PROGRESS]: 0,
      [ENUM_INCIDENT_STATUS.COMPLETED]: 0,
      [ENUM_INCIDENT_STATUS.IN_REVIEW]: 0,
      [ENUM_INCIDENT_STATUS.REPORTED]: 0,
      [ENUM_INCIDENT_STATUS.CLOSED]: 0,
      [ENUM_INCIDENT_STATUS.REJECTED]: 0,
    },
    genderReport: {
      [ENUM_GENDER.MALE]: 0,
      [ENUM_GENDER.FEMALE]: 0,
      [ENUM_GENDER.NONE]: 0,
    },
    nationalityReport: [],
    incidentCategotyReport: [],
    incidentTopicReport: [],
    incidentProvinceReport: [],
    responsibleAreaReport: [],
  }),
  actions: {
    setTimeGroup(timeGroup: TimeGroup) {
      this.timeGroup = timeGroup
    },
    async getChannelCounterReport(startDate: Dayjs, endDate: Dayjs, categoryCode?: string, topicCodes?: string[]) {
      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        categoryCode,
        topicCode: topicCodes?.join(','),
      }, 'channel', this.timeGroup)

      this.channelCounterReport = Object.keys(data).reduce((r, key: string) => {
        r[ENUM_CHANNEL.APPLICATION] += data[key][ENUM_CHANNEL.APPLICATION] || 0
        r[ENUM_CHANNEL._1155] += data[key][ENUM_CHANNEL._1155] || 0
        r[ENUM_CHANNEL.WALK_IN] += data[key][ENUM_CHANNEL.WALK_IN] || 0

        return r
      }, {
        [ENUM_CHANNEL.APPLICATION]: 0,
        [ENUM_CHANNEL._1155]: 0,
        [ENUM_CHANNEL.WALK_IN]: 0,
      })
    },
    async getChannelReport(startDate: Dayjs, endDate: Dayjs, format: string) {
      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }, 'channel', this.timeGroup)

      this.channelReport = Object.keys(data).reduce<ChannelGroup[]>((r, d: string) => {
        const f = dayjs(d).tz('Asia/Bangkok').format(format)
        const group = {
          groupName: f,
          report: {
            [ENUM_CHANNEL.APPLICATION]: data[d][ENUM_CHANNEL.APPLICATION] || 0,
            [ENUM_CHANNEL._1155]: data[d][ENUM_CHANNEL._1155] || 0,
            [ENUM_CHANNEL.WALK_IN]: data[d][ENUM_CHANNEL.WALK_IN] || 0,
          },
        }

        r.push(group)
        return r
      }, [])
    },
    async getStatusReport(startDate: Dayjs, endDate: Dayjs) {
      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }, 'status', this.timeGroup)

      this.statusReport = Object.keys(data).reduce((r, key: string) => {
        r[ENUM_INCIDENT_STATUS.NEW] += data[key][ENUM_INCIDENT_STATUS.NEW] || 0
        r[ENUM_INCIDENT_STATUS.ACCEPTED] += data[key][ENUM_INCIDENT_STATUS.ACCEPTED] || 0
        r[ENUM_INCIDENT_STATUS.WAITING] += data[key][ENUM_INCIDENT_STATUS.WAITING] || 0
        r[ENUM_INCIDENT_STATUS.IN_PROGRESS] += data[key][ENUM_INCIDENT_STATUS.IN_PROGRESS] || 0
        r[ENUM_INCIDENT_STATUS.COMPLETED] += data[key][ENUM_INCIDENT_STATUS.COMPLETED] || 0
        r[ENUM_INCIDENT_STATUS.IN_REVIEW] += data[key][ENUM_INCIDENT_STATUS.IN_REVIEW] || 0
        r[ENUM_INCIDENT_STATUS.REPORTED] += data[key][ENUM_INCIDENT_STATUS.REPORTED] || 0
        r[ENUM_INCIDENT_STATUS.CLOSED] += data[key][ENUM_INCIDENT_STATUS.CLOSED] || 0
        r[ENUM_INCIDENT_STATUS.REJECTED] += data[key][ENUM_INCIDENT_STATUS.REJECTED] || 0

        return r
      }, {
        [ENUM_INCIDENT_STATUS.NEW]: 0,
        [ENUM_INCIDENT_STATUS.ACCEPTED]: 0,
        [ENUM_INCIDENT_STATUS.WAITING]: 0,
        [ENUM_INCIDENT_STATUS.IN_PROGRESS]: 0,
        [ENUM_INCIDENT_STATUS.COMPLETED]: 0,
        [ENUM_INCIDENT_STATUS.IN_REVIEW]: 0,
        [ENUM_INCIDENT_STATUS.REPORTED]: 0,
        [ENUM_INCIDENT_STATUS.CLOSED]: 0,
        [ENUM_INCIDENT_STATUS.REJECTED]: 0,
      })
    },
    async getGenderReport(startDate: Dayjs, endDate: Dayjs) {
      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }, 'gender', this.timeGroup)

      this.genderReport = Object.keys(data).reduce((r, key: string) => {
        r[ENUM_GENDER.MALE] += data[key][ENUM_GENDER.MALE] || 0
        r[ENUM_GENDER.FEMALE] += data[key][ENUM_GENDER.FEMALE] || 0
        r[ENUM_GENDER.NONE] += data[key][ENUM_GENDER.NONE] || 0

        return r
      }, {
        [ENUM_GENDER.MALE]: 0,
        [ENUM_GENDER.FEMALE]: 0,
        [ENUM_GENDER.NONE]: 0,
      })
    },
    async getNationalityReport(startDate: Dayjs, endDate: Dayjs) {
      if (!this.nationalities.length) {
        this.nationalities = await fetch.masterData.listNationality()
      }

      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }, 'nationality', this.timeGroup)

      const result = Object.keys(data).reduce((r: GeneralReport<typeGetNationality>[], d: string) => {
        r = Object.keys(data[d]).reduce((r: GeneralReport<typeGetNationality>[], key: string) => {
          const type = this.nationalities.find((n) => n.code === key)
          let report = r.find((item) => item.type?.code === type?.code)
          if (!report) {
            report = {
              type,
              total: 0
            }
            r.push(report)
          }

          Object.assign(report, {
            total: report.total + data[d][key]
          })

          return r
        }, r)

        return r
      }, []).sort((a, b) => b.total - a.total)

      this.nationalityReport = [
        ...result.slice(0, 10),
      ]

      if (result.length > 10) {
        this.nationalityReport.push(result.slice(10).reduce((r, i) => {
          Object.assign(r, {
            total: r.total + i.total
          })
          return r
        }, { total: 0 }))
      }
    },
    async getIncidentCategoryReport(startDate: Dayjs, endDate: Dayjs, categoryCode?: string, topicCodes?: string[]) {
      if (!this.incidentCategories.length) {
        this.incidentCategories = await fetch.masterData.listIncidentCategory()
      }

      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        categoryCode,
        topicCode: topicCodes?.join(','),
      }, 'category_code', this.timeGroup)

      this.incidentCategotyReport = Object.keys(data).reduce((r: GeneralReport<typeGetIncidentCategory>[], d: string) => {
        r = Object.keys(data[d]).reduce((r: GeneralReport<typeGetIncidentCategory>[], key: string) => {
          const type = this.incidentCategories.find((n) => n.code === key)
          let report = r.find((item) => item.type?.code === type?.code)
          if (!report) {
            report = {
              type,
              total: 0
            }
            r.push(report)
          }

          Object.assign(report, {
            total: report.total + data[d][key]
          })

          return r
        }, r)

        return r
      }, []).sort((a, b) => b.total - a.total)
    },
    async getIncidentTopicReport(startDate: Dayjs, endDate: Dayjs, categoryCode?: string, topicCodes?: string[]) {
      if (!this.incidentTopics.length) {
        this.incidentTopics = await fetch.masterData.listIncidentTopic()
      }

      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        categoryCode,
        topicCode: topicCodes?.join(','),
      }, 'topic_code', this.timeGroup)

      this.incidentTopicReport = Object.keys(data).reduce((r: GeneralReport<typeGetIncidentTopic>[], d: string) => {
        r = Object.keys(data[d]).reduce((r: GeneralReport<typeGetIncidentTopic>[], key: string) => {
          const type = this.incidentTopics.find((n) => n.code === key)
          let report = r.find((item) => item.type?.code === type?.code)
          if (!report) {
            report = {
              type,
              total: 0
            }
            r.push(report)
          }

          Object.assign(report, {
            total: report.total + data[d][key]
          })

          return r
        }, r)

        return r
      }, []).sort((a, b) => b.total - a.total)
    },
    async getIncidentProviceReport(startDate: Dayjs, endDate: Dayjs, categoryCode?: string, topicCodes?: string[]) {
      if (!this.provinces.length) {
        this.provinces = await fetch.masterData.listProvince()
      }

      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        categoryCode,
        topicCode: topicCodes?.join(','),
      }, 'province_id', this.timeGroup)

      this.incidentProvinceReport = Object.keys(data).reduce((r: GeneralReport<typeGetProvince>[], d: string) => {
        r = Object.keys(data[d]).reduce((r: GeneralReport<typeGetProvince>[], key: string) => {
          const type = this.provinces.find((n) => n.id === parseInt(key, 10))
          let report = r.find((item) => item.type?.id === type?.id)
          if (!report) {
            report = {
              type,
              total: 0
            }
            r.push(report)
          }

          Object.assign(report, {
            total: report.total + data[d][key]
          })

          return r
        }, r)

        return r
      }, []).sort((a, b) => b.total - a.total)
    },
    async getResponsibleAreaReport(startDate: Dayjs, endDate: Dayjs, categoryCode?: string, topicCodes?: string[]) {
      if (!this.organizations.length) {
        this.organizations = await fetch.masterData.listOrganizations()
      }

      const data = await fetch.report.IncidentReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        categoryCode,
        topicCode: topicCodes?.join(','),
      }, 'responsible_area_code', this.timeGroup)

      this.responsibleAreaReport = Object.keys(data).reduce((r: GeneralReport<typeGetOrganization>[], d: string) => {
        r = Object.keys(data[d]).reduce((r: GeneralReport<typeGetOrganization>[], key: string) => {
          const type = this.organizations.find((n) => n.code === key)
          let report = r.find((item) => item.type?.code === type?.code)
          if (!report) {
            report = {
              type,
              total: 0
            }
            r.push(report)
          }

          Object.assign(report, {
            total: report.total + data[d][key]
          })

          return r
        }, r)

        return r
      }, []).sort((a, b) => b.total - a.total)
    },
  }
})
