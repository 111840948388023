<template>
  <div class="flex items-center mx-2 mt-5">
    <div class="flex-none">
      <router-link v-if="back" :to="back" class="w-10 text-white text-left z-[99999]">
        <ChevronLeftIcon class="flex-none inline-block ttp-icon-inside-box-02" />
      </router-link>
    </div>

    <div class="flex-1">
      <slot />
    </div>
    <div class="flex-none flex">
      <div class="w-10 text-white text-right">
        <router-link :to="{ name: 'MessageView' }"
          ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
          </svg>
        </router-link>
      </div>
      <div class="w-10 text-white text-right">
        <router-link :to="{ name: 'NotificationView' }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
  <div class="flex my-4">
    <div class="w-full">
      <div class="flex w-full items-center gap-6 ml-4">
        <div class="swipe-photo inline-flex ttp-icon-box-01 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500 overflow-hidden">
          <UserIcon v-if="!profileImage" class="inline-block ttp-icon-inside-box-02" />
          <a v-else :href="profileImage" target="_blank">
            <img class="object-cover object-center rounded-full float-right w-16 h-16 lg:w-24 lg:h-24" :src="profileImage" loading="lazy" alt="" />
          </a>
        </div>
        <div class="flex-col justify-start items-start gap-0 inline-flex">
          <div class="ttp-text-md sm:ttp-text-xl text-white font-extralight">
            {{ t("page.HomeView.hello_txt") }}, <span class="text-white-300">{{ t("page.HomeView.welcome_txt") }}</span>
          </div>
          <div class="text-white font-medium ttp-text-3xl">
            <div>{{ user?.name }}</div>
            <div class="ttp-text-sm sm:ttp-text-xl text-white-300">{{ t("page.HomeView.welcome_detail_txt") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { type RouteLocationRaw } from "vue-router";
import { useAuthStore } from "../Authentication/stores/useAuthStore";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { ChevronLeftIcon, UserIcon } from "@heroicons/vue/24/solid";
import { useI18n } from "vue-i18n";

const file = new fetchFile();
const authStore = useAuthStore();
const { t } = useI18n();

const user = computed(() => authStore.user);
const profileImage = ref<string | null>(null);

defineProps<{
  back?: RouteLocationRaw;
}>();

watch(user, async () => {
  profileImage.value = await file.GetUrl(user.value?.image);
});
</script>
