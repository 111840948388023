<script lang="ts">
import { defineComponent, ref } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useHistorySearchStore } from "../History/stores/useHistorySearchStore";
import { useChatFormStore } from "./stores/useChatFormStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import loadImage from "blueimp-load-image";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import VoiceDialog from "@/components/Tourist/VoiceDialog.vue";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetFile } from "@/configs/types/Shared/typeFile";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { usePermissionStore } from "@/stores/PoliceTouristAndAgent/usePermissionStore";

let images = ref<any[]>([]);

export default defineComponent({
  name: "ChatManageView",
  data() {
    return {
      componentName: this.$options.name,
      policeProfileImage: new URL(`@/assets/images/police-profile.svg`, import.meta.url).href,
    };
  },
  components: {
    VoiceDialog,
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            message: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.chatFormStore.error[`message`] || "").length > 0,
                isIcon: false,
                isDisable: this.chatFormStore.loading,
                isRequired: this.chatFormStore.isRequired.message.is,
                textError: "",
                title: this.$t(this.chatFormStore.isRequired.message.label),
                name: `message`,
                placeholder: this.$t(this.chatFormStore.isRequired.message.placeholder),
                value: this.chatFormStore.result.message,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.chatFormStore.result.message = value;
                this.chatFormStore.message = value;
              },
            },
            name: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.chatFormStore.locationError[this.chatFormStore.isRequiredLocation.name.name] || "").length > 0,
                isIcon: false,
                isDisable: false,
                isRequired: this.chatFormStore.isRequiredLocation.name.is,
                textError: this.$t(this.chatFormStore.isRequiredLocation.name.placeholder),
                title: this.$t(this.chatFormStore.isRequiredLocation.name.label),
                placeholder: this.$t(this.chatFormStore.isRequiredLocation.name.placeholder),
                value: this.chatFormStore?.resultLocation?.name,
                name: this.chatFormStore.isRequiredLocation.name.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.chatFormStore.resultLocation.name = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async checkVoiceCall() {
      (this.$refs as any).voice.start(this.$route.params.id);
    },
    async openAppSetting() {
      if (Capacitor.isNativePlatform()) {
        await (this.$PermissionsPlugin as any).openAppSettings();
      }
    },
    async connectChat() {
      if (this.$chatSocket) {
        await this.chatFormStore.setIncident(String(this.$route.params.id), this.$enums.ENUM_CHAT_SENDER.TOURIST, this.authStore?.user?.id);
        await this.$chatSocket.connect();
      }
    },
    async socketChatOnReceived() {
      if (this.$chatSocket) {
        await this.$chatSocket.on("message.received", async (payload: any) => {
          if (payload?.message?.type !== this.$enums.ENUM_CHAT_TYPE.MAKE_A_CALL) {
            this.chatFormStore.roomId = payload?.message?.content?.roomId;
            this.chatFormStore.startAt = payload?.message?.content?.startAt;
            this.chatFormStore.endAt = payload?.message?.content?.endAt;
            this.chatFormStore.replyID = payload?.refID;
            await setLocalStorage(`roomId`, payload?.message?.content?.roomId);

            // Get Image
            if (payload?.message?.type == this.$enums.ENUM_CHAT_TYPE.IMAGES) {
              if (payload?.message?.content?.length) {
                payload.message.content = await Promise.all(await this.chatFormStore.fetchFile.Get(payload?.message?.content));
                setTimeout(() => {
                  this.$configLightbox();
                }, 1000);
              }
            }

            await this.chatFormStore.messages.push(payload);
            this.scrollBottom(1000);
          }
        });
      }
    },
    async takePhoto() {
      if (!this.permissionStore.hasCameraPermission) {
        await this.permissionStore.requestCameraPermission();
        if (!this.permissionStore.hasCameraPermission) {
          return;
        }
      }

      if (Capacitor.isNativePlatform()) {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          quality: 100,
          allowEditing: true,
        });

        this.chatFormStore.loading = true;
        let blob = await fetch(photo.webPath!).then((r) => r.blob());

        const formData = new FormData();
        formData.append("file", blob, `image-${Date.now()}.jpg`);
        formData.append("accept", "image/*");
        const file = await axiosClient({ isFile: true }, this.$enums.ENUM_APP_NAME.TOURIST_POLICE).post<typeGetFile>("/v1/files", formData);

        this.chatFormStore.imageIds = [file.data.id];
        this.chatFormStore.imageUrls = await Promise.all(await this.chatFormStore.fetchFile.Get(this.chatFormStore.imageIds));

        this.chatFormStore.update(this.$enums.ENUM_CHAT_TYPE.IMAGES, this.chatFormStore.replyID, this.chatFormStore.imageIds);

        this.$configLightbox();
        this.scrollBottom(100);

        alert("Attached Successfully!");
        this.chatFormStore.loading = false;
      } else {
        this.chatFormStore.photo = null;
        this.chatFormStore.isCamera = !this.chatFormStore.isCamera;
        this.chatFormStore.stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
          },
        });

        (this.$refs as any).video.srcObject = this.chatFormStore.stream;

        (this.$refs as any).video.onloadedmetadata = () => {
          this.chatFormStore.ready = true;
        };

        (this.$refs as any).video.onended = () => {
          this.chatFormStore.ready = false;
          this.chatFormStore.stream = null;
        };
      }
    },
    async attachFiles() {
      if (Capacitor.isNativePlatform()) {
        if (!this.permissionStore.hasGalleryPermission) {
          await this.permissionStore.requestCameraPermission();
          if (!this.permissionStore.hasGalleryPermission) {
            return;
          }
        }

        const photo = await Camera.pickImages({
          limit: 10,
          quality: 100,
        });
        this.chatFormStore.loading = true;
        const gallery = await Promise.all(
          photo.photos.map(async (photo) => {
            let blob = await fetch(photo.webPath!).then((r) => r.blob());

            const formData = new FormData();
            formData.append("file", blob, `image-${Date.now()}.jpg`);
            formData.append("accept", "image/*");

            return axiosClient({ isFile: true }, this.$enums.ENUM_APP_NAME.TOURIST_POLICE).post<typeGetFile>("/v1/files", formData);
          })
        );
        const responseDataArray = await gallery.map((response) => response.data);

        this.chatFormStore.imageIds = await responseDataArray.map((item: any) => item.id);
        this.chatFormStore.imageUrls = await this.chatFormStore.fetchFile.Get(this.chatFormStore.imageIds);

        this.chatFormStore.update(this.$enums.ENUM_CHAT_TYPE.IMAGES, this.chatFormStore.replyID, this.chatFormStore.imageIds);
        if (this.chatFormStore.messages[this.$findIndexById(this.chatFormStore.messages, this.chatFormStore.id)] !== undefined) this.chatFormStore.messages[this.$findIndexById(this.chatFormStore.messages, this.chatFormStore.id)].child = "Hide";

        this.$configLightbox();
        this.scrollBottom(100);

        alert("Attached Successfully!");
        this.chatFormStore.loading = false;
      } else {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.multiple = true;
        input.onchange = async (e: any) => {
          const files = e.target.files;
          images.value = [];

          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = async () => {
              images.value.push(reader.result);
              if (images.value.length === files.length) {
                await this.uploadImages();
              }
            };
            reader.readAsDataURL(file);
          }
        };
        input.click();
      }
    },
    async uploadImages(_e?: any) {
      this.chatFormStore.loading = true;
      if (!images.value.length) return;

      const formDataArray = await Promise.all(
        await images.value.map(async (imageUri) => {
          let blob = await fetch(imageUri!).then((r) => r.blob());

          const formData = new FormData();
          formData.append("file", blob, `image-${Date.now()}.jpg`);
          formData.append("accept", "image/*");
          return formData;
        })
      );

      const responses = await Promise.all(
        await formDataArray.map((formData) => {
          return axiosClient({ isFile: true }, this.$enums.ENUM_APP_NAME.TOURIST_POLICE).post<typeGetFile>("/v1/files", formData);
        })
      );
      const responseDataArray = await responses.map((response) => response.data);

      this.chatFormStore.imageIds = await responseDataArray.map((item: any) => item.id);
      this.chatFormStore.imageUrls = await this.chatFormStore.fetchFile.Get(this.chatFormStore.imageIds);

      this.chatFormStore.update(this.$enums.ENUM_CHAT_TYPE.IMAGES, this.chatFormStore.replyID, this.chatFormStore.imageIds);
      if (this.chatFormStore.messages[this.$findIndexById(this.chatFormStore.messages, this.chatFormStore.id)] !== undefined) this.chatFormStore.messages[this.$findIndexById(this.chatFormStore.messages, this.chatFormStore.id)].child = "Hide";

      this.$configLightbox();
      this.scrollBottom(100);

      alert("Attached Successfully!");
      this.chatFormStore.loading = false;
    },
    convertBlobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = (reader.result as string).split(",")[1];
          resolve(base64String);
        };
        reader.onerror = () => {
          reject(new Error("Failed to convert blob to base64"));
        };
        reader.readAsDataURL(blob);
      });
    },
    async capturePhoto() {
      let video = (this.$refs as any).video;

      let videoCanvas = document.createElement("canvas");
      videoCanvas.height = video.videoHeight;
      videoCanvas.width = video.videoWidth;
      let videoContext = videoCanvas.getContext("2d") as any;

      videoContext.drawImage(video, 0, 0);

      this.chatFormStore.photo = loadImage.scale(videoCanvas, {});
      this.chatFormStore.stream.getTracks().forEach((track: any) => track.stop());
      this.chatFormStore.stream = null;

      await this.chatFormStore.photo.toBlob(async (blob: any) => {
        this.chatFormStore.loading = true;

        const currentDate = new Date();
        const dateString = currentDate.toISOString().replace(/[:.]/g, "-");
        const fileName = `custom_${dateString}.jpg`;

        const file = new File([blob], fileName, { type: "image/jpeg" });

        const results = await this.chatFormStore.fetchFile.Upload([file]);
        this.chatFormStore.imageIds = await results.map((item: any) => item.id);
        this.chatFormStore.imageUrls = await Promise.all(await this.chatFormStore.fetchFile.Get(this.chatFormStore.imageIds));

        this.chatFormStore.update(this.$enums.ENUM_CHAT_TYPE.IMAGES, this.chatFormStore.replyID, this.chatFormStore.imageIds);

        this.$configLightbox();
        this.scrollBottom(100);
      }, "image/jpeg");

      this.chatFormStore.isCamera = !this.chatFormStore.isCamera;
      this.scrollBottom(1000);

      alert("Attached Successfully!");
      this.chatFormStore.loading = false;
    },
    closeCamera() {
      if (this.chatFormStore.stream) {
        this.chatFormStore.stream.getTracks().forEach((track: any) => track.stop());
        this.chatFormStore.stream = null;
      }
      this.chatFormStore.isCamera = !this.chatFormStore.isCamera;
    },

    async fetch() {
      if (this.$route.params.id) {
        const result = await this.historySearchStore.fetchIncident.Get(this.$route.params.id as string);
        this.historySearchStore.result = result;

        // Get Image
        if (this?.historySearchStore?.result?.images?.length) {
          this.historySearchStore.result.images = await Promise.all(await this.historySearchStore.fetchFile.Get(this?.historySearchStore?.result?.images));
        }
        await this.$configLightbox();
        await this.scrollBottom(100);
      }

      this.historySearchStore.loading = false;
    },
    async getMessageRoom() {
      if (this.$route.params.id) {
        this.chatFormStore.messages = await this.chatFormStore.fetchIncident.GetListIncidentChat(String(this.$route.params.id), this.masterDataStore.lang());

        // Last Auto Reply
        if (this.chatFormStore.messages.length) {
          // Get Image
          if (this.$findIndexesOfImages(this.chatFormStore.messages).length) {
            await Promise.all(
              this.$findIndexesOfImages(this.chatFormStore.messages).map(async (index) => {
                this.chatFormStore.messages[index].message.content = await this.chatFormStore.fetchFile.Get(this.chatFormStore.messages[index]?.message?.content);
              })
            );
            await this.$configLightbox();
          }

          // Get Image From Google Map
          if (this.$findIndexesOfLocations(this.chatFormStore.messages).length) {
            await this.$findIndexesOfLocations(this.chatFormStore.messages).map(async (index) => {
              const data = this.chatFormStore.messages[index]?.message?.content;
              const result = Object.values({
                latitude: data?.latitude,
                longitude: data?.longitude,
              }).join(",");

              this.chatFormStore.messages[index].message.content = {
                ...this.chatFormStore.messages[index]?.message?.content,
                url: await this.chatFormStore.getStaticMap(result),
              };
            });
          }

          // Last Auto Reply
          const lastIndex = this.chatFormStore.messages.map((obj: any) => obj.sender).lastIndexOf(this.$enums.ENUM_CHAT_SENDER.SYSTEM);
          this.chatFormStore.replyID = this.chatFormStore.messages[lastIndex].id;
        }

        await this.scrollBottom(100);
      }
    },
    loading() {
      return this.historySearchStore.loading;
    },
    scrollBottom(number: number) {
      setTimeout(() => {
        if (this.$refs.scrollContainer) {
          const container = this.$refs.scrollContainer as HTMLElement;

          container.scrollTop = container.scrollHeight;
        }
        this.chatFormStore.loading = false;
      }, number);
    },
    settingMap() {
      this.chatFormStore.resultLocation.latitude = 13.736717;
      this.chatFormStore.resultLocation.longitude = 100.523186;
      this.initializeMap();
      this.scrollBottom(100);
    },
    async initializeMap() {
      const google = await this.$asyncGoogleMapsLoader();
      const { Map } = (await google.maps.importLibrary("maps")) as any;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as any;

      var thailandCoordinates = { lat: 13.736717, lng: 100.523186 };
      const map = new Map(document.getElementById("map_chat") as HTMLElement, {
        center: thailandCoordinates,
        zoom: 14,
        mapId: "4504f8b37365c3d0",
        mapTypeId: "roadmap",
      });

      const draggableMarker = new AdvancedMarkerElement({
        map,
        position: thailandCoordinates,
        gmpDraggable: true,
        title: "This marker is draggable.",
      });

      draggableMarker.addListener("dragend", async (e: any) => {
        const position = draggableMarker.position as any;
        this.chatFormStore.resultLocation.latitude = position.lat;
        this.chatFormStore.resultLocation.longitude = position.lng;

        const newPosition = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        } as any;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: newPosition },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("name") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.chatFormStore.resultLocation.name = result.results[0].formatted_address;
      });
      if (navigator.geolocation) {
        const position = await Geolocation.getCurrentPosition();
        var selfCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.chatFormStore.resultLocation.latitude = position.coords.latitude;
        this.chatFormStore.resultLocation.longitude = position.coords.longitude;

        map.setCenter(selfCoordinates);
        draggableMarker.position = selfCoordinates;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: selfCoordinates },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("name") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.chatFormStore.resultLocation.name = result.results[0].formatted_address;
      }

      const input = document.getElementById("name") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as any);
      });

      let markers: any[] = [];
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) return;
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place: any) => {
          if (!place.geometry || !place.geometry.location) return;

          draggableMarker.position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          this.chatFormStore.resultLocation.latitude = place.geometry.location.lat();
          this.chatFormStore.resultLocation.longitude = place.geometry.location.lng();
          this.chatFormStore.resultLocation.name = `${place.name}, ${place.formatted_address}`;

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
    },
    async reset() {
      await this.chatFormStore.$reset();
      await this.historySearchStore.$reset();
      await setLocalStorage("roomId", "");
    },
    async setting() {
      // await this.reset();
      await this.fetch();
      await this.getMessageRoom();
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
    await this.connectChat();
    await this.socketChatOnReceived();
  },
  async unmounted() {
    await this.reset();

    await this.$chatSocket.off("message.received");
    await this.$chatSocket.disconnect();
  },
  setup() {
    const authStore = useAuthStore();
    const chatFormStore = useChatFormStore();
    const sharedStore = useSharedStore();
    const historySearchStore = useHistorySearchStore();
    const masterDataStore = useMasterDataStore();
    const permissionStore = usePermissionStore();

    return { authStore, chatFormStore, sharedStore, historySearchStore, images, masterDataStore, permissionStore };
  },
});
</script>

<template>
  <main v-if="chatFormStore.isCamera" class="absolute top-0 flex w-full h-full z-[100]">
    <button @click="closeCamera" class="absolute top-0 right-0 z-40">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" class="h-10 md:h-12 lg:h-16 w-10 lg:w-12 md:w-16">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" :fill="chatFormStore.photo ? '#000' : '#fff'" />
      </svg>
    </button>
    <section id="camera" v-if="chatFormStore.stream" class="absolute flex flex-col w-full h-full inset-0 items-center justify-end z-20">
      <button class="absolute ttp-icon-box-02 bottom-[100px] rounded-full bg-white shadow-md" @click="capturePhoto"></button>
    </section>
    <video v-if="!chatFormStore.photo" ref="video" class="absolute h-full inset-0 object-cover w-full z-10" autoplay muted playsinline></video>
  </main>
  <div v-if="chatFormStore.isMap" id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] relative">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <button class="text-white text-left" @click="chatFormStore.isMap = false">
              <component :is="$solidIcons.XMarkIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
            </button>
            <div class="w-full text-white text-center font-bold text-center">
              <h1 class="ttp-text-xl">{{ $t("page.ChatManageView.location_title") }}</h1>
            </div>
            <form
              @submit="
                async (e: any) => {
                  await chatFormStore.onSubmitLocation(e);
                  scrollBottom(100);
                }
              "
              class="text-white text-right"
            >
              <button type="submit" class="cursor-pointer">{{ $t("btn.btn_share") }}</button>
            </form>
          </div>
          <div class="flex flex-col gradient-border w-full h-full overflow-auto">
            <div class="flex w-full h-full">
              <div class="flex w-full relative bg-white border">
                <div class="relative flex w-full h-full">
                  <component :classTagName="'col-start-1 col-end-13 z-[1000] hidden'" :classInputName="'ttp-location-input'" :is="defaultFormComponents.form.input.name.component" v-bind="defaultFormComponents.form.input.name.props" @updateValue="defaultFormComponents.form.input.name.onUpdateValue" />
                  <div id="map_chat"></div>
                  <div v-if="false" class="absolute top-0 left-0 w-full h-full"></div>
                </div>
              </div>
            </div>
            <div class="flex w-full h-full justify-center bg-white rounded-t-3xl z-[1002] py-6 px-10">
              <div class="w-10 h-1 rounded-lg drop-shadow-lg bg-[#E0E0E0]"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div v-else id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] relative">
    <div v-if="chatFormStore.isToggle" class="flex w-full h-full absolute top-0 left-0 bg-black bg-opacity-50 z-10"></div>
    <div v-if="chatFormStore.isToggle" class="flex w-full justify-center absolute bottom-0 bg-white rounded-t-3xl z-20 ttp-padding-01 mb-16">
      <div class="absolute top-4 w-10 h-1 rounded-lg drop-shadow-lg bg-[#f4f4f4]"></div>
      <div class="flex flex-col w-full">
        <div class="relative my-2">
          <button
            :disabled="false"
            class="text-black ttp-text-sm text-left absolute top-0 left-0 h-full"
            @click="
              () => {
                chatFormStore.isToggle = !chatFormStore.isToggle;
              }
            "
          >
            {{ $t("btn.btn_cancel") }}
          </button>
          <h1 class="ttp-text-sm font-bold text-center text-black">{{ chatFormStore.buttonText_1 }}</h1>
          <button
            :disabled="false"
            class="text-black ttp-text-sm text-left absolute top-0 right-0 h-full"
            @click="
              () => {
                chatFormStore.isToggle = !chatFormStore.isToggle;
                chatFormStore.update($enums.ENUM_CHAT_TYPE.SELECTED, chatFormStore.replyID, chatFormStore.selectedTopic);
                chatFormStore.messages[$findIndexById(chatFormStore.messages, chatFormStore.selectId)].child = 'Hide';
                scrollBottom(100);
              }
            "
          >
            {{ $t("btn.btn_select_default") }}
          </button>
        </div>
        <div class="flex flex-col w-full gap-1 overflow-auto h-[200px]">
          <button
            @click="
              () => {
                chatFormStore.selectedTopic = { name: data.name, value: data.value };
              }
            "
            v-for="(data, index) in chatFormStore.selectValue"
            :key="index"
            class="ttp-text-xs hover:bg-[#E0E0E1] focus:bg-[#E0E0E1] active:bg-[#E0E0E1] ttp-text-sm w-full p-1 hover:text-[#000000] focus:text-[#000000] active:text-[#000000] text-[#4E4E4E] rounded-lg"
          >
            {{ data.name }}
          </button>
        </div>
      </div>
    </div>

    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-20 text-white text-left"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">{{ $t("page.ChatManageView.title") }}</h1>
            </div>
            <div class="flex w-20 text-white text-right">
              <router-link :to="{ name: 'HistoryPoliceTouristAndAgentView' }">
                <component :is="$solidIcons.DocumentTextIcon" class="flex-none inline-block ttp-icon-inside-box-02 ml-4" />
              </router-link>
              <button @click="checkVoiceCall" v-if="!$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.COMPLETED, $enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REJECTED])" :class="{ 'on-call': sharedStore.onCall }">
                <component :is="$solidIcons.PhoneIcon" class="flex-none inline-block ttp-icon-inside-box-02 ml-4" />
              </button>
            </div>
          </div>
          <div ref="scrollContainer" class="flex flex-col gap-2 lg:gap-4 gradient-border ttp-padding-01 pb-40 w-full h-full overflow-auto">
            <div v-if="true" class="ttp-date flex gap-2 mt-2">
              <div class="m-auto inline-flex rounded bg-[#edf3ff] px-4 py-1 ttp-text-xs leading-4 text-[#2C3483]">{{ $dayjs(new Date()).format("DD/MM/YYYY") }}</div>
            </div>
            <template v-for="item in chatFormStore.messages" :key="item.refID">
              <div v-if="item.sender == $enums.ENUM_CHAT_SENDER.TOURIST" class="ttp-tourist-chat flex gap-2">
                <div class="w-12 lg:w-20"></div>
                <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.TEXT">
                  <div class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                    <div class="relative pb-5">
                      <div class="text-white ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="white" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="white" />
                        </svg>
                      </small>
                    </div>
                  </div>
                </template>
                <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.SELECTED">
                  <div class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                    <div class="relative pb-5">
                      <div class="text-white ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="white" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="white" />
                        </svg>
                      </small>
                    </div>
                  </div>
                </template>
                <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.IMAGES">
                  <div v-if="item?.message?.content" class="w-full">
                    <div class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                      <div class="relative pb-5">
                        <div class="text-white ttp-text-xs">{{ $t("layout.select_picture") }}</div>
                        <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                          <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                          <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="white" />
                            <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="white" />
                          </svg>
                        </small>
                      </div>
                    </div>
                    <div class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                      <div class="relative pb-5">
                        <div class="swipe-photo flex gap-2 w-full items-end justify-end">
                          <LoadingFormComponent v-if="$isArrayOfStringsOrObjects(item?.message?.content)" />
                          <div v-else v-for="(_item, index) in item?.message?.content" :key="index">
                            <a :href="_item?.url ? _item?.url : '#'" target="_blank">
                              <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg ml-2" :src="_item?.url" alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                    <div class="relative pb-5">
                      <div class="text-white ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="white" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="white" />
                        </svg>
                      </small>
                    </div>
                  </div>
                </template>
                <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.LOCATION">
                  <a v-if="item?.message?.content?.latitude" :href="`https://maps.google.com/?q=${item?.message?.content?.latitude},${item?.message?.content?.longitude}`" target="_blank" class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full pb-2 overflow-hidden">
                    <div class="relative">
                      <div class="w-full mb-2">
                        <img :src="item?.message?.content?.url" class="object-cover object-center w-full h-36 max-w-full" />
                      </div>
                      <div class="px-3">
                        <div class="text-white ttp-text-xs" v-html="item?.message?.content?.name"></div>
                        <hr class="my-1 md:my-2" />
                        <div class="flex gap-1 items-center">
                          <div><component :is="$solidIcons.MapPinIcon" class="inline-block m-auto text-[#7B61FF] ttp-icon-inside-box-03 cursor-pointer" /></div>
                          <div class="text-[#7B61FF] ttp-text-xs pt-1">Location</div>
                          <div class="w-full text-right"><component :is="$solidIcons.ChevronRightIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-[#7B61FF]" /></div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div v-else class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                    <div class="relative pb-5">
                      <div class="text-white ttp-text-xs">{{ $t("btn.btn_non") }}</div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="white" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="white" />
                        </svg>
                      </small>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="bg-ttp-chat-right border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-4">
                    <div class="text-white font-medium">Voice Call</div>
                    <div v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.MAKE_A_CALL" class="text-ttp-reported relative">
                      <div class="flex items-center gap-3">
                        <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-white"><component :is="$solidIcons.PhoneIcon" class="flex-none inline-block ttp-icon-inside-box-03 mx-1 text-blue" /></div>
                        <div class="ttp-text-sm text-white">{{ $t("page.ChatView.make_a_call") }}</div>
                      </div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="#9E9E9E" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="#9E9E9E" />
                        </svg>
                      </small>
                    </div>
                    <div v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.HANG_UP" class="text-ttp-reported relative">
                      <div class="flex items-center gap-3">
                        <div class="ttp-text-sm text-white">{{ $t("page.ChatView.hang_up") }}</div>
                      </div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="#9E9E9E" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="#9E9E9E" />
                        </svg>
                      </small>
                    </div>
                    <div v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.ANSWER_A_CALL" class="text-ttp-reported relative">
                      <div class="flex items-center gap-3">
                        <div class="ttp-text-sm text-white">{{ $t("page.ChatView.answer_a_call") }}</div>
                      </div>
                      <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                        <span class="text-white ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                        <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="#9E9E9E" />
                          <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="#9E9E9E" />
                        </svg>
                      </small>
                    </div>
                  </div>
                </template>
              </div>

              <div v-else-if="$inArray(item.sender, [$enums.ENUM_CHAT_SENDER.SYSTEM])" class="ttp-police-chat flex gap-2">
                <div>
                  <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <img :src="policeProfileImage" />
                  </div>
                </div>
                <div class="bg-ttp-chat-left flex flex-col border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3 gap-1">
                  <div class="relative text-ttp-primary ttp-text-sm font-medium">
                    <div>Guardian (Bot)</div>
                    <small v-if="true" class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                      <span class="text-[#9E9E9E] ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                      <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="#9E9E9E" />
                        <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="#9E9E9E" />
                      </svg>
                    </small>
                  </div>
                  <div class="text-ttp-reported relative">
                    <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.TEXT">
                      <div class="ttp-text-xs mb-2" v-html="$replaceString(item?.message?.content?.message, '%name%', $convertString(authStore?.user?.name))"></div>
                      <div v-if="item?.message?.content?.placeholder && !item?.child">
                        <button
                          @click="
                            () => {
                              chatFormStore.id = item?.id;
                              if (item?.message?.ruleName == $enums.ENUM_CHAT_TYPE.LOCATION) {
                                if (chatFormStore.replyID) {
                                  chatFormStore.replyID = item?.refID;
                                  chatFormStore.isMap = true;
                                  settingMap();
                                }
                              } else if (item?.message?.ruleName == $enums.ENUM_CHAT_TYPE.IMAGES) {
                                attachFiles();
                              } else {
                                chatFormStore.selectValue = item?.message?.content?.items;
                                chatFormStore.replyID = item?.refID;
                                chatFormStore.buttonText_1 = item?.message?.content?.placeholder[0];
                                chatFormStore.messages[$findIndexById(chatFormStore.messages, item?.id)].child = 'Hide';
                              }
                            }
                          "
                          class="bg-ttp-primary ttp-text-xs w-full p-1 text-white border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg"
                        >
                          {{ $convertString(item?.message?.content?.placeholder[0]) }}
                        </button>
                      </div>
                      <div v-if="item?.message?.content?.placeholder && !item?.child" class="mt-2">
                        <button
                          @click="
                            () => {
                              chatFormStore.id = item?.id;
                              chatFormStore.replyID = item?.refID;
                              chatFormStore.selectedTopic = null;
                              if (item?.message?.ruleName == $enums.ENUM_CHAT_TYPE.LOCATION) {
                                chatFormStore.update($enums.ENUM_CHAT_TYPE.LOCATION, chatFormStore.replyID, chatFormStore.selectedTopic);
                              } else if (item?.message?.ruleName == $enums.ENUM_CHAT_TYPE.IMAGES) {
                                chatFormStore.update($enums.ENUM_CHAT_TYPE.IMAGES, chatFormStore.replyID, chatFormStore.selectedTopic);
                              } else {
                                chatFormStore.update($enums.ENUM_CHAT_TYPE.TEXT, chatFormStore.replyID, chatFormStore.selectedTopic);
                              }
                              chatFormStore.messages[$findIndexById(chatFormStore.messages, item?.id)].child = 'Hide';

                              scrollBottom(100);
                            }
                          "
                          class="ttp-text-xs text-[#2C3483] w-full text-center"
                        >
                          {{ $convertString(item?.message?.content?.placeholder[1] ? item?.message?.content?.placeholder[1] : $t("btn.btn_non")) }}
                        </button>
                      </div>
                    </template>
                    <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.SELECT">
                      <div class="relative">
                        <div class="ttp-text-xs mb-2" v-html="item?.message?.content?.message"></div>
                      </div>
                      <div v-if="!item?.child">
                        <button
                          @click="
                            () => {
                              chatFormStore.isToggle = !chatFormStore.isToggle;
                              chatFormStore.selectValue = item?.message?.content?.items;
                              chatFormStore.id = item?.id;
                              chatFormStore.replyID = item?.refID;
                              chatFormStore.buttonText_1 = item?.message?.content?.placeholder[0];
                              chatFormStore.selectId = item?.id;
                            }
                          "
                          class="bg-ttp-primary ttp-text-xs w-full p-1 text-white border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg"
                        >
                          {{ $convertString(item?.message?.content?.placeholder[0]) }}
                        </button>
                      </div>
                      <div v-if="!item?.child" class="mt-2">
                        <button
                          @click="
                            () => {
                              chatFormStore.id = item?.id;
                              chatFormStore.replyID = item?.refID;
                              chatFormStore.selectedTopic = { name: null, value: null };
                              chatFormStore.update($enums.ENUM_CHAT_TYPE.SELECTED, chatFormStore.replyID, chatFormStore.selectedTopic);
                              chatFormStore.messages[$findIndexById(chatFormStore.messages, item?.id)].child = 'Hide';
                            }
                          "
                          class="ttp-text-xs text-[#2C3483] w-full text-center"
                        >
                          {{ $convertString(item?.message?.content?.placeholder[1] ? item?.message?.content?.placeholder[1] : $t("btn.btn_non")) }}
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="w-0 lg:w-20"></div>
              </div>

              <div v-else-if="$inArray(item.sender, [$enums.ENUM_CHAT_SENDER.USER])" class="ttp-police-chat flex gap-2">
                <div>
                  <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <img :src="policeProfileImage" />
                  </div>
                </div>
                <div class="bg-ttp-chat-left flex flex-col border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3 gap-1">
                  <div class="relative text-ttp-primary ttp-text-sm font-medium">
                    <div>Police</div>
                    <small v-if="true" class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                      <span class="text-[#9E9E9E] ttp-text-xs">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }}</span>
                      <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="#9E9E9E" />
                        <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="#9E9E9E" />
                      </svg>
                    </small>
                  </div>
                  <div class="text-ttp-reported relative">
                    <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.TEXT">
                      <div class="ttp-text-xs mb-2">{{ item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item) }}</div>
                    </template>
                    <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.IMAGES">
                      <div v-if="item?.message?.content" class="w-full">
                        <div class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                          <div class="relative pb-5">
                            <div class="swipe-photo flex gap-2 w-full items-end justify-end">
                              <LoadingFormComponent v-if="$isArrayOfStringsOrObjects(item?.message?.content)" />
                              <div v-else v-for="(_item, index) in item?.message?.content" :key="index">
                                <a :href="_item?.url ? _item?.url : '#'" target="_blank">
                                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg ml-2" :src="_item?.url" alt="" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="bg-ttp-chat-left border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                        <div class="relative pb-5">
                          <div class="text-black ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.MAKE_A_CALL">
                      <div if="ttp-police-chat-6" class="ttp-police-chat flex gap-2">
                        <div class="bg-ttp-chat-left flex flex-col border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3 gap-1">
                          <div class="text-ttp-reported relative">
                            <div class="flex items-center gap-3">
                              <div class="flex items-center gap-3">
                                <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-[#2C3483]"><component :is="$solidIcons.PhoneIcon" class="flex-none inline-block ttp-icon-inside-box-03 mx-1 text-white" /></div>
                                <div class="ttp-text-sm text-[#2C3483]">{{ $t("page.ChatView.make_a_call") }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-20"></div></div
                    ></template>
                    <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.HANG_UP">
                      <div if="ttp-police-chat-6" class="ttp-police-chat flex gap-2">
                        <div class="flex items-center gap-3">
                          <div class="ttp-text-sm text-[#2C3483]">{{ $t("page.ChatView.hang_up") }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.ANSWER_A_CALL">
                      <div if="ttp-police-chat-6" class="ttp-police-chat flex gap-2">
                        <div class="flex items-center gap-3">
                          <div class="ttp-text-sm text-[#2C3483]">{{ $t("page.ChatView.answer_a_call") }}</div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="w-0 lg:w-20"></div>
              </div>
            </template>

            <div v-if="chatFormStore.loading" class="flex justify-center items-center w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled">
              <LoadingFormComponent />
              <div class="py-1 ttp-text-xs">{{ $t("page.AllIncidentView.waiting_2") }}</div>
            </div>
            <div v-if="false" if="ttp-police-chat-5" class="ttp-police-chat flex gap-2">
              <div>
                <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                  <img :src="policeProfileImage" />
                </div>
              </div>
              <div class="bg-ttp-chat-left flex flex-col border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3 gap-1">
                <div class="text-ttp-primary font-medium">Guardian (Bot)</div>
                <div class="text-ttp-reported relative pb-5">
                  <div class="ttp-text-xs">You can choose FAQ below or type your own question.</div>
                  <small class="flex gap-1 absolute right-0 bottom-0 text-ttp-text-disabled items-center justify-center">
                    <span class="text-[#9E9E9E] ttp-text-xs">09:41</span>
                    <svg class="ttp-icon-inside-box-04" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.606 0.164958C14.8831 0.407413 14.9112 0.828588 14.6687 1.10568L8.83538 7.77235C8.71574 7.90908 8.54547 7.99106 8.36397 7.99932C8.18247 8.00758 8.00546 7.9414 7.87389 7.8161L6.19564 6.21776L4.83538 7.77235C4.71574 7.90908 4.54547 7.99106 4.36397 7.99932C4.18247 8.00758 4.00546 7.9414 3.87389 7.8161L0.37389 4.48277C0.10727 4.22884 0.0969774 3.80686 0.350902 3.54024C0.604826 3.27362 1.02681 3.26333 1.29343 3.51725L4.28987 6.371L5.22926 5.29741L4.37389 4.48277C4.10727 4.22884 4.09698 3.80686 4.3509 3.54024C4.60483 3.27362 5.02681 3.26333 5.29343 3.51725L8.28987 6.371L13.6653 0.227673C13.9077 -0.0494184 14.3289 -0.0774967 14.606 0.164958Z" fill="#9E9E9E" />
                      <path d="M10.6687 1.10568C10.9112 0.828588 10.8831 0.407413 10.606 0.164958C10.3289 -0.0774967 9.90773 -0.0494184 9.66528 0.227673L7.33194 2.89434C7.08949 3.17143 7.11757 3.59261 7.39466 3.83506C7.67175 4.07752 8.09292 4.04944 8.33538 3.77235L10.6687 1.10568Z" fill="#9E9E9E" />
                    </svg>
                  </small>
                </div>
                <button class="text-ttp-primary ttp-text-xs text-center mb-2">How I contact with Tourist police</button>
                <button class="text-ttp-primary ttp-text-xs text-center mb-2">How to find incident report history</button>
                <button class="text-ttp-primary ttp-text-xs text-center">If I want to cancel incident report</button>
              </div>
              <div class="w-20"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>

  <div v-if="$inArray($getProjectType(), [$enums.ENUM_APP_CATEGORY_NAME.TOURIST]) && !chatFormStore.isMap && $route.name === 'ChatManageView' && !$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.COMPLETED, $enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REJECTED])" id="page-footer" class="fixed w-full z-[100] bottom-0 flex flex-none items-center bg-gray-100 lg:pl-[258px] lg:left-0 shadow-md drop-shadow-md">
    <form
      @submit.prevent="
        async (e: any) => {
          await chatFormStore.onSubmit(e);
          scrollBottom(100);
        }
      "
      class="flex w-full text-center items-center justify-center p-2 lg:p-3 gap-2 bg-white"
    >
      <ul class="flex w-full text-center items-center justify-center p-2 lg:p-3 gap-2">
        <li>
          <label @click="takePhoto">
            <component :is="$solidIcons.CameraIcon" class="inline-block m-auto text-ttp-primary ttp-icon-inside-box-02 cursor-pointer" />
          </label>
        </li>
        <li>
          <label for="file-upload" class="icon-container">
            <component @click="attachFiles" :is="$solidIcons.PhotoIcon" class="inline-block m-auto text-ttp-primary ttp-icon-inside-box-02 cursor-pointer" />
          </label>
        </li>
        <li>
          <label
            @click="
              () => {
                chatFormStore.isMap = true;
                settingMap();
              }
            "
          >
            <component :is="$solidIcons.MapPinIcon" class="inline-block m-auto text-ttp-primary ttp-icon-inside-box-02 cursor-pointer" />
          </label>
        </li>
        <li class="w-full">
          <component :classTagName="'col-start-1 col-end-7 md:col-start-3 md:col-end-7'" :is="defaultFormComponents.form.input.message.component" v-bind="defaultFormComponents.form.input.message.props" @updateValue="defaultFormComponents.form.input.message.onUpdateValue" />
        </li>
        <li>
          <button :disabled="chatFormStore.loading" class="disabled:cursor-not-allowed disabled:opacity-50 inline-flex ttp-icon-box-03 items-center justify-center rounded-full bg-[#212A83] text-gray-300 dark:bg-gray-700 dark:text-gray-500">
            <svg class="ttp-icon-inside-box-03" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.4354 2.58198C20.9352 2.0686 20.1949 1.87734 19.5046 2.07866L3.408 6.75952C2.6797 6.96186 2.16349 7.54269 2.02443 8.28055C1.88237 9.0315 2.37858 9.98479 3.02684 10.3834L8.0599 13.4768C8.57611 13.7939 9.24238 13.7144 9.66956 13.2835L15.4329 7.4843C15.723 7.18231 16.2032 7.18231 16.4934 7.4843C16.7835 7.77623 16.7835 8.24935 16.4934 8.55134L10.72 14.3516C10.2918 14.7814 10.2118 15.4508 10.5269 15.9702L13.6022 21.0538C13.9623 21.6577 14.5826 22 15.2628 22C15.3429 22 15.4329 22 15.513 21.9899C16.2933 21.8893 16.9135 21.3558 17.1436 20.6008L21.9156 4.52479C22.1257 3.84028 21.9356 3.09537 21.4354 2.58198Z" fill="white" />
            </svg>
          </button>
        </li>
      </ul>
    </form>
  </div>
  <div v-else-if="$inArray($getProjectType(), [$enums.ENUM_APP_CATEGORY_NAME.TOURIST]) && $route.name === 'ChatManageView' && $inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REJECTED])" id="page-footer" class="fixed w-full z-10 bottom-[60px] lg:bottom-0 flex flex-col items-center bg-[#D9D9D9] lg:pl-[258px] lg:left-0 py-4 drop-shadow-md">
    <div class="text-[#4E4E4E] ttp-text-xl">{{ $t("page.ChatView.end_conversation") }}</div>
    <div class="text-[#898A8D] text-center ttp-text-sm">{{ $t("page.ChatView.detail") }}</div>
  </div>
  <VoiceDialog ref="voice" />
</template>

<style scoped>
.on-call {
  animation: onCall 1s infinite;
}

@keyframes onCall {
  from {
    color: inherit;
  }
  to {
    color: #16a34a;
  }
}
</style>
