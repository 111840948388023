import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import { Capacitor } from "@capacitor/core";
import { NativeAudio } from "@capacitor-community/native-audio";
import { useAudioStore } from "@/stores/Shared/useAudioStore";

export const storeName = `useSharedStore-${import.meta.env.VITE_APP_VERSION}-PoliceCommandCenter`;
export const useSharedStore = defineStore(storeName, {
  state: () => ({
    audioStore: useAudioStore(),
    mode: "true" as string,
    mobileSidebarOpen: false,
    desktopSidebarOpen: true,
    submenuOpen: "Dashboard" as string,
    count: 0,
    getProjectType: ENUM_APP_CATEGORY_NAME.COMMAND as any,
    locale: "TH" as string,
    position: null as any,
    is_track_location: `${storeName}:is_track_location`,
    is_microphone: `${storeName}:is_microphone`,
    is_push_notification: `${storeName}:is_push_notification`,
    is_camera: `${storeName}:is_camera`,
    is_photo: `${storeName}:is_photo`,
    isShowPermission: false,
    isLoadingPermission: false,
    permissionResults: [],
    onCall: false,
  }),
  actions: {
    appVersion() {
      return import.meta.env.VITE_APP_VERSION;
    },
    displayName() {
      return import.meta.env.VITE_DISPLAY_NAME;
    },
    async setSubmenuOpen(name: any) {
      this.submenuOpen = name;
      await setLocalStorage(`${storeName}:submenuOpen`, name);
    },
    async toggleMode() {
      this.mode = this.mode == "true" ? "false" : "true";
      await setLocalStorage(`${storeName}:mode`, this.mode);
    },
    increment() {
      this.count++;
    },
    decrement() {
      this.count--;
    },
    async startCall() {
      this.onCall = true;
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.loop({
          assetId: "call",
        });
      } else {
        await this.audioStore.playCall();
      }
    },
    async waitingCall() {
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.play({
          assetId: "waiting",
        });
      } else {
        await this.audioStore.playWaiting();
      }
    },
    async endCall() {
      if (!this.onCall) {
        return
      }
      this.onCall = false;
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.stop({
          assetId: "waiting",
        });
        await NativeAudio.stop({
          assetId: "call",
        });
        await NativeAudio.play({
          assetId: "hang_up",
        });
      } else {
        await this.audioStore.stopCall();
        await this.audioStore.stopWaiting();
        await this.audioStore.playHangUp();
      }
    },
    async stopCall() {
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.stop({
          assetId: "call",
        });
      } else {
        await this.audioStore.stopCall();
      }
    },
    async stopWaitingCall() {
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.stop({
          assetId: "waiting",
        });
      } else {
        await this.audioStore.stopWaiting();
      }
    },
  },
});
