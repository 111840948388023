<script lang="ts">
import { defineComponent, ref } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { useSettingLanguageStore } from "./stores/useSettingLanguageStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { IonButton } from "@ionic/vue";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { ENUM_APP_NAME } from "@/configs/enums/enum";
import type { typeGetFile } from "@/configs/types/Shared/typeFile";

const image = ref<any>(null);

export default defineComponent({
  name: "SettingMenuView",
  components: { IonButton },
  data() {
    return {
      componentName: this.$options.name,
      profile_image: "" as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          button: {
            btnUploadImage: {
              click: (e: any) => {
                this.selectImage(e);
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async selectImage(e: any) {
      if (Capacitor.isNativePlatform()) {
        const permissionStatus = await Camera.requestPermissions({
          permissions: ["photos"],
        });

        if (permissionStatus.photos !== "granted") {
          alert("Permission to access photos was denied");
          return;
        }

        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Photos,
          quality: 100,
        });
        image.value = photo.webPath;
        this.uploadImage(e);
      } else {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (e: any) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              image.value = reader.result;
              this.uploadImage(e);
            };
            reader.readAsDataURL(file);
          }
        };
        input.click();
      }
    },
    async uploadImage(e: any) {
      if (!image.value) return;

      const response = await fetch(image.value);
      const blob = await response.blob();
      const base64Data = await this.convertBlobToBase64(blob);

      await Filesystem.writeFile({
        path: `image-${Date.now()}.jpg`,
        data: base64Data,
        directory: Directory.Data,
      });

      const formData = new FormData();
      formData.append("file", blob, `image-${Date.now()}.jpg`);
      formData.append("accept", "image/*");
      const responses = await axiosClient({ isFile: true }, ENUM_APP_NAME.TOURIST_POLICE).post<typeGetFile>("/v1/files", formData);

      this.settingLanguageStore.result.image = responses?.data?.id;
      this.profile_image = responses?.data?.url;

      await this.settingLanguageStore.onSubmit(e);
      await this.authStore.get();

      alert("Attached Successfully!");
    },
    convertBlobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve((reader.result as string).split(",")[1]);
        };
        reader.readAsDataURL(blob);
      });
    },
    loading() {
      return false;
    },
    async fetch() {
      this.settingLanguageStore.loading = true;

      await Promise.all([
        (async () => {
          const fetch = new fetchAuth();
          const user = await fetch.Get();

          if (user.image) {
            const f = new fetchFile();
            this.profile_image = await f.GetUrl(user.image);
          }
          this.settingLanguageStore.result = user;
        })(),
      ]);
      this.settingLanguageStore.loading = false;
    },
    async reset() {
      await this.settingLanguageStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const masterDataStore = useMasterDataStore();
    const settingLanguageStore = useSettingLanguageStore();

    return {
      authStore,
      sharedStore,
      masterDataStore,
      settingLanguageStore,
      image,
    };
  },
});
</script>

<template>
  <div v-if="authStore.isToggle" class="flex w-full h-full fixed top-0 left-0 bg-black bg-opacity-50 z-[1002]"></div>
  <div v-if="authStore.isToggle" class="flex w-full justify-center fixed left-0 bottom-0 bg-white rounded-t-3xl z-[1002] pt-10 pb-6 px-10">
    <div class="absolute top-4 w-10 h-1 rounded-lg drop-shadow-lg bg-[#E0E0E0]"></div>
    <div class="flex flex-col w-full gap-3">
      <h1 class="ttp-text-xl font-bold text-center border-b-2 pb-2 text-[#CC2B34]">{{ $t("popup.logout.title") }}</h1>
      <div class="text-base font-bold text-center pb-2 text-black">{{ $t("popup.logout.detail") }}</div>
      <div class="flex w-full gap-4">
        <button
          @click="
            () => {
              authStore.isToggle = !authStore.isToggle;
            }
          "
          class="w-full px-4 bg-[#E9F0FF] rounded-[10px] justify-center items-center gap-2 inline-flex"
        >
          <div class="grow shrink basis-0 text-center text-indigo-900 ttp-text-sm font-bold py-2">{{ $t("btn.btn_cancel") }}</div>
        </button>
        <button
          @click="
            async () => {
              await authStore.logout();
              $window.location.href = $router.resolve({ name: $inArray($getProjectType(), [$enums.ENUM_APP_CATEGORY_NAME.AGENT]) ? 'LoginPoliceCommandCenterView' : 'LoginView' }).href;
            }
          "
          class="w-full px-4 bg-indigo-900 rounded-[10px] justify-center items-center gap-2 inline-flex"
        >
          <div class="grow shrink basis-0 text-center text-white ttp-text-sm font-bold py-2">{{ $t("btn.btn_logout") }}</div>
        </button>
      </div>
    </div>
  </div>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <div v-if="false" class="flex w-full h-full absolute top-0 left-0 bg-black bg-opacity-50 z-10"></div>
    <div v-if="false" class="flex w-full justify-center absolute bottom-0 left-0 bg-white rounded-t-3xl z-20 pt-10 pb-6 px-10">
      <div class="absolute top-4 w-10 h-1 rounded-lg drop-shadow-lg bg-[#E0E0E0]"></div>
      <div class="flex flex-col w-full gap-6">
        <router-link :to="{ name: 'ProfileMenuView' }" class="flex w-full gap-4 items-center justify-center">
          <div class="text-black"><component :is="$outlineIcons.PhotoIcon" class="inline-block ttp-icon-inside-box-03" /></div>
          <div class="text-black w-full ttp-text-sm">Choose from library</div>
          <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
        </router-link>
        <router-link :to="{ name: 'ProfileMenuView' }" class="flex w-full gap-4 items-center justify-center">
          <div class="text-black"><component :is="$outlineIcons.CameraIcon" class="inline-block ttp-icon-inside-box-03" /></div>
          <div class="text-black w-full ttp-text-sm">Take photo</div>
          <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
        </router-link>
        <router-link :to="{ name: 'ProfileMenuView' }" class="flex w-full gap-4 items-center justify-center">
          <div class="text-[#CC2B34]"><component :is="$outlineIcons.TrashIcon" class="inline-block ttp-icon-inside-box-03" /></div>
          <div class="text-[#CC2B34] w-full">Delete profile</div>
          <div class="text-[#CC2B34]"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
        </router-link>
      </div>
    </div>

    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center h-screen">
        <div class="flex flex-col w-full max-w-5xl pb-28 overflow-auto">
          <div class="flex items-center justify-center mx-2 my-5">
            <div class="w-10 text-black text-left">
              <button
                @click="
                  () => {
                    $router.push({ name: 'HomeView' });
                  }
                "
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
              </button>
            </div>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl"></h1>
            </div>
            <div class="w-10 text-white text-right">
              <div class="flex gap-4 justify-end items-end">
                <router-link :to="{ name: 'MessageView' }"
                  ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-black">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                  </svg>
                </router-link>
                <router-link :to="{ name: 'NotificationView' }">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-black">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>

          <div class="flex flex-col relative gap-2 items-center w-full md:p-0 h-full">
            <div class="swipe-photo relative inline-flex h-36 w-36 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
              <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-pencil-box" />
              <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center w-36 h-36 max-w-full rounded-full float-right" :src="profile_image" alt="" /></a>
              <div @click="defaultFormComponents.form.button.btnUploadImage.click" class="cursor-pointer text-center absolute bottom-0 right-0 bg-gray-100 rounded-full bg-ttp-primary ttp-icon-inside-box-02"><component :is="$solidIcons.PencilIcon" class="inline-block ttp-icon-inside-box-03 text-white" /></div>
            </div>
            <div class="text-black text-center ttp-text-xl font-bold">{{ authStore?.user?.name }}</div>
            <div class="text-black text-center ttp-text-sm">{{ authStore?.user?.contactNumberCountryCode }}+{{ authStore?.user?.contactNumber }}</div>
            <div class="flex flex-col relative gap-4 w-full mt-4 pt-4 px-4 md:px-10 border-t-2">
              <router-link :to="{ name: 'ProfileMenuView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.ProfileMenuView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <router-link :to="{ name: 'SettingNotificationView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.BellIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.SettingNotificationView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <router-link :to="{ name: 'SettingSecurityPrivacyView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.ShieldCheckIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.SettingSecurityPrivacyView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <router-link :to="{ name: 'SettingLanguageView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.EllipsisHorizontalCircleIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.SettingLanguageView.title") }}</div>
                <div class="text-black w-full text-right ttp-text-sm">{{ $t(`lang.${String(sharedStore?.locale).toLowerCase()}`) }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <router-link :to="{ name: 'SettingContactUsView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.InformationCircleIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.SettingContactUsView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <a
                to="#"
                @click="
                  () => {
                    authStore.isToggle = !authStore.isToggle;
                  }
                "
                class="flex gap-4 items-center justify-center cursor-pointer"
              >
                <div class="text-[#F75555]"><component :is="$outlineIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-[#F75555] w-full ttp-text-sm">{{ $t("popup.logout.title") }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
