<script lang="ts">
// @ts-nocheck
import { defineComponent } from "vue";
import { useSettingSecurityPrivacyStore } from "./stores/useSettingSecurityPrivacyStore";
import FormCheckBoxToggleComponent from "@/components/Forms/CheckBox/FormCheckBoxToggleComponent.vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { getLocalStorage, setLocalStorage } from "@/configs/helpers/MainHelper";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { Capacitor } from "@capacitor/core";
import { Camera } from "@capacitor/camera";

export default defineComponent({
  name: "SettingSecurityPrivacyView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          toggle: {
            is_track_location: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingSecurityPrivacyStore.error.is_track_location || "").length > 0,
                isIcon: false,
                isDisable: true,
                isLabel: true,
                isRequired: this.settingSecurityPrivacyStore.isRequired.is_track_location.is,
                textError: this.$t(this.settingSecurityPrivacyStore.isRequired.is_track_location.placeholder),
                title: "",
                placeholder: this.$t(this.settingSecurityPrivacyStore.isRequired.is_track_location.placeholder),
                value: this.settingSecurityPrivacyStore.result.is_track_location === "granted" ? this.$enums.ENUM_FIELD_STATUS.ACTIVE : this.$enums.ENUM_FIELD_STATUS.INACTIVE,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.settingSecurityPrivacyStore.isRequired.is_track_location.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: () => {
                // this.settingSecurityPrivacyStore.result.is_track_location = value;
              },
            },
            is_camera: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingSecurityPrivacyStore.error.is_camera || "").length > 0,
                isIcon: false,
                isDisable: true,
                isLabel: true,
                isRequired: this.settingSecurityPrivacyStore.isRequired.is_camera.is,
                textError: this.$t(this.settingSecurityPrivacyStore.isRequired.is_camera.placeholder),
                title: "",
                placeholder: this.$t(this.settingSecurityPrivacyStore.isRequired.is_camera.placeholder),
                value: this.settingSecurityPrivacyStore.result.is_camera === "granted" ? this.$enums.ENUM_FIELD_STATUS.ACTIVE : this.$enums.ENUM_FIELD_STATUS.INACTIVE,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.settingSecurityPrivacyStore.isRequired.is_camera.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: () => {
                // this.settingSecurityPrivacyStore.result.is_camera = value;
              },
            },
            is_photo: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingSecurityPrivacyStore.error.is_photo || "").length > 0,
                isIcon: false,
                isDisable: true,
                isLabel: true,
                isRequired: this.settingSecurityPrivacyStore.isRequired.is_photo.is,
                textError: this.$t(this.settingSecurityPrivacyStore.isRequired.is_photo.placeholder),
                title: "",
                placeholder: this.$t(this.settingSecurityPrivacyStore.isRequired.is_photo.placeholder),
                value: this.settingSecurityPrivacyStore.result.is_photo === "granted" ? this.$enums.ENUM_FIELD_STATUS.ACTIVE : this.$enums.ENUM_FIELD_STATUS.INACTIVE,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.settingSecurityPrivacyStore.isRequired.is_photo.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: () => {
                // this.settingSecurityPrivacyStore.result.is_chat = value;
              },
            },
            is_microphone: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingSecurityPrivacyStore.error.is_microphone || "").length > 0,
                isIcon: false,
                isDisable: true,
                isLabel: true,
                isRequired: this.settingSecurityPrivacyStore.isRequired.is_microphone.is,
                textError: this.$t(this.settingSecurityPrivacyStore.isRequired.is_microphone.placeholder),
                title: "",
                placeholder: this.$t(this.settingSecurityPrivacyStore.isRequired.is_microphone.placeholder),
                value: this.settingSecurityPrivacyStore.result.is_microphone === "granted" ? this.$enums.ENUM_FIELD_STATUS.ACTIVE : this.$enums.ENUM_FIELD_STATUS.INACTIVE,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.settingSecurityPrivacyStore.isRequired.is_microphone.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: () => {
                // this.settingSecurityPrivacyStore.result.is_microphone = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return false;
    },
    async geolocationToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        try {
          await navigator.geolocation.getCurrentPosition(
            async () => {
              await setLocalStorage(this.sharedStore.is_track_location, "granted");
              this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
            },
            async () => {
              await setLocalStorage(this.sharedStore.is_track_location, "denied");
              this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
            }
          );
        } catch (error) {
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
          this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
        }
      }
      this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
    },
    async geolocationPermission() {
      if (Capacitor.isNativePlatform()) {
        this.$PermissionsPlugin
          .checkPermissionLocation()
          .then(async (result: any) => {
            await setLocalStorage(this.sharedStore.is_track_location, result.value);
            this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
          })
          .catch(async (_error: any) => {
            await setLocalStorage(this.sharedStore.is_track_location, "denied");
            this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
          });
      } else {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(async (result) => {
            await setLocalStorage(this.sharedStore.is_track_location, result.state);
            this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
            result.onchange = async () => {
              await setLocalStorage(this.sharedStore.is_track_location, result.state);
              this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
            };
          })
          .catch(async (e) => {
            await setLocalStorage(this.sharedStore.is_track_location, "denied");
            this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
          });
      }
    },
    async cameraToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          stream.getTracks().forEach((track) => track.stop());
          await setLocalStorage(this.sharedStore.is_camera, "granted");
          this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
        } catch (error) {
          await setLocalStorage(this.sharedStore.is_camera, "denied");
          this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
        }
      }
    },
    async cameraPermission() {
      if (Capacitor.isNativePlatform()) {
        this.$PermissionsPlugin
          .checkPermissionCamera()
          .then(async (result: any) => {
            await setLocalStorage(this.sharedStore.is_camera, result.value);
            this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
          })
          .catch(async (_error: any) => {
            await setLocalStorage(this.sharedStore.is_camera, "denied");
            this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
          });
      } else {
        const cameraStatus = await Camera.checkPermissions();
        if (cameraStatus.camera == "granted") {
          await setLocalStorage(this.sharedStore.is_camera, "granted");
          this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
          cameraStatus.onchange = async () => {
            await setLocalStorage(this.sharedStore.is_camera, "granted");
            this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
          };
        } else {
          await setLocalStorage(this.sharedStore.is_camera, "denied");
          this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
        }
      }
    },
    async photoToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        await setLocalStorage(this.sharedStore.is_photo, "granted");
        this.settingSecurityPrivacyStore.result.is_photo = await getLocalStorage(this.sharedStore.is_photo);
      }
    },
    async photoPermission() {
      if (Capacitor.isNativePlatform()) {
        this.$PermissionsPlugin
          .checkPermissionPhoto()
          .then(async (result: any) => {
            await setLocalStorage(this.sharedStore.is_photo, result.value);
            this.settingSecurityPrivacyStore.result.is_photo = await getLocalStorage(this.sharedStore.is_photo);
          })
          .catch(async (_error: any) => {
            await setLocalStorage(this.sharedStore.is_photo, "denied");
            this.settingSecurityPrivacyStore.result.is_photo = await getLocalStorage(this.sharedStore.is_photo);
          });
      } else {
        const cameraStatus = await Camera.checkPermissions();
        if (cameraStatus.photos == "granted") {
          await setLocalStorage(this.sharedStore.is_photo, "granted");
          this.settingSecurityPrivacyStore.result.is_photo = await getLocalStorage(this.sharedStore.is_photo);
        } else {
          await setLocalStorage(this.sharedStore.is_photo, "denied");
          this.settingSecurityPrivacyStore.result.is_photo = await getLocalStorage(this.sharedStore.is_photo);
        }
      }
    },
    async microphoneToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach((track) => track.stop());
          await setLocalStorage(this.sharedStore.is_microphone, "granted");
          this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
        } catch (error) {
          this.openAppSetting();
          await setLocalStorage(this.sharedStore.is_microphone, "denied");
          this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
        }
      }
    },
    async microphonePermission() {
      if (Capacitor.isNativePlatform()) {
        this.$PermissionsPlugin
          .checkPermissionMicrophone()
          .then(async (result: any) => {
            await setLocalStorage(this.sharedStore.is_microphone, result.value);
            this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
          })
          .catch(async (_error: any) => {
            await setLocalStorage(this.sharedStore.is_microphone, "denied");
            this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
          });
      } else {
        navigator.permissions
          .query({ name: "microphone" as any })
          .then(async (result) => {
            await setLocalStorage(this.sharedStore.is_microphone, result.state);
            this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
            result.onchange = async () => {
              await setLocalStorage(this.sharedStore.is_microphone, result.state);
              this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
            };
          })
          .catch(async () => {
            await setLocalStorage(this.sharedStore.is_microphone, "denied");
            this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
          });
      }
    },
    async openAppSetting() {
      if (Capacitor.isNativePlatform()) {
        await this.$PermissionsPlugin.openAppSettings();
      }
    },
  },
  async mounted() {
    if (Capacitor.isNativePlatform()) {
      this.$PermissionsPlugin.addListener("locationPermissionChange", async (data: any) => {
        await setLocalStorage(this.sharedStore.is_track_location, data.status);
        this.settingSecurityPrivacyStore.result.is_track_location = await getLocalStorage(this.sharedStore.is_track_location);
      });
      this.$PermissionsPlugin.addListener("photoPermissionChange", async (data: any) => {
        await setLocalStorage(this.sharedStore.is_photo, data.status);
        this.settingSecurityPrivacyStore.result.is_photo = await getLocalStorage(this.sharedStore.is_photo);
      });
      this.$PermissionsPlugin.addListener("microphonePermissionChange", async (data: any) => {
        await setLocalStorage(this.sharedStore.is_microphone, data.status);
        this.settingSecurityPrivacyStore.result.is_microphone = await getLocalStorage(this.sharedStore.is_microphone);
      });
      this.$PermissionsPlugin.addListener("cameraPermissionChange", async (data: any) => {
        await setLocalStorage(this.sharedStore.is_camera, data.status);
        this.settingSecurityPrivacyStore.result.is_camera = await getLocalStorage(this.sharedStore.is_camera);
      });
    }
  },
  unmounted() {
    if (Capacitor.isNativePlatform()) {
      this.$PermissionsPlugin.removeListener("locationPermissionChange");
      this.$PermissionsPlugin.removeListener("photoPermissionChange");
      this.$PermissionsPlugin.removeListener("microphonePermissionChange");
      this.$PermissionsPlugin.removeListener("cameraPermissionChange");
    }
  },
  async created() {
    this.geolocationPermission();
    this.cameraPermission();
    this.photoPermission();
    this.microphonePermission();
  },
  setup() {
    const settingSecurityPrivacyStore = useSettingSecurityPrivacyStore();
    const authStore = useAuthStore();
    const sharedStore = useSharedStore();

    return {
      settingSecurityPrivacyStore,
      authStore,
      sharedStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'SettingMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.SettingSecurityPrivacyView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="flex w-full items-center">
              <div class="w-full text-black ttp-text-sm">{{ $t("page.SettingSecurityPrivacyView.allow_location") }}</div>
              <div class="w-full text-right">
                <component @click="geolocationToggle" :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.toggle.is_track_location.component" v-bind="defaultFormComponents.form.toggle.is_track_location.props" @updateValue="defaultFormComponents.form.toggle.is_track_location.onUpdateValue" />
              </div>
            </div>
            <div class="flex w-full items-center">
              <div class="w-full text-black ttp-text-sm">{{ $t("page.SettingSecurityPrivacyView.allow_camera") }}</div>
              <div class="w-full text-right">
                <component @click="cameraToggle" :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.toggle.is_camera.component" v-bind="defaultFormComponents.form.toggle.is_camera.props" @updateValue="defaultFormComponents.form.toggle.is_camera.onUpdateValue" />
              </div>
            </div>
            <div class="flex w-full items-center">
              <div class="w-full text-black ttp-text-sm">{{ $t("page.SettingSecurityPrivacyView.allow_photo") }}</div>
              <div class="w-full text-right">
                <component @click="photoToggle" :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.toggle.is_photo.component" v-bind="defaultFormComponents.form.toggle.is_photo.props" @updateValue="defaultFormComponents.form.toggle.is_photo.onUpdateValue" />
              </div>
            </div>
            <div class="flex w-full items-center">
              <div class="w-full text-black ttp-text-sm">{{ $t("page.SettingSecurityPrivacyView.allow_mic") }}</div>
              <div class="w-full text-right">
                <component @click="microphoneToggle" :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.toggle.is_microphone.component" v-bind="defaultFormComponents.form.toggle.is_microphone.props" @updateValue="defaultFormComponents.form.toggle.is_microphone.onUpdateValue" />
              </div>
            </div>
            <div class="flex w-full items-center">
              <router-link :to="{ name: 'SettingSecurityPrivacyChangePasswordView' }" class="w-full px-4 bg-[#E9F0FF] rounded-[10px] justify-center items-center gap-2 inline-flex mt-2"><div class="grow shrink basis-0 text-center text-[#2C3483] ttp-text-base font-bold py-2">Change Password</div></router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
