<script lang="ts">
import { defineComponent } from "vue";
import * as yup from "yup";

import { useAuthStore as useAuthPoliceCommandCenterStore, type Credential } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useAuthStore as useAuthPoliceTouristAndAgentStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";

import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormCheckBoxDefaultComponent from "@/components/Forms/CheckBox/FormCheckBoxDefaultComponent.vue";

import { EnvelopeIcon, LockClosedIcon } from "@heroicons/vue/24/outline";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { ENUM_FIELD_LANGUAGE } from "@/configs/enums/enum";
import type { $enum } from "@/configs/types/Shared/typeShare";
import { isCommandCenterApp, setLocalStorage } from "@/configs/helpers/MainHelper";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";

export default defineComponent({
  name: "LoginPoliceCommandCenterView",
  components: {
    FormInputDefaultComponent,
    ButtonDefaultComponent,
    FormCheckBoxDefaultComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
      form: {
        email: "",
        password: "",
        rememberMe: false,
      } as Credential,
      formError: {} as { [key: string]: string },
      errorMessage: "",
      loading: false,
      validate: yup.object().shape({
        email: yup.string().required(this.$t("form.email.placeholder")),
        password: yup.string().min(6, "รหัสผ่านควรมีอย่างน้อย 6 ตัวอักษร").required(this.$t("form.password.placeholder")),
      }),
      isRoute: isCommandCenterApp,
    };
  },
  computed: {
    components() {
      return {
        form: {
          input: {
            email: {
              component: FormInputDefaultComponent,
              props: {
                name: "email",
                isSpecial: false,
                isVisible: true,
                isError: this.formError["email"]?.length > 0,
                isIcon: true,
                isDisable: this.loading,
                isRequired: true,
                textError: this.formError["email"],
                componentIcon: this.icons.EnvelopeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                title: this.$t("form.idCard.label"),
                placeholder: this.$t("form.idCard.placeholder"),
                value: this.form.email,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: any) => {
                this.form.email = value.trim();
              },
            },
            password: {
              component: FormInputDefaultComponent,
              props: {
                name: "password",
                isSpecial: false,
                isVisible: true,
                isError: this.formError["password"]?.length > 0,
                isIcon: true,
                isDisable: this.loading,
                isRequired: true,
                textError: this.formError["password"],
                componentIcon: this.icons.LockClosedIcon,
                // componentIcon_2: this.$solidIcons.EyeSlashIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                type: "password",
                title: this.$t("form.password.label"),
                placeholder: this.$t("form.password.placeholder"),
                value: this.form.password,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: any) => {
                this.form.password = value.trim();
              },
            },
          },
          button: {
            btnSignin: {
              component: ButtonDefaultComponent,
              props: {} as typeof ButtonDefaultComponent.props,
            },
          },
          checkbox: {
            rememberMe: {
              component: FormCheckBoxDefaultComponent,
              props: {} as typeof FormCheckBoxDefaultComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    async setLang(value: ENUM_FIELD_LANGUAGE | $enum) {
      this.sharedStore.locale = String(value).toUpperCase();
      await setLocalStorage(`${this.storeName}:locale`, String(value).toUpperCase());
      this.$i18n.locale = String(value).toLowerCase();
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      this.loading = true;
      this.resetError();

      try {
        await this.validate.validate(this.form, {
          abortEarly: false,
        });
        await this.authStore.login(this.form);
        await this.fetch();
        if (this.appName == this.$enums.ENUM_APP_NAME.TOURIST_POLICE) {
          this.$router.push({ name: "IncidentListView" });
        } else {
          this.$router.push({ name: "OverviewView" });
        }
      } catch (e) {
        if (e instanceof yup.ValidationError) {
          e.inner.forEach(({ path, message }) => {
            if (path) {
              this.formError[path] = message;
            }
          });
        } else {
          this.errorMessage = this.$t("error.invalid_credential");
        }
      } finally {
        this.loading = false;
      }
    },
    resetError() {
      this.formError = {};
      this.errorMessage = "";
    },
    async fetch() {
      await Promise.all([this.masterDataStore.listTitle(), this.masterDataStore.listLocale(), this.masterDataStore.listRole(), this.masterDataStore.listNationality(), this.masterDataStore.listIncidentCategory(), this.masterDataStore.listProvince(), this.masterDataStore.listCountry()]);
      if (isCommandCenterApp) await Promise.all([this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0)]);
    },
    async setting() {
      await this.setLang(this.$enums.ENUM_FIELD_LANGUAGE.TH);
      await setLocalStorage(`${this.storeName}:getProjectType`, String(this.$enums.ENUM_APP_CATEGORY_NAME.AGENT));
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {},
  setup() {
    const authStore = isCommandCenterApp ? useAuthPoliceCommandCenterStore() : useAuthPoliceTouristAndAgentStore();
    const masterDataStore = useMasterDataStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const storeName = isCommandCenterApp ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent;
    return {
      authStore,
      masterDataStore,
      sharedStore,
      icons: { EnvelopeIcon, LockClosedIcon },
      storeName,
      appName: import.meta.env.VITE_APP_NAME,
    };
  },
});
</script>

<template>
  <div id="page-container" class="mx-auto flex min-h-screen w-full min-w-[320px] flex-col bg-gray-100 text-ttp-text-primary">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex min-h-screen w-full max-w-10xl items-center justify-center bg-gray-200/50 p-4">
        <div class="w-full max-w-5xl">
          <div class="relative flex flex-col overflow-hidden border rounded-md bg-white shadow-md md:flex-row drop-shadow-md">
            <section :class="{ 'relative flex items-center justify-center bg-cover md:w-1/2 md:px-10 lg:p-16 ': true, 'bg-ttp-text-primary': appName != $enums.ENUM_APP_NAME.TOURIST_POLICE }">
              <div class="relative text-center sm:text-left">
                <img src="@/assets/images/ttp-logo.png" class="ttp-icon-inside-box-05" />
              </div>
            </section>
            <section class="relative md:w-1/2 p-4 md:p-10 lg:p-14 lg:p-24">
              <Menu v-if="appName == $enums.ENUM_APP_NAME.TOURIST_POLICE" as="div" class="inline-block absolute top-0 right-0">
                <MenuButton class="p-2">
                  <component :is="$outlineIcons.EllipsisVerticalIcon" class="inline-block ttp-icon-inside-box-03" />
                </MenuButton>
                <MenuItems class="absolute right-1 origin-top-right z-10 mt-2 w-4/4 shadow-md rounded-lg dark:shadow-gray-900 focus:outline-none z-[100]">
                  <div class="bg-white ring-1 ring-black ring-opacity-5 rounded-lg divide-y divide-gray-100 dark:bg-gray-800 dark:divide-gray-700 dark:ring-gray-700">
                    <div class="space-y-1">
                      <MenuItem v-slot="{ active }">
                        <router-link
                          :to="{ name: 'LoginPoliceCommandCenterView' }"
                          class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                          :class="{
                            'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                            'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                          }"
                        >
                          <span class="grow text-gray-500 ttp-text-xs font-normal">Police</span>
                        </router-link>
                      </MenuItem>
                    </div>
                    <div class="space-y-1">
                      <MenuItem v-slot="{ active }">
                        <router-link
                          :to="{ name: 'LoginView' }"
                          class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                          :class="{
                            'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                            'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                          }"
                        >
                          <span class="grow text-gray-500 ttp-text-xs font-normal">Tourist</span>
                        </router-link>
                      </MenuItem>
                    </div>
                  </div>
                </MenuItems>
              </Menu>
              <div>
                <h1 class="mb-1 ttp-text-xl font-bold">
                  {{ $t("page.LoginView.title") }}
                </h1>
                <h2 class="ttp-text-xs font-normal text-ttp-text-secondary mb-6">
                  {{ $t(appName == $enums.ENUM_APP_NAME.TOURIST_POLICE ? "page.LoginView.agent_subtitle" : "page.LoginView.subtitle") }}
                </h2>
              </div>

              <form @submit="onSubmit">
                <div class="grid grid-cols-12 gap-2">
                  <component :classTagName="'col-start-1 col-end-13'" :is="components.form.input.email.component" v-bind="components.form.input.email.props" @updateValue="components.form.input.email.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13'" :is="components.form.input.password.component" v-bind="components.form.input.password.props" @updateValue="components.form.input.password.onUpdateValue" />
                  <div class="col-start-1 col-end-13">
                    <div class="flex">
                      <FormCheckBoxDefaultComponent
                        name="rememberMe"
                        textError=""
                        htmlIcon=""
                        :isSpecial="false"
                        :isVisible="true"
                        :isError="false"
                        :isIcon="false"
                        :isDisable="loading"
                        :isRequired="false"
                        :title="$t('form.remember_me.label')"
                        :isRightPlaceholder="true"
                        :placeholder="$t('form.remember_me.placeholder')"
                        :value="form.rememberMe ? 'true' : 'false'"
                        :classEnumName="'text-ttp-primary'"
                        :items="[{ label: '', value: 'true' }]"
                        @updateValue="
                          (values: any[]) => {
                            form.rememberMe = Boolean(values.length);
                            authStore.setRememberMe(form.rememberMe)
                          }
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="ttp-between">
                  <ButtonDefaultComponent :classTagName="'w-full'" :isSpecial="false" :isVisible="true" :isIcon="true" :isLoading="loading" :isDisable="loading" :htmlIcon="''" :isSubmit="true" :title="$t('btn.btn_sign_in')" :classEnumName="$enums.ENUM_COLOR.DEFAULT_1" :size="$enums.ENUM_SIZE.SMALL" />
                </div>
                <small v-if="errorMessage.length" class="mt-2 text-ttp-text-error">
                  {{ errorMessage }}
                </small>
              </form>
            </section>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
