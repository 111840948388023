<script lang="ts">
import { defineComponent } from "vue";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import PageSidebarMenuComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageSidebarMenuComponent.vue";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "PageSidebarComponent",
  components: {
    PageSidebarMenuComponent,
  },
  setup() {
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    return {
      sharedStore,
    };
  },
  computed: {},
});
</script>

<template>
  <nav
    id="page-sidebar"
    class="flex flex-col fixed top-0 left-0 bottom-0 w-full lg:w-64 h-full bg-ttp-text-primary border-gray-200 z-[101] transition-transform duration-500 ease-out"
    :class="{
      '-translate-x-full': !sharedStore.mobileSidebarOpen,
      'translate-x-0': sharedStore.mobileSidebarOpen,
      'lg:-translate-x-full': !sharedStore.desktopSidebarOpen,
      'lg:translate-x-0': sharedStore.desktopSidebarOpen,
    }"
    aria-label="Main Sidebar Navigation"
  >
    <div class="flex-none flex items-center justify-between lg:justify-center px-4 w-full">
      <div class="flex flex-col items-center pt-16 w-full">
        <a href="#">
          <img src="@/assets/images/ttp-logo.png" class="ttp-icon-inside-box-05" />
        </a>
        <span class="text-center text-white">{{ $t("layout.appName") }}</span>
      </div>
      <div class="lg:hidden absolute top-4 right-4">
        <button @click="sharedStore.mobileSidebarOpen = false" type="button" class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 ttp-text-xs border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
          <component :is="$solidIcons.XMarkIcon" class="inline-block ttp-icon-inside-box-03 -mx-0.5" />
        </button>
      </div>
    </div>
    <div class="overflow-y-auto">
      <div class="p-4 w-full">
        <nav class="space-y-1">
          <PageSidebarMenuComponent />
        </nav>
      </div>
    </div>
  </nav>
</template>
