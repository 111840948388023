import AdminLayoutView from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/AdminLayoutView.vue";

import ProfileView from "@/views/PoliceTouristAndAgent/Agent/Profile/ProfileView.vue";
import PasswordSettingView from "@/views/PoliceCommandCenter/Profile/PasswordSettingView.vue";
import HistoryListView from "@/views/PoliceTouristAndAgent/Agent/History/Index.vue";
import HistoryDetailView from "@/views/PoliceTouristAndAgent/Agent/History/Detail.vue";
import NewsInternalView from "@/views/PoliceTouristAndAgent/Agent/News/NewsInternalView.vue";
import NewsInternalDetailView from "@/views/PoliceTouristAndAgent/Agent/News/NewsInternalDetailView.vue";

import IncidentListView from "@/views/PoliceTouristAndAgent/Agent/Incident/Index.vue";
import IncidentDetailView from "@/views/PoliceTouristAndAgent/Agent/Incident/Detail.vue";

import AppraisalListView from '@/views/PoliceTouristAndAgent/Agent/Appraisal/Index.vue'
import AppraisalResultView from '@/views/PoliceTouristAndAgent/Agent/Appraisal/Result.vue'
import AppraisalFormView from '@/views/PoliceTouristAndAgent/Agent/Appraisal/Form.vue'

export const routePoliceAgent: any = [
  {
    path: "/agent/incidents",
    name: "Incident",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "Incidents",
      i18n: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "agent",
      activeMenu: [],
    },
    children: [
      {
        path: "",
        name: "IncidentListView",
        component: IncidentListView,
        meta: {
          requiresAuth: true,
          title: "Home",
          i18n: "layout.menu.IncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["IncidentListView"],
        },
      },
      {
        path: ":id",
        name: "IncidentDetailView",
        component: IncidentDetailView,
        meta: {
          requiresAuth: true,
          title: "IncidentDetail",
          i18n: "layout.menu.IncidentDetailView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["IncidentListView"],
        },
      },
    ],
  },
  {
    path: "/agent/redirect",
    name: "RedirectView",
    redirect: (to: any) => {
      const redirect = { name: to.query?.view, params: JSON.parse(to.query?.params || "") };
      return redirect;
    },
  },
  {
    path: "/agent/profile",
    name: "ProfileTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: false,
      title: "Profile",
      i18n: "layout.menu_head.ProfileTitle",
      icon: '<svg class="hi-outline hi-user-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      isSpecial: false,
      getProjectType: "agent",
      activeMenu: [],
    },
    children: [
      {
        path: "",
        name: "ProfileView",
        component: ProfileView,
        meta: {
          requiresAuth: true,
          title: "User",
          i18n: "layout.menu.ProfileView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["ProfileView"],
        },
      },
      {
        path: "setting",
        name: "PasswordSettingView",
        component: PasswordSettingView,
        meta: {
          requiresAuth: true,
          title: "User",
          i18n: "layout.menu.PasswordSettingView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["PasswordSettingView"],
        },
      },
    ],
  },
  {
    path: "/agent/news",
    name: "NewsTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: false,
      title: "NewsTitle",
      i18n: "layout.menu_head.NewsTitle",
      icon: '<svg class="hi-outline hi-user-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      isSpecial: false,
      getProjectType: "agent",
      activeMenu: [],
    },
    children: [
      {
        path: "",
        name: "NewsInternalView",
        component: NewsInternalView,
        meta: {
          requiresAuth: true,
          title: "NewsInternalView",
          i18n: "layout.menu.NewsInternalView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["NewsInternalView", "NewsInternalDetailView"],
        },
      },
      {
        path: ":id",
        name: "NewsInternalDetailView",
        component: NewsInternalDetailView,
        meta: {
          requiresAuth: true,
          title: "NewsInternalView",
          i18n: "layout.menu.NewsInternalView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["NewsInternalView", "NewsInternalDetailView"],
        },
      },
    ],
  },
  {
    path: "/agent/history",
    name: "OtherTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: false,
      title: "OtherTitle",
      i18n: "layout.menu_head.OtherTitle",
      icon: '<svg class="hi-outline hi-user-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      isSpecial: false,
      getProjectType: "agent",
      activeMenu: [],
    },
    children: [
      {
        path: "",
        name: "HistoryView",
        component: HistoryListView,
        meta: {
          requiresAuth: true,
          title: "History",
          i18n: "layout.menu.HistoryView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["HistoryView", "HistoryNoticeView", "HistoryReportView"],
        },
      },
      {
        path: ":id",
        name: "HistoryNoticeView",
        component: HistoryDetailView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.HistoryNoticeView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["HistoryView", "HistoryNoticeView", "HistoryReportView"],
        },
      },
    ],
  },
  {
    path: "/agent/appraisal",
    name: "Appraisal",
    component: AdminLayoutView,
    meta: {
      requiresAuth: false,
      title: "OtherTitle",
      i18n: "layout.menu_head.OtherTitle",
      icon: '<svg class="hi-outline hi-user-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      isSpecial: false,
      getProjectType: "agent",
      activeMenu: [],
    },
    children: [
      {
        path: "",
        name: "AppraisalList",
        component: AppraisalListView,
        meta: {
          requiresAuth: true,
          title: "AppraisalList",
          i18n: "layout.menu.AppraisalList",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["Appraisal", "AppraisalList"],
        },
      },
      {
        path: ":appraisal",
        name: "AppraisalResult",
        component: AppraisalResultView,
        meta: {
          requiresAuth: true,
          title: "AppraisalResult",
          i18n: "layout.menu.AppraisalList",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["Appraisal", "AppraisalResult"],
        },
      },
      {
        path: ":appraisal/form",
        name: "AppraisalForm",
        component: AppraisalFormView,
        meta: {
          requiresAuth: true,
          title: "AppraisalForm",
          i18n: "layout.menu.AppraisalList",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["Appraisal", "AppraisalForm"],
        },
      },
      {
        path: ":appraisal/results/:result",
        name: "AppraisalResultDetail",
        component: AppraisalFormView,
        meta: {
          requiresAuth: true,
          title: "AppraisalResultDetail",
          i18n: "layout.menu.AppraisalList",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "agent",
          activeMenu: ["Appraisal", "AppraisalResultDetail"],
        },
      },
    ],
  },
];
