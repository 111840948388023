<script lang="ts">
import { defineComponent } from "vue";
import { useProfileSocialMediaAccountStore } from "./stores/useProfileSocialMediaAccountStore";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";

export default defineComponent({
  name: "ProfileSocialMediaAccountView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    components() {
      return {};
    },
  },
  methods: {
    loading() {
      return false;
    },
    async fetch() {
      await Promise.all([this.masterDataStore.listTitle(), this.masterDataStore.listLocale(), this.masterDataStore.listRole(), this.masterDataStore.listNationality(), this.masterDataStore.listIncidentCategory(), this.masterDataStore.listProvince(), this.masterDataStore.listCountry()]);
      if (isCommandCenterApp) await Promise.all([this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0)]);
    },
    async googleLinkHandler() {
      await this.authStore.linkWithThirdParty("GOOGLE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "ProfileUnlinkView", params: { socialType: "google" } });
      }
    },
    async facebookLinkHandler() {
      await this.authStore.linkWithThirdParty("FACEBOOK");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "ProfileUnlinkView", params: { socialType: "facebook" } });
      }
    },
    async appleLinkHandler() {
      await this.authStore.linkWithThirdParty("APPLE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "ProfileUnlinkView", params: { socialType: "apple" } });
      }
    },
  },
  setup() {
    const profileSocialMediaAccountStore = useProfileSocialMediaAccountStore();
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    return {
      profileSocialMediaAccountStore,
      authStore,
      masterDataStore,
      sharedStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'ProfileMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.ProfileSocialMediaAccountView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="flex flex-col w-full gap-6 py-4">
              <button @click="facebookLinkHandler" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black">
                  <svg class="ttp-icon-inside-box-02" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_76_25218)">
                      <path d="M15 0C6.7155 0 0 6.7155 0 15C0 23.2845 6.7155 30 15 30C23.2845 30 30 23.2845 30 15C30 6.7155 23.2845 0 15 0Z" fill="url(#paint0_linear_76_25218)" />
                      <path d="M17.1606 21.0721H21.2313L21.8707 16.9369H17.1606V14.6766C17.1606 12.959 17.7221 11.4356 19.3288 11.4356H21.9108V7.82737C21.457 7.76602 20.4975 7.63232 18.6847 7.63232C14.8987 7.63232 12.6793 9.63151 12.6793 14.1867V16.9377H8.78711V21.0729H12.6785V32.4389C13.4492 32.5537 14.2302 32.6323 15.0316 32.6323C15.7559 32.6323 16.463 32.5663 17.1606 32.4719V21.0721Z" fill="white" />
                    </g>
                    <defs>
                      <linearGradient id="paint0_linear_76_25218" x1="4.49475" y1="4.49475" x2="27.4612" y2="27.4612" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#2AA4F4" />
                        <stop offset="1" stop-color="#007AD9" />
                      </linearGradient>
                      <clipPath id="clip0_76_25218">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="text-black w-full text-right ttp-text-sm">{{ $t("page.ProfileSocialMediaAccountView.link_txt") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </button>
              <button @click="googleLinkHandler" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black">
                  <svg class="ttp-icon-inside-box-02" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_76_25245)">
                      <path d="M29.9842 15.9173C29.9842 14.637 29.8821 13.7028 29.6613 12.7339H15.2988V18.5125H23.7292C23.5593 19.9486 22.6415 22.1113 20.6018 23.5645L20.5732 23.758L25.1144 27.339L25.429 27.3709C28.3184 24.6546 29.9842 20.6579 29.9842 15.9173Z" fill="#4285F4" />
                      <path d="M15.2984 31.1428C19.4286 31.1428 22.896 29.7586 25.4286 27.3711L20.6014 23.5647C19.3097 24.4816 17.576 25.1218 15.2984 25.1218C11.2532 25.1218 7.8198 22.4055 6.5959 18.6511L6.4165 18.6666L1.69456 22.3865L1.63281 22.5612C4.14834 27.6478 9.31543 31.1428 15.2984 31.1428Z" fill="#34A853" />
                      <path d="M6.5967 18.6509C6.27377 17.682 6.08687 16.6439 6.08687 15.5712C6.08687 14.4985 6.27377 13.4604 6.57971 12.4915L6.57116 12.2852L1.79005 8.50562L1.63362 8.58136C0.596853 10.6922 0.00195312 13.0625 0.00195312 15.5712C0.00195312 18.0799 0.596853 20.4502 1.63362 22.561L6.5967 18.6509Z" fill="#FBBC05" />
                      <path d="M15.2984 6.02087C18.1708 6.02087 20.1084 7.28387 21.2133 8.33934L25.5305 4.04854C22.8791 1.53984 19.4286 0 15.2984 0C9.31542 0 4.14833 3.49487 1.63281 8.58146L6.5789 12.4917C7.81979 8.73724 11.2531 6.02087 15.2984 6.02087Z" fill="#EB4335" />
                    </g>
                    <defs>
                      <clipPath id="clip0_76_25245">
                        <rect width="30" height="31.25" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="text-black w-full text-right ttp-text-sm">{{ $t("page.ProfileSocialMediaAccountView.link_txt") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </button>
              <button @click="appleLinkHandler" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black">
                  <svg class="ttp-icon-inside-box-02" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_76_25259)">
                      <path d="M20.9661 0.265013C20.9024 0.193763 18.6055 0.293138 16.6067 2.46251C14.608 4.63001 14.9155 7.11626 14.9605 7.18001C15.0055 7.24376 17.8105 7.34314 19.6011 4.82126C21.3917 2.29939 21.0299 0.338138 20.9661 0.265013ZM27.1799 22.2644C27.0899 22.0844 22.8205 19.9506 23.218 15.8481C23.6155 11.7438 26.3586 10.6188 26.4017 10.4969C26.4449 10.375 25.2824 9.01564 24.0505 8.32751C23.146 7.84236 22.145 7.5644 21.1199 7.51376C20.9174 7.50814 20.2142 7.33564 18.7686 7.73126C17.8161 7.99189 15.6692 8.83564 15.0786 8.86939C14.4861 8.90314 12.7236 7.89064 10.828 7.62251C9.61486 7.38814 8.32861 7.86814 7.40799 8.23751C6.48924 8.60501 4.74174 9.65126 3.51924 12.4319C2.29674 15.2106 2.93611 19.6131 3.39361 20.9819C3.85111 22.3488 4.56549 24.5894 5.78049 26.2244C6.86049 28.0694 8.29299 29.35 8.89111 29.785C9.48924 30.22 11.1767 30.5088 12.3467 29.9106C13.288 29.3331 14.9867 29.0013 15.658 29.0256C16.3274 29.05 17.6474 29.3144 18.9992 30.0363C20.0699 30.4056 21.0824 30.2519 22.0967 29.8394C23.1111 29.425 24.5792 27.8538 26.293 24.6681C26.9436 23.1869 27.2399 22.3863 27.1799 22.2644Z" fill="black" />
                      <path d="M20.9661 0.265013C20.9024 0.193763 18.6055 0.293138 16.6067 2.46251C14.608 4.63001 14.9155 7.11626 14.9605 7.18001C15.0055 7.24376 17.8105 7.34314 19.6011 4.82126C21.3917 2.29939 21.0299 0.338138 20.9661 0.265013ZM27.1799 22.2644C27.0899 22.0844 22.8205 19.9506 23.218 15.8481C23.6155 11.7438 26.3586 10.6188 26.4017 10.4969C26.4449 10.375 25.2824 9.01564 24.0505 8.32751C23.146 7.84236 22.145 7.5644 21.1199 7.51376C20.9174 7.50814 20.2142 7.33564 18.7686 7.73126C17.8161 7.99189 15.6692 8.83564 15.0786 8.86939C14.4861 8.90314 12.7236 7.89064 10.828 7.62251C9.61486 7.38814 8.32861 7.86814 7.40799 8.23751C6.48924 8.60501 4.74174 9.65126 3.51924 12.4319C2.29674 15.2106 2.93611 19.6131 3.39361 20.9819C3.85111 22.3488 4.56549 24.5894 5.78049 26.2244C6.86049 28.0694 8.29299 29.35 8.89111 29.785C9.48924 30.22 11.1767 30.5088 12.3467 29.9106C13.288 29.3331 14.9867 29.0013 15.658 29.0256C16.3274 29.05 17.6474 29.3144 18.9992 30.0363C20.0699 30.4056 21.0824 30.2519 22.0967 29.8394C23.1111 29.425 24.5792 27.8538 26.293 24.6681C26.9436 23.1869 27.2399 22.3863 27.1799 22.2644Z" fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_76_25259">
                        <rect width="30" height="30" fill="white" transform="translate(0 0.25)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="text-black w-full text-right ttp-text-sm">{{ $t("page.ProfileSocialMediaAccountView.link_txt") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
