import { ENUM_INCIDENT_STATUS, ENUM_INCIDENT_TYPE } from "@/configs/enums/enum";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import { fetchReport, type SimpleReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import type { typeIncident } from "@/configs/types/Incident/typeIncident";
import type { typeGetPagination } from "@/configs/types/Shared/typePagination";
import { defineStore } from "pinia";

export type IncidentGroup = {
  key: ENUM_INCIDENT_TYPE;
  activeTab: ENUM_INCIDENT_STATUS;
  tabs: Tab[]
}

export type Tab = {
  key: ENUM_INCIDENT_STATUS;
  loading: boolean;
  data: typeGetPagination<typeIncident>
}

type State = {
  incidentGroup: IncidentGroup[]
  incidentReport: SimpleReport
}

const fetch = new fetchIncident()
const report = new fetchReport()

export const useOverviewStore = defineStore('overview', {
  state: (): State => ({
    incidentGroup: [
      {
        key: ENUM_INCIDENT_TYPE.DEFAULT,
        activeTab: ENUM_INCIDENT_STATUS.NEW,
        tabs: [
          {
            key: ENUM_INCIDENT_STATUS.NEW,
            loading: false,
            data: { items: [], page: 1, size: 0, totalPages: 1, total: 0 },
          },
          {
            key: ENUM_INCIDENT_STATUS.ACCEPTED,
            loading: false,
            data: { items: [], page: 1, size: 0, totalPages: 1, total: 0 },
          },
        ],
      },
      {
        key: ENUM_INCIDENT_TYPE.SOS,
        activeTab: ENUM_INCIDENT_STATUS.NEW,
        tabs: [
          {
            key: ENUM_INCIDENT_STATUS.NEW,
            loading: false,
            data: { items: [], page: 1, size: 0, totalPages: 1, total: 0 },
          },
          {
            key: ENUM_INCIDENT_STATUS.ACCEPTED,
            loading: false,
            data: { items: [], page: 1, size: 0, totalPages: 1, total: 0 },
          },
        ],
      },
    ],
    incidentReport: {},
  }),
  actions: {
    async fetch(type: ENUM_INCIDENT_TYPE, status: ENUM_INCIDENT_STATUS, page: number) {
      const group = this.incidentGroup.find(({ key }) => key === type)
      const tab = group?.tabs?.find(({ key }) => key === status)
      if (!tab) {
        return
      }

      Object.assign(tab, { loading: true })

      try {
        const data = await fetch.ListOverview({
          type,
          status,
          page,
          size: Number(import.meta.env.VITE_PAGE_SIZE),
        })

        Object.assign(tab, { data })
      } catch (e) {
        return
      } finally {
        Object.assign(tab, { loading: false })
      }
    },
    async report() {
      this.incidentReport = await report.SimpleIncidentReport();
    },
    setActiveTab(group: ENUM_INCIDENT_TYPE, activeTab: ENUM_INCIDENT_STATUS) {
      const g = this.incidentGroup.find(({ key }) => key === group)
      if (g) {
        Object.assign(g, { activeTab })
      }
    }
  },
})
