import { defineStore } from "pinia";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues, groupNewsByContentDate } from "@/configs/helpers/MainHelper";

import { fetchNews } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchNews";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";

const storeName = `useNewsSearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: "",
  },
};
export const useNewsSearchStore = defineStore(storeName, {
  state: () => ({
    fetchNews: new fetchNews(),
    fetchFile: new fetchFile(),
    sharedStore: useSharedStore(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    loadingList: false,
    loadingScroll: false,
    loadingSearch: false,
    isRequired,
    currentPage: 0,
    totalPage: 1,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    tableListResults: null as any,
    tableScrollResults: null as any,
    tableSearchResults: null as any,
    getResult: null as any,
    NewsRole: "",
    listType: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async onUpdateCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;
        this.tableResults = null;

        delete this.result.keyword;

        this.tableResults = await this.keyword({
          ...this.result,
          page: this.currentPage,
        });

        this.tableResults?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);
        this.tableResults.items = await groupNewsByContentDate(this.tableResults?.items);

        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async onUpdateListCurrentPage(currentPage: number, size: number | undefined = undefined) {
      this.loadingScroll = true;
      this.currentPage = currentPage;

      const results = await this.keyword({
        ...this.result,
        page: this.currentPage,
        size,
      });

      if (!results.items.length) this.loadingScroll = false;

      return results;
    },
    async onUpdateSearchCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;
        this.tableSearchResults = null;

        delete this.result.endAt;
        delete this.result.startAt;

        this.tableSearchResults = await this.keyword({
          ...this.result,
          page: this.currentPage,
        });

        this.tableSearchResults?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);

        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableSearchResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    keyword(results: typeGetQuery) {
      const payload = {
        ...results,
        size: results.size || Number(import.meta.env.VITE_PAGE_SIZE),
      };

      return this.fetchNews.List(payload);
    },
  },
});
