<template>
  <div class="px-6 pb-6 pt-20 md:pt-24">
    <form class="space-y-4 lg:space-y-8 dark:text-gray-100">
      <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
        <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
          <h3 class="flex items-center gap-2">
            <router-link :to="{ name: 'AppraisalResult', params: { appraisal: route.params.appraisal } }">
              <ChevronLeftIcon class="flex-none inline-block ttp-icon-inside-box-02" />
            </router-link>
            <span>{{ ssaStore.appraisal?.name }}</span>
          </h3>
        </div>
        <div class="grow p-5 md:flex md:gap-5">
          <div class="space-y-1">
            <label for="organizationCode" class="font-medium">สถานีตำรวจท่องเที่ยว</label>
            <select v-if="!readonly" id="organizationCode" v-model="result.organizationCode" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:focus:border-blue-500">
              <option value="" disabled>กรุณาเลือกสถานีตำรวจท่องเที่ยว</option>
              <option v-for="item in authStore.user?.responsibleAreas" :value="item.code">
                {{ item.content[0].shortName }}
              </option>
            </select>
            <input v-else type="text" :value="authStore.user?.responsibleAreas?.find((i) => i.code === result.organizationCode)?.content?.[0]?.shortName" readonly class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label class="font-medium">ครั้งที่</label>
            <div class="grid grid-cols-[48%_auto_48%]">
              <input type="text" v-model="result.issue" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
              <span class="flex w-full items-center text-center">/</span>
              <input type="text" v-model="result.of" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
            </div>
          </div>
          <div class="space-y-1">
            <label class="font-medium">เจ้าหน้าที่ผู้ปฏิบัติ</label>
            <input readonly :value="authStore.user?.content[0]?.fullName" type="text" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
        </div>
        <div class="grow p-5 md:flex md:gap-5">
          <div class="space-y-1">
            <label class="font-medium">วันเวลาที่ตรวจสอบ</label>
            <input v-model="result.submitAt" :readonly="readonly" type="datetime-local" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label class="font-medium">สถานที่ออกตรวจ</label>
            <input v-model="result.area" :readonly="readonly" type="text" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
        </div>
      </div>

      <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
        <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
          <h3 class="flex items-center gap-2">
            <span>รายละเอียดการตรวจสอบมาตรฐานด้านความปลอดภัยสำหรับการท่องเที่ยว</span>
          </h3>
        </div>
        <div class="min-w-full overflow-x-auto rounded border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
          <table class="min-w-full align-middle text-sm">
            <thead>
              <tr class="border-b border-gray-100 dark:border-gray-700/50">
                <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">&nbsp;</th>
                <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">รายละเอียด</th>
                <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">ผลการตรวจสอบ</th>
              </tr>
            </thead>
            <tbody>
              <FormTableItem v-if="ssaStore.appraisal?.questionnaires?.length" :result="result.questionnaires" :questionnaires="ssaStore.appraisal?.questionnaires" :readonly="readonly" @change="onResultChange" />
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="ssaStore.appraisal?.hasInformant" class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
        <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
          <h3 class="flex items-center gap-2">
            <span>ข้อมูล ผู้นำตรวจ/ให้ข้อมูล (ผู้จัดการแทน, ผู้ดูแลสถานที่)</span>
          </h3>
        </div>
        <div class="p-5 space-y-6">
          <div class="space-y-1">
            <label for="informantName" class="font-medium">ชื่อ-สกุล</label>
            <input type="text" id="informantName" v-model="result.informant.name" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="informantResponsible" class="font-medium">หน้าที่รับผิดชอบ</label>
            <input type="text" id="informantResponsible" v-model="result.informant.responsible" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="informantIdentificationNumber" class="font-medium">หมายเลขบัตรประชาชน/หนังสือเดินทาง</label>
            <div class="flex items-center">
              <select id="select" v-model="result.informant.identificationType" :readonly="readonly" name="select" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:focus:border-blue-500">
                <option :value="ENUM_IDENTIFICATION_TYPE.ID_CARD">หมายเลขบัตรประชาชน</option>
                <option :value="ENUM_IDENTIFICATION_TYPE.PASSPORT">หนังสือเดินทาง</option>
              </select>
              <input type="text" id="informantIdentificationNumber" v-model="result.informant.identificationNumber" :readonly="readonly" class="block w-full grow rounded-r-lg border border-gray-200 px-3 py-2 text-sm leading-5 placeholder-gray-500 focus:z-1 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
            </div>
          </div>
          <div class="space-y-1">
            <label for="informantAddress" class="font-medium">ที่อยู่</label>
            <textarea id="informantAddress" v-model="result.informant.address" :readonly="readonly" rows="4" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500"></textarea>
          </div>
          <div class="space-y-1">
            <label for="informantOfficeContactNumber" class="font-medium">หมายเลขโทรศัพท์สำนักงาน</label>
            <input type="tel" id="informantOfficeContactNumber" v-model="result.informant.officeContactNumber" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="informantContactNumber" class="font-medium">มือถือ</label>
            <input type="tel" id="informantContactNumber" v-model="result.informant.contactNumber" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="informantEmail" class="font-medium">อีเมล</label>
            <input type="email" id="informantEmail" v-model="result.informant.email" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
        </div>
      </div>

      <div v-if="ssaStore.appraisal?.hasBusinessInformation" class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
        <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
          <h3 class="flex items-center gap-2">
            <span>ข้อมูล สถานประกอบกิจการ (ผู้รับใบอนุญาต/ผู้ขอหนังสือรับรองการแจ้ง/ผู้ดูแล/ผู้จัดการ)</span>
          </h3>
        </div>
        <div class="p-5 space-y-6">
          <div class="space-y-1">
            <label for="businessInformationName" class="font-medium">ชื่อ</label>
            <input type="text" id="businessInformationName" v-model="result.businessInformation.name" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="businessInformationAddress" class="font-medium">ตั้งอยู่เลขที่</label>
            <textarea id="businessInformationAddress" v-model="result.businessInformation.address" :readonly="readonly" rows="4" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500"></textarea>
          </div>
          <div class="space-y-1">
            <label for="businessInformationLicenseNumber" class="font-medium">ใบอนุญาตประกอบกิจการนำเที่ยว/ใบอนุญาตตั้งสถานบริการ/ใบอนุญาตจำหน่ายสุรา/ทะเบียนพาณิชย์เลขที่</label>
            <input type="text" id="businessInformationLicenseNumber" v-model="result.businessInformation.licenseNumber" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="businessInformationTotalThaiEmployee" class="font-medium">จำนวนพนักงานไทยในร้าน</label>
            <input type="number" id="businessInformationTotalThaiEmployee" v-model="result.businessInformation.totalThaiEmployee" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div class="space-y-1">
            <label for="businessInformationTotalForeignerEmployee" class="font-medium">จำนวนพนักงานต่างด้าวในร้าน</label>
            <input type="number" id="businessInformationTotalForeignerEmployee" v-model="result.businessInformation.totalForeignerEmployee" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
        </div>
      </div>

      <div v-if="ssaStore.appraisal?.hasBusinessInformation" class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
        <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
          <h3 class="flex items-center gap-2">
            <span>รายชื่อพนักงานต่างด้าวในร้าน</span>
          </h3>
        </div>
        <template v-for="(item, index) in result.businessInformation.foreignerEmployees" :key="index">
          <div class="flex gap-3 bg-gray-50 px-5 py-4 text-center justify-between gap-0 sm:text-left dark:bg-gray-700/50">
            <h3 class="justify-left flex items-center gap-2 font-semibold">
              <span>พนักงานต่างด้าวคนที่ {{ index + 1 }}</span>
            </h3>
            <div v-if="!readonly" class="inline-flex items-center gap-1">
              <button type="button" class="inline-flex items-center justify-center gap-2 rounded-lg border border-rose-700 bg-rose-700 px-4 py-2 font-semibold leading-6 text-white hover:border-rose-600 hover:bg-rose-600 hover:text-white focus:ring focus:ring-rose-400/50 active:border-rose-700 active:bg-rose-700 dark:focus:ring-rose-400/90" @click.prevent="removeForeignerEmployees(index)">
                <span>ลบ</span>
              </button>
            </div>
          </div>
          <div class="p-5 space-y-6 border-b border-gray-300">
            <div class="space-y-1">
              <label :for="`name_${index}`" class="font-medium">ชื่อ</label>
              <input :id="`name_${index}`" v-model="item.name" placeholder="กรุณากรอกชื่อ-สกุล" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
            </div>
            <div class="space-y-1">
              <label :for="`nationality_${index}`" class="font-medium">สัญชาติ</label>
              <input :id="`nationality_${index}`" v-model="item.nationality" placeholder="กรุณากรอกสัญชาติ" :readonly="readonly" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
            </div>
            <div class="space-y-1">
              <label :for="`identificationNumber_${index}`" class="font-medium">เลขที่หนังสือเดินทาง</label>
              <input :id="`identificationNumber_${index}`" v-model="item.identificationNumber" :readonly="readonly" placeholder="กรุณากรอกเลขที่หนังสือเดินทาง" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
            </div>
            <div class="space-y-1">
              <label :for="`identificationExpiryAt_${index}`" class="font-medium">วันสิ้นสุด</label>
              <input :id="`identificationExpiryAt_${index}`" v-model="item.identificationExpiryAt" type="date" :readonly="readonly" placeholder="กรุณากรอกวันสิ้นสุด" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
            </div>
          </div>
        </template>
        <div v-if="!readonly" class="p-5 space-y-6">
          <button class="inline-flex items-center justify-center gap-2 rounded-lg border border-blue-200 bg-blue-100 px-4 py-2 font-semibold leading-6 text-blue-800 hover:border-blue-300 hover:text-blue-900 hover:shadow-sm focus:ring focus:ring-blue-300/25 active:border-blue-200 active:shadow-none dark:border-blue-200 dark:bg-blue-200 dark:hover:border-blue-300 dark:hover:bg-blue-300 dark:focus:ring-blue-500/50 dark:active:border-blue-200 dark:active:bg-blue-200" @click.prevent="addForeignerEmployee">เพิ่มรายชื่อ</button>
        </div>
      </div>

      <div v-if="result.isDraft" class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
        <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
          <h3 class="flex items-center gap-2">
            <span>จัดการแบบฟอร์ม</span>
          </h3>
        </div>
        <div class="p-5 flex gap-4">
          <button :disabled="readonly" class="inline-flex items-center justify-center gap-2 rounded-lg border border-teal-200 bg-teal-100 px-4 py-2 font-semibold leading-6 text-teal-800 hover:border-teal-300 hover:text-teal-900 hover:shadow-sm focus:ring focus:ring-teal-300/25 active:border-teal-200 active:shadow-none dark:border-teal-200 dark:bg-teal-200 dark:hover:border-teal-300 dark:hover:bg-teal-300 dark:focus:ring-teal-500/50 dark:active:border-teal-200 dark:active:bg-teal-200" @click.prevent="() => save(true)">บันทึกแบบร่าง</button>
          <button :disabled="readonly" class="inline-flex items-center justify-center gap-2 rounded-lg border border-teal-700 bg-teal-700 px-4 py-2 font-semibold leading-6 text-white hover:border-teal-600 hover:bg-teal-600 hover:text-white focus:ring focus:ring-teal-400/50 active:border-teal-700 active:bg-teal-700 dark:focus:ring-teal-400/90" @click.prevent="() => save(false)">บันทึก</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useSafetyStandardAppraisalStore, type AppraisalQuestion, type AppraisalResultQuestionnaire, type AppraisalResult, type Employee } from "@/stores/PoliceTouristAndAgent/useSafetyStandardAppraisalStore";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import FormTableItem from "./FormTableItem.vue";
import { ENUM_IDENTIFICATION_TYPE } from "@/configs/enums/enum";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Swal from "sweetalert2";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";

dayjs.extend(utc);
dayjs.extend(timezone);

const router = useRouter();
const route = useRoute();

const authStore = useAuthStore();
const ssaStore = useSafetyStandardAppraisalStore();

const loading = ref<boolean>(false);

const result = ref<AppraisalResult>({
  id: undefined,
  organizationCode: "",
  issue: "",
  of: "",
  submitAt: "",
  area: "",
  suggestion: "",
  isDraft: true,
  questionnaires: [],

  informant: {
    name: "",
    responsible: "",
    identificationType: ENUM_IDENTIFICATION_TYPE.ID_CARD,
    identificationNumber: "",
    address: "",
    officeContactNumber: "",
    contactNumber: "",
    email: "",
  },

  businessInformation: {
    name: "",
    address: "",
    licenseType: "",
    licenseNumber: "",
    totalThaiEmployee: 0,
    totalForeignerEmployee: 0,

    foreignerEmployees: [],
  },
});

const readonly = computed(() => loading.value || !result.value.isDraft);

const questionnairesToResultQuestionnaires = (questionnaires: AppraisalQuestion[], resultQuestionnaires: AppraisalResultQuestionnaire[]) => {
  result.value.questionnaires = questionnaires
    .flatMap(({ children }) => children)
    .map((item) => ({
      answer: resultQuestionnaires.find((q) => q.questionnaire?.id === item.id)?.answer || "",
      remark: resultQuestionnaires.find((q) => q.questionnaire?.id === item.id)?.remark || "",
      questionnaire: item,
    }));
};

const removeForeignerEmployees = (index: number) => {
  result.value.businessInformation.foreignerEmployees.splice(index, 1);
};

const addForeignerEmployee = () => {
  if (!result.value.businessInformation.foreignerEmployees) {
    result.value.businessInformation.foreignerEmployees = [];
  }

  result.value.businessInformation.foreignerEmployees.push(<Employee>{
    name: "",
    nationality: "",
    identificationType: ENUM_IDENTIFICATION_TYPE.PASSPORT,
    identificationNumber: "",
    identificationExpiryAt: "",
  });
};

const onResultChange = ({ id, field, value }: { id: string; field: "answer" | "remark"; value: string }) => {
  const r = result.value.questionnaires.find((r) => r.questionnaire?.id === id);
  if (r) {
    r[field] = value;
  }
};

const save = async (isDraft: boolean) => {
  if (isDraft) {
    Swal.fire({
      title: "กำลังบันทึกแบบร่าง",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  } else {
    const { isConfirmed } = await Swal.fire({
      title: "ต้องการบันทึกข้อมูล?",
      text: "หากทำการบันทึกข้อมูลแล้ว จะไม่สามารถเปลี่ยนแปลงได้",
      confirmButtonText: "ยืนยันการบันทึกข้อมูล",
      cancelButtonText: "ยกเลิกการบันทึกข้อมูล",
      showCancelButton: true,
    });

    if (!isConfirmed) {
      return;
    }
  }

  result.value.isDraft = isDraft;
  if (result.value.submitAt?.length) {
    const submitAt = dayjs.tz(result.value.submitAt, "Asia/Bangkok");
    result.value.submitAt = submitAt.isValid() ? submitAt.toISOString() : "";
  }

  if (result.value.businessInformation?.foreignerEmployees?.length) {
    result.value.businessInformation.foreignerEmployees = result.value.businessInformation.foreignerEmployees.map((e) => {
      const identificationExpiryAt = dayjs.tz(e.identificationExpiryAt, "Asia/Bangkok");
      e.identificationExpiryAt = identificationExpiryAt.isValid() ? identificationExpiryAt.toISOString() : "";
      return e;
    });
  }

  try {
    const updatedResult = await ssaStore.saveResult(route.params.appraisal as string, result.value);
    renderResult(updatedResult);

    if (route.name !== "AppraisalResultDetail") {
      router.push({
        name: "AppraisalResultDetail",
        params: {
          apprialsal: route.params.appraisal,
          result: updatedResult.id,
        },
      });
    }

    Swal.fire({
      title: "บันทึกข้อมูลสำเสร็จ",
      icon: "success",
      showConfirmButton: true,
      confirmButtonText: "ปิด",
    });
  } catch (e) {
    Swal.fire({
      title: "ไม่สามารถบันทึกข้อมูลได้",
      text: "กรุณาลองใหม่อีกครั้ง",
      icon: "error",
      showConfirmButton: true,
      confirmButtonText: "ปิด",
    });
  }
};

const renderResult = (rawResult?: AppraisalResult) => {
  if (rawResult) {
    Object.assign(result.value, {
      id: rawResult.id,
      organizationCode: rawResult.organizationCode,
      issue: rawResult.issue,
      of: rawResult.of,
      submitAt: dayjs(rawResult.submitAt).format("YYYY-MM-DDThh:mm:ss"),
      area: rawResult.area,
      suggestion: rawResult.suggestion,
      isDraft: rawResult.isDraft,
      questionnaires: [],
      informant: rawResult.informant,
      businessInformation: {
        ...rawResult.businessInformation,
        foreignerEmployees:
          rawResult.businessInformation?.foreignerEmployees.map((e) => ({
            ...e,
            identificationExpiryAt: e.identificationExpiryAt?.length ? dayjs(e.identificationExpiryAt).format("YYYY-MM-DD") : "",
          })) || [],
      },
    });
  }

  if (ssaStore.appraisal?.questionnaires?.length) {
    questionnairesToResultQuestionnaires(ssaStore.appraisal?.questionnaires || [], rawResult?.questionnaires || []);
  }

  if (ssaStore.appraisal?.hasBusinessInformation && !result.value.businessInformation.foreignerEmployees?.length) {
    addForeignerEmployee();
    addForeignerEmployee();
    addForeignerEmployee();
  }
};

onMounted(async () => {
  loading.value = true;
  try {
    await ssaStore.findAppraisal((route.params?.appraisal || "") as string);
  } catch (e) {
    Swal.fire({
      title: "ไม่พบข้อมูล",
      text: "กรุณาปิด เพื่อย้อนกลับ",
      icon: "error",
      showConfirmButton: true,
      confirmButtonText: "ปิด",
    }).then(() => {
      router.push({
        name: "AppraisalList",
      });
    });
  } finally {
    loading.value = false;
  }

  let rawResult: AppraisalResult | undefined = undefined;

  if (route.params?.result?.length) {
    loading.value = true;
    try {
      rawResult = await ssaStore.findResult((route.params?.appraisal || "") as string, (route.params?.result || "") as string);
    } catch (e) {
      Swal.fire({
        title: "ไม่พบข้อมูล",
        text: "กรุณาปิด เพื่อย้อนกลับ",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "ปิด",
      }).then(() => {
        router.push({
          name: "AppraisalDetail",
          params: {
            appraisal: route.params.appraisal,
          },
        });
      });
    } finally {
      loading.value = false;
    }
  }

  renderResult(rawResult);
});
</script>
