<script lang="ts">
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ResetPasswordView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return false;
    },
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">Reset Password</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-green-500 font-bold ttp-text-2xl text-center my-4">{{ $t("page.ResetPasswordView.title") }}</h1>
                <p class="text-black text-center">{{ $t("page.ResetPasswordView.detail") }}</p>
                <div class="ttp-between mt-4">
                  <div class="flex gap-2 w-full">
                    <RouterLink :to="{ name: 'HomeView' }" class="w-full m-auto">
                      <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" />
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
