<script lang="ts">
// @ts-nocheck
import { defineComponent, reactive } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import { usePopularPlaceSearchStore } from "./stores/usePopularPlaceSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import dayjs from "dayjs";
import type { $string } from "@/configs/types/Shared/typeShare";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import { useInfiniteScroll } from "@vueuse/core";
import _ from "lodash";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { Capacitor } from "@capacitor/core";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { Geolocation } from "@capacitor/geolocation";
import { useActivitySearchStore } from "./stores/useActivitySearchStore";
import AppHeader from "../AppHeader.vue";
import { usePermissionStore } from "@/stores/PoliceTouristAndAgent/usePermissionStore";

export default defineComponent({
  name: "PopularPlaceView",
  components: { TabGroup, TabList, Tab, TabPanels, TabPanel, LoadingFormComponent, Pagination, Navigation, Swiper, SwiperSlide, AppHeader },
  data() {
    return {
      componentName: this.$options.name,
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href,
      profile_image: "" as any,
      isVisibleSearch: false,
      coordinations: null,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                htmlIcon: "",
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.popularPlaceSearchStore.error.keyword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                classIcon: "ttp-icon-inside-box-03 text-[#2C3483]",
                componentIcon: this.$solidIcons.MapPinIcon,
                isDisable: this.loading(),
                isRequired: this.popularPlaceSearchStore.isRequired.keyword.is,
                textError: this.$t(this.popularPlaceSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.popularPlaceSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.popularPlaceSearchStore.isRequired.keyword.placeholder),
                value: this.popularPlaceSearchStore.result.keyword,
                name: this.popularPlaceSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: async (value: $string) => {
                this.popularPlaceSearchStore.result.keyword = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async popularPlaceFetch() {
      if (!this.popularPlaceSearchStore.loadingList) {
        await this.popularPlaceSearchStore.onUpdateCurrentPage();
      }
    },
    async popularPlaceListFetch() {
      if (this.popularPlaceSearchStore.loadingScroll) {
        return;
      }

      this.popularPlaceSearchStore.currentPage = this.popularPlaceSearchStore.currentPage + 1;

      const ids = this.popularPlaceSearchStore?.tableResults?.items?.map((item: any) => item.id) || [];
      let results = (await this.popularPlaceSearchStore.onUpdateListCurrentPage(this.popularPlaceSearchStore.currentPage, this.coordinations?.latitude, this.coordinations?.longitude)) as any;
      results.items = results?.items?.filter((item: any) => !ids.includes(item.id));

      if (!this.popularPlaceSearchStore.tableScrollResults) {
        this.popularPlaceSearchStore.tableListResults = results;
        this.popularPlaceSearchStore.tableScrollResults = _.cloneDeep(results);
        this.popularPlaceSearchStore.totalPage = results?.totalPages;
      } else {
        this.popularPlaceSearchStore.tableListResults.items.push(...results?.items);
        this.popularPlaceSearchStore.tableListResults.totalPage = this.popularPlaceSearchStore.tableListResults.totalPages;
        results = this.popularPlaceSearchStore.tableListResults;
      }

      await results?.items.reduce(async (_acc: any, item: any) => {
        if (item.thumbnail) {
          item.thumbnail = await Promise.all(await this.popularPlaceSearchStore?.fetchFile.Get([item.thumbnail]));
        }
        return item;
      }, []);

      this.popularPlaceSearchStore.tableScrollResults.items = results.items;
      this.popularPlaceSearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
      this.popularPlaceSearchStore.loadingScroll = false;
    },
    async activityListFetch() {
      if (!this.activitySearchStore.loadingScroll) {
        this.activitySearchStore.currentPage = this.activitySearchStore.currentPage + 1;

        let results = (await this.activitySearchStore.onUpdateListCurrentPage(this.activitySearchStore.currentPage, this.coordinations?.latitude, this.coordinations?.longitude)) as any;

        if (!this.activitySearchStore.tableScrollResults) {
          this.activitySearchStore.tableListResults = results;
          this.activitySearchStore.tableScrollResults = _.cloneDeep(results);
          this.activitySearchStore.totalPage = results?.totalPages;
        } else {
          this.activitySearchStore.tableListResults.items.push(...results?.items);
          this.activitySearchStore.tableListResults.totalPage = this.activitySearchStore.tableListResults.totalPages;
          results = this.activitySearchStore.tableListResults;
        }

        await results?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.activitySearchStore?.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);

        this.activitySearchStore.tableScrollResults.items = results.items;
        this.activitySearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
        this.activitySearchStore.loadingScroll = false;
      }
    },
    async popularPlaceSearchFetch() {
      if (!this.popularPlaceSearchStore.loadingSearch) {
        this.popularPlaceSearchStore.onUpdateSearchCurrentPage();
      }
    },
    isOpen(openingHours: []) {
      return this.$isOpenNow(openingHours) ? "Open" : "Close";
    },
    async popularPlaceScrollFetch() {
      useInfiniteScroll(
        this.el,
        () => {
          if (this.popularPlaceSearchStore.totalPage > this.popularPlaceSearchStore.currentPage) {
            this.popularPlaceListFetch();
          }
        },
        { distance: 10 }
      );
    },
    async activityScrollFetch() {
      useInfiniteScroll(
        this.el,
        () => {
          if (this.activitySearchStore.totalPage > this.activitySearchStore.currentPage) this.activityListFetch();
        },
        { distance: 10 }
      );
    },
    loading() {
      return this.popularPlaceSearchStore.loadingSearch;
    },
    async reset() {
      await this.popularPlaceSearchStore.$reset();
    },
    async setting() {
      await this.$configLightbox();

      await Promise.all([this.popularPlaceFetch(), this.popularPlaceListFetch(), this.activityListFetch()]);

      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }
    },
  },
  async mounted() {
    if (!this.permissionStore.hasLocationPermission) {
      await this.permissionStore.requestGeolocationPermission();
    }

    if (this.permissionStore.hasLocationPermission) {
      this.coordinations = await this.permissionStore.getGeolocation();
    }

    await this.reset();
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const popularPlaceSearchStore = usePopularPlaceSearchStore();
    const activitySearchStore = useActivitySearchStore();
    const sharedStore = useSharedStore();
    const permissionStore = usePermissionStore();
    dayjs.locale(masterDataStore.lang());

    var state = reactive({
      el: null,
    });

    return {
      sharedStore,
      authStore,
      masterDataStore,
      popularPlaceSearchStore,
      activitySearchStore,
      modules: [Pagination, Navigation],
      el: state.el,
      state,
      permissionStore,
    };
  },
});
</script>

<template>
  <div
    v-if="isVisibleSearch"
    @click="
      () => {
        isVisibleSearch = !isVisibleSearch;
      }
    "
    class="absolute top-0 left-0 bg-black flex w-full h-full z-[99999] opacity-50"
  ></div>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <AppHeader :back="{ name: 'HomeView' }">
            <div v-if="isVisibleSearch" class="relative w-full text-white text-right z-[99999]">
              <form @submit.prevent="popularPlaceSearchFetch">
                <component :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" classTagName="w-full text-left" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
                <div v-if="popularPlaceSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full h-60 rounded-lg p-3 z-[99999] overflow-scroll">
                  <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: data?.id } }" v-for="(data, key) in popularPlaceSearchStore?.tableSearchResults?.items" :key="key" class="flex gap-2 mb-2 w-full text-black">
                    <div class="inline-flex w-10 h-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 overflow-hidden">
                      <img v-if="data.images" :src="data?.images[0]" loading="lazy" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                      <img v-else="image_url" :src="image_url" loading="lazy" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                    </div>
                    <div class="flex flex-col w-full">
                      <div class="ttp-text-md text-black text-left font-bold">{{ $convertString(data?.name) }}</div>
                      <time class="ttp-text-xs text-black text-left">{{ $dayjs(new Date(data?.refCreatedAt)).format("DD MMMM YYYY") }}</time>
                    </div>
                  </router-link>
                </div>
                <div v-else-if="loading()" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                  <div class="m-auto text-center"><LoadingFormComponent class="text-black" /></div>
                  <div class="ttp-text-xs text-black text-center">{{ $t("layout.loading") }}</div>
                </div>
                <div v-else-if="popularPlaceSearchStore?.tableSearchResults != null && !popularPlaceSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                  <div class="ttp-text-xs text-black text-center">{{ $t("layout.not_found") }}</div>
                </div>
              </form>
            </div>
            <div v-if="!isVisibleSearch" class="w-full"></div>
            <button
              @click="
                () => {
                  if (!isVisibleSearch) isVisibleSearch = !isVisibleSearch;
                  else popularPlaceSearchFetch();
                }
              "
              class="w-10 text-white text-right text-right z-[99999]"
            >
              <div>
                <component :is="$solidIcons.MagnifyingGlassIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
              </div>
            </button>
          </AppHeader>

          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto">
            <div class="grid grid-cols-1">
              <form @submit.prevent="popularPlaceSearchFetch">
                <component
                  :is="defaultFormComponents.form.input.keyword.component"
                  v-bind="defaultFormComponents.form.input.keyword.props"
                  classTagName="w-full text-left"
                  @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue"
                  @click="
                    () => {
                      isVisibleSearch = !isVisibleSearch;
                    }
                  "
                />
              </form>
            </div>
            <div class="swiper-container w-full">
              <div v-if="popularPlaceSearchStore.loadingList" class="flex flex-col justify-center items-center w-full">
                <div><LoadingFormComponent /></div>
                <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
              </div>
              <Swiper v-else :slidesPerView="2.75" :loop="true" :spaceBetween="5" :modules="modules" class="swiper-wrapper">
                <SwiperSlide v-for="(result, number) in popularPlaceSearchStore?.tableResults?.items" :key="number" class="swiper-slide">
                  <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: result?.id } }" class="relative flex gap-2 h-36 rounded-[21px] overflow-hidden">
                    <div class="relative w-full">
                      <div class="absolute w-full h-36 inset-0">
                        <img v-if="result.images" :src="result?.images[0]" loading="lazy" alt="รูปภาพ" class="object-cover w-full h-full" />
                      </div>
                    </div>
                    <div class="absolute w-full text-white bottom-0 p-4 ttp-text-md leading-none font-bold">{{ $convertString(result?.name) }}</div>
                  </router-link>
                </SwiperSlide>
              </Swiper>
            </div>

            <TabGroup>
              <TabList class="flex items-center border-b border-gray-200/75 ttp-text-sm bg-white">
                <Tab as="template" v-slot="{ selected }">
                  <button
                    class="-mb-px flex grow items-center justify-center space-x-2 border-b-4 py-3 font-medium"
                    :class="{
                      'ttp-text-sm font-bold border-ttp-primary text-ttp-primary dark:border-ttp-primary dark:text-ttp-primary': selected,
                      'ttp-text-sm font-bold border-transparent text-gray-600 hover:border-gray-200 hover:text-ttp-primary active:text-ttp-primary dark:text-ttp-primary dark:hover:border-gray-600 dark:hover:text-ttp-primary': !selected,
                    }"
                  >
                    Attraction
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    class="-mb-px flex grow items-center justify-center space-x-2 border-b-4 py-3 font-medium"
                    :class="{
                      'ttp-text-sm font-bold border-ttp-primary text-ttp-primary dark:border-ttp-primary dark:text-ttp-primary': selected,
                      'ttp-text-sm font-bold border-transparent text-gray-600 hover:border-gray-200 hover:text-ttp-primary active:text-ttp-primary dark:text-ttp-primary dark:hover:border-gray-600 dark:hover:text-ttp-primary': !selected,
                    }"
                  >
                    Activity
                  </button>
                </Tab>
              </TabList>

              <TabPanels ref="el" class="flex w-full h-full">
                <TabPanel class="flex flex-col w-full h-full items-center mx-0">
                  <div v-if="popularPlaceSearchStore?.tableScrollResults?.items?.length" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4 pb-28">
                    <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: result?.id } }" v-for="(result, number) in popularPlaceSearchStore?.tableScrollResults?.items" :key="number" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                      <div class="relative w-full overflow-hidden">
                        <div class="absolute w-full h-36 inset-0">
                          <img v-if="result.images" :src="result?.images[0]" loading="lazy" alt="รูปภาพ" class="object-cover w-full h-full" />
                        </div>
                      </div>
                      <div class="flex flex-col w-full justify-between py-2">
                        <h2 class="ttp-text-lg font-bold text-[#2C3483]">{{ $convertString(result.name) }}</h2>
                        <div class="flex gap-2 grow -items-center -justify-center">
                          <span><component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto text-black ttp-icon-inside-box-03 cursor-pointer" /></span>
                          <span class="ttp-text-sm">{{ $convertString(result?.address) }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div v-else-if="!popularPlaceSearchStore?.tableListResults?.items?.length && popularPlaceSearchStore.currentPage == 1 && !popularPlaceSearchStore.loadingScroll" class="flex flex-col h-full justify-center items-center">
                    <div class="text-center">
                      <component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                      <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.PopularPlaceView.not_found") }}</div>
                    </div>
                  </div>
                  <div v-if="popularPlaceSearchStore.loadingScroll" class="flex flex-col justify-center items-center w-full">
                    <div><LoadingFormComponent /></div>
                    <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
                  </div>
                </TabPanel>
                <TabPanel class="flex flex-col w-full h-full items-center mx-0">
                  <div v-if="activitySearchStore?.tableScrollResults?.items?.length" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4 pb-28">
                    <router-link :to="{ name: 'ActivityDetailView', params: { id: result?.id } }" v-for="(result, number) in activitySearchStore?.tableScrollResults?.items" :key="number" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                      <div class="relative w-full overflow-hidden">
                        <div class="absolute w-full h-36 inset-0">
                          <img v-if="result.images" :src="result?.images[0]" loading="lazy" alt="รูปภาพ" class="object-cover w-full h-full" />
                        </div>
                      </div>
                      <div class="flex flex-col w-full justify-between py-2">
                        <h2 class="ttp-text-lg font-bold text-[#2C3483]">{{ $convertString(result.name) }}</h2>
                        <div class="flex gap-2 grow -items-center -justify-center">
                          <span><component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto text-black ttp-icon-inside-box-03 cursor-pointer" /></span>
                          <span class="ttp-text-sm">{{ $convertString(result?.address) }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div v-else-if="!activitySearchStore?.tableListResults?.items?.length && activitySearchStore.currentPage == 1 && !activitySearchStore.loadingScroll" class="flex flex-col h-full justify-center items-center">
                    <div class="text-center">
                      <component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                      <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.PopularPlaceView.not_found") }}</div>
                    </div>
                  </div>
                  <div v-if="activitySearchStore.loadingScroll" class="flex flex-col justify-center items-center w-full">
                    <div><LoadingFormComponent /></div>
                    <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
