<template>
  <div class="px-6 pb-6 pt-20 md:pt-24">
    <div class="rounded border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
      <table class="align-middle text-sm w-full">
        <thead>
          <tr class="border-b border-gray-100 dark:border-gray-700/50">
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">แบบประเมิน</th>
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in ssaStore.appraisals" :key="item.id" class="border-b border-gray-100 dark:border-gray-700/50">
            <td class="p-3">{{ item.name }}</td>
            <td class="p-3 text-center">
              <router-link
                :to="{ name: 'AppraisalResult', params: { appraisal: item.id } }"
                class="inline-flex items-center justify-center gap-2 rounded-lg border border-blue-200 bg-blue-100 px-2 py-1 text-sm font-semibold leading-5 text-blue-800 hover:border-blue-300 hover:text-blue-900 hover:shadow-sm focus:ring focus:ring-blue-300/25 active:border-blue-200 active:shadow-none dark:border-blue-200 dark:bg-blue-200 dark:hover:border-blue-300 dark:hover:bg-blue-300 dark:focus:ring-blue-500/50 dark:active:border-blue-200 dark:active:bg-blue-200 mb-4 mr-4"
              >
                รายการประเมิน
              </router-link>
              <router-link
                :to="{ name: 'AppraisalForm', params: { appraisal: item.id } }"
                class="inline-flex items-center justify-center gap-2 rounded-lg border border-blue-700 bg-blue-700 px-2 py-1 text-sm font-semibold leading-5 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90 mb-4 mr-4"
              >
                ทำการประเมิน
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSafetyStandardAppraisalStore } from "@/stores/PoliceTouristAndAgent/useSafetyStandardAppraisalStore";
import { onMounted } from "vue";

const ssaStore = useSafetyStandardAppraisalStore();

onMounted(async () => {
  ssaStore.getAppraisals();
});
</script>
