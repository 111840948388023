import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME, type ENUM_IDENTIFICATION_TYPE } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { Paginate } from "@/configs/types/Shared/typePagination";
import { defineStore } from "pinia";

export type Appraisal = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  name: string;
  hasInformant: boolean;
  hasBusinessInformation: boolean;

  questionnaires: AppraisalQuestion[];
  results: AppraisalResult[];
}

export type AppraisalQuestion = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  question: string;
  children: AppraisalQuestion[];
}

export type AppraisalResult = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  issue: string;
  of: string;
  organizationCode: string;
  submitAt: string;
  area: string;
  suggestion: string;
  isDraft: boolean;

  questionnaires: AppraisalResultQuestionnaire[];
  informant: Informant
  businessInformation: BusinessInformation
}

export type AppraisalResultQuestionnaire = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  answer: string;
  remark: string;
  questionnaire?: AppraisalQuestion;
}

export type Informant = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  name: string;
  responsible: string;
  identificationType: ENUM_IDENTIFICATION_TYPE;
  identificationNumber: string;
  address: string;
  officeContactNumber: string;
  contactNumber: string;
  email: string;
}

export type BusinessInformation = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  name: string;
  address: string;
  licenseType: string;
  licenseNumber: string;
  totalThaiEmployee: number;
  totalForeignerEmployee: number;

  foreignerEmployees: Employee[]
}

export type Employee = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;

  name: string;
  nationality: string;
  identificationType: ENUM_IDENTIFICATION_TYPE;
  identificationNumber: string;
  identificationExpiryAt: string;
}

type State = {
  appraisals: Appraisal[]
  result: Paginate<Partial<AppraisalResult>>
  appraisal?: Appraisal,
}

export const useSafetyStandardAppraisalStore = defineStore('useSafetyStandardAppraisalStore', {
  state: (): State => ({
    appraisals: [],
    result: {
      items: [],
      page: 1,
      size: 20,
      totalPages: 1,
      total: 0,
    },
    appraisal: undefined
  }),
  actions: {
    async getAppraisals() {
      const { data } = await axiosClient(
        { isFile: false },
        ENUM_APP_NAME.TOURIST_POLICE,
        ENUM_APP_CATEGORY_NAME.AGENT,
      ).get<State['appraisals']>("/v1/safety-standard-appraisals");

      this.appraisals = data
    },
    async findAppraisal(id: string) {
      const { data } = await axiosClient(
        { isFile: false },
        ENUM_APP_NAME.TOURIST_POLICE,
        ENUM_APP_CATEGORY_NAME.AGENT,
      ).get<Appraisal>(`/v1/safety-standard-appraisals/${id}`);

      this.appraisal = data
    },
    async getAppraisalResults(id: string, page: number = 1) {
      const { data } = await axiosClient(
        { isFile: false },
        ENUM_APP_NAME.TOURIST_POLICE,
        ENUM_APP_CATEGORY_NAME.AGENT,
      ).get<State['result']>(`/v1/safety-standard-appraisals/${id}/results`, {
        params: { page }
      });

      this.result = data
    },
    async findResult(id: string, resultId: string) {
      const { data } = await axiosClient(
        { isFile: false },
        ENUM_APP_NAME.TOURIST_POLICE,
        ENUM_APP_CATEGORY_NAME.AGENT,
      ).get<AppraisalResult>(`/v1/safety-standard-appraisals/${id}/results/${resultId}`);

      return data
    },
    async saveResult(id: string, result: AppraisalResult): Promise<AppraisalResult> {
      if (result.id) {
        const { data } = await axiosClient(
          { isFile: false },
          ENUM_APP_NAME.TOURIST_POLICE,
          ENUM_APP_CATEGORY_NAME.AGENT,
        ).put<AppraisalResult>(`/v1/safety-standard-appraisals/${id}/results/${result.id}`, result);

        return data
      }

      const { data } = await axiosClient(
        { isFile: false },
        ENUM_APP_NAME.TOURIST_POLICE,
        ENUM_APP_CATEGORY_NAME.AGENT,
      ).post<AppraisalResult>(`/v1/safety-standard-appraisals/${id}/results`, result);

      return data
    }
  }
})
