<script lang="ts">
import { defineComponent } from "vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useAuthStore } from "./stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { IonNavLink } from "@ionic/vue";
import { useForgotPasswordStore } from "./stores/useForgotPasswordStore";

export default defineComponent({
  name: "ForgotPasswordView",
  components: {
    IonNavLink,
  },
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            email: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.forgotPasswordStore.error.email || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                isDisable: this.loading(),
                componentIcon: this.$solidIcons.EnvelopeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isRequired: this.forgotPasswordStore.isRequired.email.is,
                textError: this.$t(this.forgotPasswordStore.isRequired.email.placeholder),
                title: this.$t(this.forgotPasswordStore.isRequired.email.label),
                placeholder: this.$t(this.forgotPasswordStore.isRequired.email.placeholder),
                value: this.forgotPasswordStore.result.email,
                name: this.forgotPasswordStore.isRequired.email.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: string) => {
                this.forgotPasswordStore.result.email = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_submit"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async () => {
                const result = await this.forgotPasswordStore.onSubmit();
                if (result?.ref) {
                  this.forgotPasswordStore.result.ref = result?.ref;
                  this.$router.push({ name: "ForgotPasswordOTPView" });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.forgotPasswordStore.loading;
    },
    async fetch() {
      await Promise.all([
        this.masterDataStore.listTitle(),
        this.masterDataStore.listLocale(),
        this.masterDataStore.listRole(),
        this.masterDataStore.listNationality(),
        this.masterDataStore.listIncidentCategory(),
        this.masterDataStore.listProvince(),
        this.masterDataStore.listCountry(),
      ]);
    },
    async facebookLoginHandler() {
      await this.authStore.loginWithThirdParty("FACEBOOK");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
    async googleLoginHandler() {
      await this.authStore.loginWithThirdParty("GOOGLE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
    async appleLoginHandler() {
      await this.authStore.loginWithThirdParty("APPLE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
    async phoneLoginHandler() {
      await this.authStore.loginWithThirdParty("PHONE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
  },
  setup() {
    const forgotPasswordStore = useForgotPasswordStore();
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    return {
      forgotPasswordStore,
      authStore,
      masterDataStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.push({ name: 'LoginView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">Forgot Password</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl text-center">{{ $t("page.ForgotPasswordView.desc") }}</h1>
                <p class="text-black text-center">{{ $t("page.ForgotPasswordView.email") }}</p>
                <di>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.email.props" @updateValue="defaultFormComponents.form.input.email.onUpdateValue" />
                  </div>
                  <div class="w-full mb-2">
                    <div class="flex gap-2 w-full">
                      <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" class="m-auto" />
                    </div>
                  </div>
                </di>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
