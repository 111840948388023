<template>
  <template v-for="(item, index) in questionnaires" :key="item.id" class="border-b border-gray-100 dark:border-gray-700/50">
    <tr class="dark:border-gray-700/50 align-top" :class="{ ['border-b border-gray-100']: item.children }">
      <td class="p-3 pr-0 text-left" :class="{ ['bg-gray-100/75 px-3 py-4 font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50']: item.children?.length }">
        <template v-if="section">{{ section }}.</template>{{ index + 1 }}.
      </td>
      <td :colspan="item.children?.length ? 2 : 1" class="p-3 pl-0 text-left max-w-60" :class="{ ['bg-gray-100/75 px-3 py-4 font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50']: item.children?.length }">
        {{ item.question }}
      </td>
      <template v-if="!item.children?.length">
        <td class="p-3">
          <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <template v-if="!readonly">
              <label class="group relative flex">
                <input type="radio" :readonly="readonly" class="peer absolute left-0 top-0 appearance-none opacity-0" :id="`answer_${item.id}_yes`" :name="`answer_${item.id}`" :checked="result.find((i) => i.questionnaire?.id === item.id)?.answer === 'YES'" value="YES" @change="onChange(item.id as string, 'answer', (<HTMLTextAreaElement>$event?.target)?.value)" />
                <span class="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:border-blue-600 dark:peer-checked:bg-blue-800/10 dark:peer-focus:ring-blue-900"> มี / ปฏิบัติ </span>
              </label>

              <label class="group relative flex">
                <input type="radio" :readonly="readonly" class="peer absolute left-0 top-0 appearance-none opacity-0" :id="`answer_${item.id}_no`" :name="`answer_${item.id}`" :checked="result.find((i) => i.questionnaire?.id === item.id)?.answer === 'NO'" value="NO" @change="onChange(item.id as string, 'answer', (<HTMLTextAreaElement>$event?.target)?.value)" />
                <span class="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:border-blue-600 dark:peer-checked:bg-blue-800/10 dark:peer-focus:ring-blue-900">ไม่มี /ไม่ปฏิบัติ</span>
              </label>
            </template>

            <span v-else class="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:border-blue-600 dark:peer-checked:bg-blue-800/10 dark:peer-focus:ring-blue-900">
              <template v-if="result.find((i) => i.questionnaire?.id === item.id)?.answer === 'YES'">มี / ปฏิบัติ</template>
              <template v-else-if="result.find((i) => i.questionnaire?.id === item.id)?.answer === 'NO'">ไม่มี /ไม่ปฏิบัติ</template>
            </span>
          </div>
        </td>
      </template>
    </tr>
    <tr v-if="!item.children?.length" class="border-b border-gray-300 dark:border-gray-700/50">
      <td class="p-3" colspan="3">
        <input :id="`remark_${item.id}`" :name="`remark_${item.id}`" :value="result.find((i) => i.questionnaire?.id === item.id)?.remark || ''" :readonly="readonly" placeholder="หมายเหตุ" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" @change="onChange(item.id as string, 'remark', (<HTMLTextAreaElement>$event?.target)?.value)" />
      </td>
    </tr>
    <FormTableItem v-if="item.children?.length" :result="result" :questionnaires="item.children" :section="index + 1" :readonly="readonly" @change="(v) => $emit('change', v)" />
  </template>
</template>

<script setup lang="ts">
import type { AppraisalQuestion, AppraisalResultQuestionnaire } from "@/stores/PoliceTouristAndAgent/useSafetyStandardAppraisalStore";

const { questionnaires, result } = defineProps<{
  result: AppraisalResultQuestionnaire[];
  questionnaires: AppraisalQuestion[];
  readonly?: boolean;
  section?: string | number;
}>();

const emit = defineEmits(["change"]);

const onChange = (id: string, field: "answer" | "remark", value: string): void => {
  emit("change", { id, field, value });
};
</script>
