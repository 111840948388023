<script lang="ts">
// @ts-nocheck
import { computed, defineComponent } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useHistorySearchStore } from "./stores/useHistoryDetailSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useChatFormStore } from "../Chat/stores/useChatFormStore";
import useClipboard from "vue-clipboard3";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import { Capacitor } from "@capacitor/core";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { PushNotifications } from "@capacitor/push-notifications";
import { getLocalStorage } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "HistoryDetailPoliceTouristAndAgentView",
  components: { TabGroup, TabList, Tab, TabPanels, TabPanel },
  data() {
    return {
      componentName: this.$options.name,
      endDateTime: new Date(),
      timeLeft: "",
      intervalId: "" as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          popup: {
            success: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.historySearchStore.isCopyPopup,
                textTitle: this.$t("popup.success.copy_title"),
                textDetail: this.$t("popup.success.confirm_detail"),
                functionProp: () => {
                  this.historySearchStore.isCopyPopup = false;
                },
                functionCancelProp: async () => {
                  this.historySearchStore.isCopyPopup = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            reject: {
              component: PopupCancelComponent,
              props: {
                isOpen: this.historySearchStore.isReject,
                textTitle: this.$t("popup.reject.reject_title"),
                textDetail: this.$t("popup.reject.reject_detail"),
                functionProp: async () => {
                  this.historySearchStore.isReject = false;
                  await this.chatFormStore.fetchIncident.Delete(String(this.$route.params.id));
                  await this.setting();
                },
                functionCancelProp: async () => {
                  this.historySearchStore.isReject = false;
                },
              } as typeof PopupCancelComponent.props,
            },
          },
        },
      };
    },
    officers() {
      if (!this.historySearchStore?.result?.assignee && !this.historySearchStore?.result?.responsible) return [];
      else return [this.historySearchStore?.result?.assignee, this.historySearchStore?.result?.responsible];
    },
  },
  methods: {
    async clickCopy(text: string) {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(text);
        this.historySearchStore.isCopyPopup = !this.historySearchStore.isCopyPopup;
      } catch (e) {
        console.error(e);
      }
    },
    async initializeMap() {
      this.historySearchStore.isMapGenerate = true;
      const google = await this.$asyncGoogleMapsLoader();
      const { Map } = (await google.maps.importLibrary("maps")) as any;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as any;

      var thailandCoordinates = { lat: 13.736717, lng: 100.523186 };

      const map = new Map(document.getElementById("map") as HTMLElement, {
        center: this.$route.params.id
          ? {
              lat: Number(this.historySearchStore.result.information.latitude),
              lng: Number(this.historySearchStore.result.information.longitude),
            }
          : thailandCoordinates,
        zoom: 14,
        mapId: "4504f8b37365c3d0",
        mapTypeId: "roadmap",
      });

      const draggableMarker = new AdvancedMarkerElement({
        map,
        position: this.$route.params.id
          ? {
              lat: Number(this.historySearchStore.result.information.latitude),
              lng: Number(this.historySearchStore.result.information.longitude),
            }
          : thailandCoordinates,
        gmpDraggable: true,
        title: "This marker is draggable.",
      });
      draggableMarker.addListener("dragend", () => {
        const position = draggableMarker.position as any;
        this.historySearchStore.result.information.latitude = position.lat;
        this.historySearchStore.result.information.longitude = position.lng;
      });

      if (!this.$route.params.id && navigator.geolocation) {
        const position = await Geolocation.getCurrentPosition();
        var selfCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.historySearchStore.result.information.latitude = position.coords.latitude;
        this.historySearchStore.result.information.longitude = position.coords.longitude;

        map.setCenter(selfCoordinates);
        draggableMarker.position = selfCoordinates;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: selfCoordinates },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("name") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.historySearchStore.result.information.location = result.results[0].formatted_address;
      }
    },
    async fetch() {
      if (this.$route.params.id) {
        const result = await this.historySearchStore.fetchIncident.Get(this.$route.params.id as string);
        this.historySearchStore.result = result;
        // Get Image
        if (this?.historySearchStore?.result?.images?.length) {
          this.historySearchStore.result.images = await Promise.all(await this.chatFormStore.fetchFile.Get(this?.historySearchStore?.result?.images));
        }
        // Get Officer Image
        if (this.historySearchStore?.result?.assignee?.image) {
          this.historySearchStore.result.assignee.image = await Promise.all(await this.chatFormStore.fetchFile.Get([this.historySearchStore?.result?.assignee.image]));
        }
        if (this.historySearchStore?.result?.responsible?.image) {
          this.historySearchStore.result.responsible.image = await Promise.all(await this.chatFormStore.fetchFile.Get([this.historySearchStore?.result?.responsible.image]));
        }
        // Latitude & Longitude Not Found
        if (!this.historySearchStore.result.information.latitude) {
          this.historySearchStore.result.information.latitude = "13.736717";
          this.historySearchStore.result.information.longitude = "100.523186";
        }

        //  Check Process
        this.historySearchStore.metadata = this.$extractMetadata(this.historySearchStore?.result?.progress);
        if (this.historySearchStore.metadata) {
          this.historySearchStore.calculate = await this.$calculateTimeColor(new Date().toISOString(), this.historySearchStore?.metadata?.arrived, this.historySearchStore?.metadata?.createdAt, this.$calculateMinutesDifference(this.historySearchStore?.metadata?.createdAt, this.historySearchStore?.metadata?.eta));
        }
      }

      this.historySearchStore.loading = false;
    },
    loading() {
      return this.historySearchStore.loading;
    },
    async reset() {
      await this.historySearchStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
      await this.$configLightbox();
    },
    async clickLink(incidentID: string) {
      this.sharedStore.permissionResults = [];
      this.sharedStore.isLoadingPermission = true;
      this.$router.push({ name: "ChatManageView", params: { id: incidentID } });
    },
    async getPhotoLibraryPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          await this.$PermissionsPlugin.requestPhoto();

          const result = await this.$PermissionsPlugin
            .checkPermissionPhoto()
            .then(async (_result: any) => {
              await setLocalStorage(this.sharedStore.is_photo, _result.value);
              if (_result.value == "granted") {
                this.$popUnique(this.sharedStore.permissionResults, "Photo");
              } else {
                this.$pushUnique(this.sharedStore.permissionResults, "Photo");
              }
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              await setLocalStorage(this.sharedStore.is_photo, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Photo");
              return false;
            });

          return result;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_photo, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Photo");
          return false;
        }
      } else {
        await setLocalStorage(this.sharedStore.is_photo, "granted");
        this.$popUnique(this.sharedStore.permissionResults, "Photo");
        return true;
      }
    },
    async getCameraPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          await this.$PermissionsPlugin.requestCamera();

          const result = await this.$PermissionsPlugin
            .checkPermissionCamera()
            .then(async (_result: any) => {
              await setLocalStorage(this.sharedStore.is_camera, _result.value);
              if (_result.value == "granted") {
                this.$popUnique(this.sharedStore.permissionResults, "Camera");
              }
              else this.$pushUnique(this.sharedStore.permissionResults, "Camera");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              await setLocalStorage(this.sharedStore.is_camera, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Camera");
              return false;
            });
          return result;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_camera, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Camera");
          return false;
        }
      } else {
        try {
          const permission = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
          if (permission) {
            permission.getTracks().forEach((track) => track.stop());
          }
          await setLocalStorage(this.sharedStore.is_camera, "granted");
          this.$popUnique(this.sharedStore.permissionResults, "Camera");
          return true;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_camera, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Camera");
          return false;
        }
      }
    },
    async getMicrophonePermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          await this.$PermissionsPlugin.requestMicrophone();

          const result = await this.$PermissionsPlugin
            .checkPermissionMicrophone()
            .then(async (_result: any) => {
              await setLocalStorage(this.sharedStore.is_microphone, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Microphone");
              else this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              await setLocalStorage(this.sharedStore.is_microphone, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
              return false;
            });
          return result;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_microphone, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
          return false;
        }
      } else {
        try {
          const permission = await navigator.mediaDevices.getUserMedia({ audio: true });
          permission.getTracks().forEach((track) => track.stop());
          await setLocalStorage(this.sharedStore.is_microphone, "granted");
          this.$popUnique(this.sharedStore.permissionResults, "Microphone");
          return true;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_microphone, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
          return false;
        }
      }
    },
  },
  async mounted() {
    await this.setting();

    this.intervalId = setInterval(async () => {
      const now = new Date();
      this.historySearchStore.calculate = await this.$calculateTimeColor(now.toISOString(), this.historySearchStore?.metadata?.arrived, this.historySearchStore?.metadata?.createdAt, this.$calculateMinutesDifference(this.historySearchStore?.metadata?.createdAt, this.historySearchStore?.metadata?.eta));
      this.timeLeft = this.$calculateTimeDifference(now, new Date(this.historySearchStore?.metadata?.eta), this.masterDataStore.lang());
      if (now >= new Date(this.historySearchStore?.metadata?.eta)) {
        clearInterval(this.intervalId);
        this.timeLeft = "None";
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const historySearchStore = useHistorySearchStore();
    const masterDataStore = useMasterDataStore();
    const chatFormStore = useChatFormStore();
    const sharedStore = useSharedStore();

    const addColor = computed(() => {
      switch (historySearchStore?.calculate?.now?.colorSegment) {
        case "Green":
          return "text-green-600";
        case "Yellow":
          return "text-yellow-300";
        case "Red":
          return "text-red-600";
        default:
          return "text-gray-600";
      }
    });

    const addArriveColor = computed(() => {
      switch (historySearchStore?.calculate?.arrived?.colorSegment) {
        case "Green":
          return "text-green-600";
        case "Yellow":
          return "text-yellow-300";
        case "Red":
          return "text-red-600";
        default:
          return "text-gray-600";
      }
    });
    return {
      authStore,
      historySearchStore,
      masterDataStore,
      chatFormStore,
      addColor,
      addArriveColor,
      sharedStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <div class="w-10 text-white text-left">
              <button
                @click="
                  () => {
                    $router.go(-1);
                  }
                "
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
              </button>
            </div>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.title") }}</h1>
            </div>
            <div class="w-10 text-white text-right">
              <button @click="clickLink($route.params.id)" :disabled="sharedStore.isLoadingPermission" class="cursor-pointer">
                <component :is="$solidIcons.ChatBubbleLeftEllipsisIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
              </button>
            </div>
          </div>
          <div class="flex flex-col gradient-border ttp-padding-01 w-full h-full pb-20 overflow-auto">
            <div v-if="true" class="flex flex-col">
              <div class="flex items-center justify-center w-full gap-2 mb-3">
                <div v-if="historySearchStore?.loading" class="flex gap-2 w-full items-center h-10"></div>
                <div v-else-if="$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.NEW])" class="flex gap-2 w-full items-center h-10">
                  <div class="inline-flex ttp-icon-box-02 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5A5A5A" class="ttp-icon-inside-box-02">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </div>
                  <div class="text-[#5A5A5A] ttp-text-lg font-bold">{{ $replaceAll(historySearchStore?.result?.status, "_", " ") }}</div>
                </div>
                <div v-else-if="$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.ACCEPTED, $enums.ENUM_INCIDENT_STATUS.WAITING])" class="flex gap-2 w-full items-center h-10">
                  <div class="inline-flex ttp-icon-box-02 items-center justify-center rounded-full bg-[#EFF1FF] text-gray-300">
                    <svg class="ttp-icon-inside-box-02" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_128_7932" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="5" y="3" width="31" height="37">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.37695 3.604H35.9276V39.1775H5.37695V3.604Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_128_7932)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4018 6.2915C10.4581 6.2915 8.06445 8.68696 8.06445 11.6289V31.0667C8.07341 32.5197 8.65033 33.8885 9.6877 34.9152C10.7233 35.9382 12.1512 36.5259 13.5595 36.49C13.572 36.49 13.7691 36.4883 13.7799 36.4883H27.9036C30.8455 36.456 33.2463 34.0247 33.241 31.0685V14.9453L24.9366 6.2915H13.4018ZM13.5165 39.1775C11.3665 39.1775 9.33295 38.3444 7.79749 36.8251C6.24949 35.295 5.38949 33.2525 5.37695 31.0738V11.6289C5.37695 7.20525 8.97641 3.604 13.4018 3.604H25.5099C25.8754 3.604 26.2248 3.75271 26.4792 4.01609L35.554 13.4743C35.7941 13.7233 35.9285 14.0584 35.9285 14.4042V31.0667C35.9374 35.4903 32.3433 39.1292 27.9179 39.1758L13.5685 39.1775H13.5165Z" fill="#424DB2" />
                      </g>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5761 16.0967H29.6383C26.3595 16.0878 23.6953 13.4146 23.6953 10.1394V4.92749C23.6953 4.18574 24.2973 3.58374 25.0391 3.58374C25.7808 3.58374 26.3828 4.18574 26.3828 4.92749V10.1394C26.3828 11.9383 27.8448 13.4039 29.6419 13.4092H34.5761C35.3179 13.4092 35.9199 14.0112 35.9199 14.753C35.9199 15.4947 35.3179 16.0967 34.5761 16.0967Z" fill="#424DB2" />
                      <path d="M16.2957 21.6655L19.0082 24.3591L25.8379 17.5768C26.2254 17.192 26.6855 16.9996 27.2184 16.9996C27.7512 16.9996 28.2113 17.192 28.5988 17.5768C28.9863 17.9617 29.1801 18.4186 29.1801 18.9477C29.1801 19.4769 28.9863 19.9338 28.5988 20.3186L20.3645 28.4958C19.977 28.8806 19.5249 29.0731 19.0082 29.0731C18.4915 29.0731 18.0395 28.8806 17.652 28.4958L13.5832 24.4072C13.1957 24.0224 13.002 23.5655 13.002 23.0363C13.002 22.5072 13.1957 22.0503 13.5832 21.6655C13.9707 21.2806 14.4228 21.0882 14.9395 21.0882C15.4561 21.0882 15.9082 21.2806 16.2957 21.6655Z" fill="#424DB2" />
                    </svg>
                  </div>
                  <div class="text-[#424DB2] ttp-text-lg font-bold">{{ $replaceAll(historySearchStore?.result?.status, "_", " ") }}</div>
                </div>
                <div v-else-if="$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.IN_PROGRESS])" class="flex gap-2 w-full items-center h-10">
                  <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-[#FFF2DF] text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <svg class="w-8 h-8" viewBox="0 0 43 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.5458 13.9668H17.5327V17.4585C17.5327 18.3845 17.9022 19.2726 18.5598 19.9274C19.2174 20.5823 20.1093 20.9501 21.0392 20.9501C21.9692 20.9501 22.8611 20.5823 23.5187 19.9274C24.1763 19.2726 24.5458 18.3845 24.5458 17.4585V13.9668Z" fill="#D98E1E" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V6.98333H3.50652V17.4583C3.50652 22.0886 5.35371 26.5292 8.64171 29.8032C11.9297 33.0773 16.3892 34.9167 21.0391 34.9167C16.3892 34.9167 11.9297 36.756 8.64171 40.0301C5.35371 43.3042 3.50652 47.7448 3.50652 52.375V62.85H0V69.8333H42.0783V62.85H38.5718V52.375C38.5718 47.7448 36.7246 43.3042 33.4366 40.0301C30.1486 36.756 25.6891 34.9167 21.0391 34.9167C23.3416 34.9167 25.6214 34.4651 27.7486 33.5877C29.8757 32.7104 31.8085 31.4244 33.4366 29.8032C35.0646 28.1821 36.3561 26.2575 37.2372 24.1393C38.1183 22.0212 38.5718 19.751 38.5718 17.4583V6.98333H42.0783V0H0ZM10.5196 6.98333H31.5587V17.4583C31.5587 20.2365 30.4504 22.9008 28.4776 24.8653C26.5048 26.8297 23.8291 27.9333 21.0391 27.9333C18.2492 27.9333 15.5735 26.8297 13.6007 24.8653C11.6279 22.9008 10.5196 20.2365 10.5196 17.4583V6.98333ZM10.5196 52.375V62.85H31.5587V52.375C31.5587 49.5969 30.4504 46.9325 28.4776 44.9681C26.5048 43.0036 23.8291 41.9 21.0391 41.9C18.2492 41.9 15.5735 43.0036 13.6007 44.9681C11.6279 46.9325 10.5196 49.5969 10.5196 52.375Z" fill="#D98E1E" />
                    </svg>
                  </div>
                  <div class="text-[#D98E1E] ttp-text-xl font-bold">{{ $replaceAll(historySearchStore?.result?.status, "_", " ") }}</div>
                </div>
                <div v-else-if="$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.COMPLETED, $enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED])" class="flex gap-2 w-full items-center h-10">
                  <div class="flex flex-col ttp-icon-box-02 items-center justify-center rounded-full bg-[#D7FFDC] text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <svg class="ttp-icon-inside-box-02" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_304_17717" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="93" height="93">
                        <rect width="93" height="93" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_304_17717)">
                        <path d="M42.4305 49.2123L37.0055 43.7873C36.2305 43.0123 35.3263 42.6248 34.293 42.6248C33.2597 42.6248 32.3555 43.0123 31.5805 43.7873C30.8055 44.5623 30.418 45.4826 30.418 46.5482C30.418 47.6139 30.8055 48.5342 31.5805 49.3092L39.718 57.5436C40.493 58.3186 41.3972 58.7061 42.4305 58.7061C43.4639 58.7061 44.368 58.3186 45.143 57.5436L61.6118 41.0748C62.3868 40.2998 62.7743 39.3795 62.7743 38.3139C62.7743 37.2482 62.3868 36.3279 61.6118 35.5529C60.8368 34.7779 59.9165 34.3904 58.8508 34.3904C57.7852 34.3904 56.8649 34.7779 56.0899 35.5529L42.4305 49.2123ZM46.4993 84.8623C46.0472 84.8623 45.6274 84.83 45.2399 84.7654C44.8524 84.7009 44.4649 84.604 44.0774 84.4748C35.3586 81.5686 28.4159 76.192 23.2493 68.3451C18.0826 60.4982 15.4993 52.054 15.4993 43.0123V24.7029C15.4993 23.0883 15.9675 21.6352 16.904 20.3436C17.8404 19.0519 19.0514 18.1154 20.5368 17.5342L43.7868 8.81543C44.6909 8.49251 45.5951 8.33105 46.4993 8.33105C47.4034 8.33105 48.3076 8.49251 49.2118 8.81543L72.4618 17.5342C73.9472 18.1154 75.1581 19.0519 76.0946 20.3436C77.031 21.6352 77.4993 23.0883 77.4993 24.7029V43.0123C77.4993 52.054 74.9159 60.4982 69.7493 68.3451C64.5826 76.192 57.6399 81.5686 48.9211 84.4748C48.5336 84.604 48.1461 84.7009 47.7586 84.7654C47.3711 84.83 46.9513 84.8623 46.4993 84.8623ZM46.4993 77.1123C53.2159 74.9811 58.7701 70.7186 63.1618 64.3248C67.5534 57.9311 69.7493 50.8269 69.7493 43.0123V24.7029L46.4993 15.9842L23.2493 24.7029V43.0123C23.2493 50.8269 25.4451 57.9311 29.8368 64.3248C34.2284 70.7186 39.7826 74.9811 46.4993 77.1123Z" fill="#039739" />
                      </g>
                    </svg>
                  </div>
                  <div class="text-[#039739] ttp-text-lg font-bold">{{ $replaceAll(historySearchStore?.result?.status, "_", " ") }}</div>
                </div>
                <div v-else-if="$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.REJECTED])" class="flex gap-2 w-full items-center h-10">
                  <div class="flex flex-col ttp-icon-box-02 items-center justify-center rounded-full bg-[#FFD1D3] text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <svg class="ttp-icon-inside-box-02" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.15 36C11.6167 36 11.1083 35.9 10.625 35.7C10.1417 35.5 9.71667 35.2167 9.35 34.85L1.15 26.65C0.783333 26.2833 0.5 25.8583 0.3 25.375C0.1 24.8917 0 24.3833 0 23.85V12.15C0 11.6167 0.1 11.1083 0.3 10.625C0.5 10.1417 0.783333 9.71667 1.15 9.35L9.35 1.15C9.71667 0.783333 10.1417 0.5 10.625 0.3C11.1083 0.1 11.6167 0 12.15 0H23.85C24.3833 0 24.8917 0.1 25.375 0.3C25.8583 0.5 26.2833 0.783333 26.65 1.15L34.85 9.35C35.2167 9.71667 35.5 10.1417 35.7 10.625C35.9 11.1083 36 11.6167 36 12.15V23.85C36 24.3833 35.9 24.8917 35.7 25.375C35.5 25.8583 35.2167 26.2833 34.85 26.65L26.65 34.85C26.2833 35.2167 25.8583 35.5 25.375 35.7C24.8917 35.9 24.3833 36 23.85 36H12.15ZM12.2 32H23.8L32 23.8V12.2L23.8 4H12.2L4 12.2V23.8L12.2 32ZM18 20.8L22.3 25.1C22.6667 25.4667 23.1333 25.65 23.7 25.65C24.2667 25.65 24.7333 25.4667 25.1 25.1C25.4667 24.7333 25.65 24.2667 25.65 23.7C25.65 23.1333 25.4667 22.6667 25.1 22.3L20.8 18L25.1 13.7C25.4667 13.3333 25.65 12.8667 25.65 12.3C25.65 11.7333 25.4667 11.2667 25.1 10.9C24.7333 10.5333 24.2667 10.35 23.7 10.35C23.1333 10.35 22.6667 10.5333 22.3 10.9L18 15.2L13.7 10.9C13.3333 10.5333 12.8667 10.35 12.3 10.35C11.7333 10.35 11.2667 10.5333 10.9 10.9C10.5333 11.2667 10.35 11.7333 10.35 12.3C10.35 12.8667 10.5333 13.3333 10.9 13.7L15.2 18L10.9 22.3C10.5333 22.6667 10.35 23.1333 10.35 23.7C10.35 24.2667 10.5333 24.7333 10.9 25.1C11.2667 25.4667 11.7333 25.65 12.3 25.65C12.8667 25.65 13.3333 25.4667 13.7 25.1L18 20.8Z" fill="#CC2B34" />
                    </svg>
                  </div>
                  <div class="text-[#F13C46] ttp-text-lg font-bold">{{ $replaceAll(historySearchStore?.result?.status, "_", " ") }}</div>
                </div>
                <div class="flex w-full">
                  <button class="w-full p-2 bg-indigo-900 rounded-[10px] justify-center items-center gap-2 inline-flex mt-2">
                    <div class="grow shrink basis-0 text-center text-white ttp-text-sm font-bold">
                      <template v-if="historySearchStore?.result?.information?.friendlyCategoryCode?.length">
                        <div>{{ $convertString(masterDataStore.getIncidentFriendlyCategoryByCodeAndLocale(historySearchStore?.result?.information?.friendlyCategoryCode, masterDataStore.lang())) }}</div>
                      </template>
                      <template v-if="historySearchStore?.result.information?.friendlyCategory?.content?.length">
                        <div>{{ ($getContentByLocale(historySearchStore?.result.information?.friendlyCategory?.content, masterDataStore.lang()) as any)?.title }}</div>
                      </template>
                      <template v-else>
                        <div>{{ `${$convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(historySearchStore?.result?.information?.categoryCode, masterDataStore.lang(), historySearchStore?.result?.information?.category))}${$convertString(masterDataStore.getIncidentTopicByCodeAndLocale(historySearchStore?.result?.information?.topicCode, masterDataStore.lang(), historySearchStore?.result?.information?.topic))}` }}</div>
                      </template>
                    </div>
                  </button>
                </div>
              </div>
              <div class="flex w-full px-4 py-2 mb-4 rounded-lg bg-[#EFF0F6]">
                <div class="w-full">
                  <small class="text-[#4E4E4E]">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_no") }}</small>
                  <div class="text-black ttp-text-base">{{ $convertString(historySearchStore?.result?.incidentNumber) }}</div>
                </div>
                <button @click="clickCopy($convertString(historySearchStore?.result?.incidentNumber))"><component :is="$outlineIcons.DocumentDuplicateIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-2" /></button>
              </div>
              <div id="ttp-map" class="w-full pb-4">
                <div class="flex justify-center items-center w-full px-2 mb-2">
                  <div class="w-full font-bold text-black ttp-text-base">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_info") }}</div>
                  <small class="w-full text-right ttp-text-sm">{{ $dayjs(historySearchStore?.result?.createdAt).format("DD/MM/YYYY HH:mm") }}</small>
                </div>

                <!-- Images -->
                <div class="swipe-photo flex gap-2 w-full">
                  <div v-if="$isArrayOfStringsOrObjects(historySearchStore?.result?.images)" class="flex flex-col justify-center items-center w-full">
                    <div><LoadingFormComponent /></div>
                    <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
                  </div>
                  <a v-else-if="historySearchStore?.result?.images" v-for="(_item, index) in historySearchStore?.result?.images" :key="index" :href="_item?.url ? _item?.url : '#'" target="_blank"><img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="_item?.url" alt="" /></a>
                </div>
                <!-- Map -->
                <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 leading-tight px-2 mt-2">
                  <button
                    type="button"
                    @click="
                      () => {
                        historySearchStore.isMap = !historySearchStore.isMap;
                        if (historySearchStore.isMap && !historySearchStore.isMapGenerate) {
                          initializeMap();
                        }
                      }
                    "
                    class="flex w-full items-center justify-center"
                  >
                    <div class="w-full font-bold text-black ttp-text-base text-left">{{ $t("btn.btn_map") }} <component :is="historySearchStore.isMap ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="inline-block ttp-icon-inside-box-03 opacity-40" /></div>
                    <!-- Google Map Link -->
                    <div v-if="!$inArray(historySearchStore?.result?.information?.latitude, ['13.736717', null])" class="w-full text-right ttp-text-base">
                      <a :href="`https://maps.google.com/?q=${historySearchStore?.result?.information?.latitude},${historySearchStore?.result?.information?.longitude}`" target="_blank"
                        ><div class="inline-flex rounded bg-blue-200 px-2 py-1 ttp-text-xs font-semibold leading-4 text-blue-700">{{ $t("btn.btn_google_map") }}</div></a
                      >
                    </div>
                  </button>
                </div>
                <div v-if="historySearchStore?.result?.information?.location" :class="[{ 'flex w-full h-60 mt-2 relative bg-white border border-gray-200': true, hidden: !historySearchStore.isMap }]">
                  <div class="flex w-full h-full">
                    <div :class="{ 'absolute flex w-full h-full z-10 items-center justify-center': true, 'bg-black opacity-80': $route.params.id && historySearchStore.result.information.latitude == '13.736717' }">
                      <div v-if="$route.params.id && historySearchStore.result.information.latitude == '13.736717'" class="text-white">ไม่พบพิกัดสถานที่</div>
                    </div>
                    <div id="map"></div>
                  </div>
                </div>
                <div v-else :class="[{ 'flex w-full h-60 mt-2 relative bg-white border border-gray-200': true, hidden: !historySearchStore.isMap }]">
                  <div class="flex w-full h-full">
                    <div :class="{ 'absolute flex w-full h-full z-10 items-center justify-center bg-black opacity-80': true }">
                      <div class="text-white">ไม่พบพิกัดสถานที่</div>
                    </div>
                  </div>
                </div>
                <!-- Information -->
                <div class="flex items-center w-full gap-2 text-black mb-2 py-4">
                  <div><component :is="$outlineIcons.MapPinIcon" class="inline-block ttp-icon-inside-box-02" /></div>
                  <div class="ttp-text-xs">{{ $convertString(historySearchStore?.result?.information?.location) }}</div>
                </div>
                <div class="flex items-center w-full gap-2 text-black ttp-text-xs">
                  <div><component :is="$outlineIcons.DocumentTextIcon" class="inline-block ttp-icon-inside-box-02" /></div>
                  {{ $convertString(historySearchStore?.result?.information?.detail) }}
                </div>
              </div>
              <!-- Button Cancel Report -->
              <div v-if="!$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.IN_PROGRESS, $enums.ENUM_INCIDENT_STATUS.COMPLETED, $enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REJECTED, $enums.ENUM_INCIDENT_STATUS.PENDING])" class="flex w-full gap-2 px-2 text-black border-t-2 py-10">
                <button
                  class="w-full text-center font-medium ttp-text-base text-[#CC2B34]"
                  @click="
                    async () => {
                      historySearchStore.isReject = !historySearchStore.isReject;
                    }
                  "
                >
                  {{ $t("btn.btn_cancel_report") }}
                </button>
              </div>
              <!-- Officer Information -->
              <div v-if="officers?.length" class="flex flex-col w-full p-4 mb-4 rounded-lg bg-[#EFF0F6]">
                <div class="w-full font-bold text-[#4E4E4E] ttp-text-base mb-4">Officer</div>
                <div v-if="officers" v-for="(user, key) in officers" class="flex items-center gap-4 w-full mb-2">
                  <template v-if="user">
                    <div class="flex flex-wrap justify-center">
                      <div v-if="user?.image" class="swipe-photo inline-flex ttp-icon-box-01 rounded-full bg-gray-100 text-gray-300 overflow-hidden">
                        <a v-for="(_item, index) in user?.image" :key="index" :href="_item?.url ? _item?.url : '#'" target="_blank"><img class="object-cover object-center w-36 h-36 inline-block" :src="_item?.url" alt="" /></a>
                      </div>
                      <div v-else class="inline-flex ttp-icon-box-01 items-center justify-center rounded-full bg-gray-100 text-gray-300">
                        <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
                      </div>
                    </div>
                    <div>
                      <div class="w-full text-black ttp-text-md font-bold">{{ $convertString(user?.content?.[masterDataStore.lang() == "TH" ? 0 : 1]?.fullName) }}</div>
                      <div v-if="key === 0" class="w-full ttp-text-sm text-black">Tourist Police</div>
                      <div v-else-if="key === 1" class="w-full ttp-text-sm text-black">Command Center Officer</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="historySearchStore.isCopyPopup" :is="defaultFormComponents.form.popup.success.component" v-bind="defaultFormComponents.form.popup.success.props" />
  <component v-if="historySearchStore.isReject" :is="defaultFormComponents.form.popup.reject.component" v-bind="defaultFormComponents.form.popup.reject.props" />
</template>

<style style="scss">
#ttp-map .gm-control-active.gm-fullscreen-control,
#ttp-map .gmnoprint {
  display: none !important;
}
</style>
