import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import { NativeAudio } from "@capacitor-community/native-audio";
import { useAudioStore } from "@/stores/Shared/useAudioStore";
import { useAuthStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import type { typeGetAlert } from "@/configs/types/Alert/typeAlert";

export const storeName = `useSharedStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;
export const useSharedStore = defineStore(storeName, {
  state: () => ({
    audioStore: useAudioStore(),
    authStore: useAuthStore(),
    mode: "true" as string,
    mobileSidebarOpen: false,
    desktopSidebarOpen: true,
    submenuOpen: "Dashboard" as string,
    count: 0,
    getProjectType: ENUM_APP_CATEGORY_NAME.TOURIST as any,
    locale: "EN" as string,
    isAlert: false,
    position: null as any,
    alertResult: null as any,
    alerts: [] as typeGetAlert[],
    is_track_location: `${storeName}:is_track_location`,
    is_microphone: `${storeName}:is_microphone`,
    is_push_notification: `${storeName}:is_push_notification`,
    is_camera: `${storeName}:is_camera`,
    is_photo: `${storeName}:is_photo`,
    isShowPermission: false,
    isLoadingPermission: false,
    permissionResults: [] as string[],
    onCall: false,
  }),
  actions: {
    appVersion() {
      return import.meta.env.VITE_APP_VERSION;
    },
    displayName() {
      return import.meta.env.VITE_DISPLAY_NAME;
    },
    async setSubmenuOpen(name: any) {
      this.submenuOpen = name;
      await setLocalStorage(`${storeName}:submenuOpen`, name);
    },
    async toggleMode() {
      this.mode = this.mode == "true" ? "false" : "true";
      await setLocalStorage(`${storeName}:mode`, this.mode);
    },
    increment() {
      this.count++;
    },
    decrement() {
      this.count--;
    },
    async startCall() {
      this.onCall = true;
      await NativeAudio.play({
        assetId: "call",
      });
      await NativeAudio.loop({
        assetId: "call",
      });
    },
    waitingCall() {
      NativeAudio.play({
        assetId: "waiting",
      });
    },
    async endCall() {
      if (!this.onCall) {
        return
      }
      this.onCall = false;

      Promise.all([
        NativeAudio.stop({ assetId: "waiting" }),
        NativeAudio.stop({ assetId: "call" }),
        NativeAudio.play({ assetId: "hang_up" }),
      ])
    },
    stopCall() {
      NativeAudio.stop({
        assetId: "call",
      });
    },
    stopWaitingCall() {
      NativeAudio.stop({
        assetId: "waiting",
      });
    },
  },
});
