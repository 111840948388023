import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeCreateIncident, typeGetIncident } from "@/configs/types/Incident/typeIncident";

export class fetchIncident {
  async ListIncident(status?: ENUM_INCIDENT_STATUS[]): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get(`/v1/incidents`, {
      params: {
        status: status ? status.join(",") : "",
      },
    });
    return data;
  }
  async CreateIncident(incident: typeCreateIncident): Promise<typeGetIncident> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).post<typeGetIncident>("/v1/incidents", incident);

    return data;
  }
  async Get(id: string): Promise<typeGetIncident> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetIncident>(`/v1/incidents/${id}`);
    return data;
  }
  async GetListIncidentChat(id: string, locale = "EN"): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any>(`/v1/incidents/${id}/chats`, { headers: { "Accept-Language": locale } });
    return data;
  }
  async Delete(id: string): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).delete<any>(`/v1/incidents/${id}`);
    return data;
  }
  async NotificationList(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any>(`/v1/notifications`, { params });
    return data;
  }
  async GetListIncidentChatByUser(params: object, locale = "EN"): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any>(`/v1/chats`, { params, headers: { "Accept-Language": locale } });
    return data;
  }
}
