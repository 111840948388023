export default {
  layout: {
    appName: "Туристическая полиция",
    menu: {
      AlertView: `Управление уведомлениями`,
      AlertManageView: `Уведомления`,
      PreviewView: `Предварительный просмотр`,
      HomeView: `Главная`,
      LogoutView: `Выйти`,
      NotFoundView: `Страница не найдена`,
      MaintenanceView: `Техническое обслуживание`,
      LoginView: `Войти`,
      ForgotPasswordView: `Изменить пароль`,
      AllIncidentView: `Все инциденты`,
      IncidentView: `Управление инцидентами`,
      NewIncidentView: `Новый инцидент`,
      AcceptedIncidentView: `Принятый инцидент`,
      WaitingIncidentView: `Ожидающий инцидент`,
      CurrentIncidentView: `Текущий инцидент`,
      ReportingIncidentView: `Сообщенный инцидент`,
      ClosingIncidentView: `Закрывающийся инцидент`,
      ClosedIncidentView: `Закрытый инцидент`,
      RejectedIncidentView: `Отклоненный инцидент`,
      ConferenceView: `NT Конференция`,
      OverviewView: `Обзор`,
      DashboardView: `Панель управления`,
      DashboardIncidentTypeView: `Типы инцидентов`,
      ProfileView: `Мой аккаунт`,
      SettingMenuView: `Мой аккаунт`,
      ProfileEditView: `Профиль пользователя`,
      PasswordSettingView: `Настройки пароля`,
      UserView: `Управление пользователями`,
      UserManageView: `Профиль пользователя`,
      TouristView: `Управление туристами`,
      TouristManageView: `Профиль туриста`,
      ChatView: `Чат`,
      IncidentManageView: `Управление инцидентами`,
      IncidentNoticeView: `Уведомление о происшествии`,
      IncidentReportView: `Отчет`,
      HistoryView: `История`,
      HistoryNoticeView: `Уведомление о происшествии`,
      HistoryReportView: `Предварительный отчет по инциденту, зарегистрированному в системе`,
      ReportIncidentView: `Отчет`,
      VoiceCallView: `Голосовой вызов`,
      OverviewReportView: `Отчет`,
      NewsView: "Управление новостями",
      NewsManageView: "Новости",
      NewsInternalView: "Корпоративные новости",
      PopularPlaceView: "Популярные места",
    },
    menu_head: {
      PreviewTitle: " Предварительный просмотр",
      AccountTitle: "Счет",
      UserTitle: "Участник",
      TouristTitle: "Турист",
      IncidentTitle: "Сообщение о происшествии",
      SupportTitle: "Вспомогательная программа",
      ProfileTitle: "Профиль",
      HistoryTitle: "Сообщение о происшествии",
      DashboardTitle: "Приборная панель",
      NewsTitle: "ประกาศ",
    },
    menu_bottom: {
      home_title: " Главная",
      report_title: "Отчет",
      history_title: "История",
      profile_title: "Профиль",
    },
    by: "При поддержке",
    create: "Создавать",
    update: "Редактировать",
    edit_address: "Сменить адрес",
    edit_address_placeholder: "Пожалуйста, введите адрес",
    please_input: "Пожалуйста, введите",
    not_found: "Не найдено",
    not_found_image: "Изображение не найдено",
    loading: "Загрузка",
    all: "Все",
    case: "Случай",
    non: "Не определено",
    non_image: "Нет, не хочу",
    non_location: "Не хочу делиться местоположением",
    select_picture: "Выберите изображение.",
    result: "Результат",
    anonymous: "Аноним",
    gender: {
      male: "Мужчина",
      female: "Женщина",
    },
    date: `Date`,
    error_detail: `An error has occurred. Please contact the public relations department.`,
  },
  divider: {
    or_login_with: "или войдите в систему",
  },
  page: {
    AllIncidentView: {
      title_1: `Время в пути:`,
      title_2: `Ожидаемое время прибытия:`,
      title_3: `Время прибытия на место происшествия:`,
      title_4: `Время на месте происшествия:`,
      waiting_1: `Сотрудники в пути`,
      waiting_2: `Пожалуйста, подождите...`,
      status_1: `Инцидент успешно устранен`,
      status_2: `Завершение работы`,
      status_3: `Отчет отменен`,
    },
    PermissionView: {
      app_detail: `Чтобы получить доступ к справке, всегда активируйте права доступа к приложению следующим образом.`,
      web_detail: ` <hr class="my-2" />
      <h2 class="ttp-text-lg font-medium text-center">Шаги для включения разрешений в браузере</h2>
      <hr class="my-2" />
      <div>1. Откройте браузер</div>
      <div>2. Нажмите на три точки в правом верхнем углу экрана, чтобы открыть меню</div>
      <div>3. Выберите "Настройки" (Settings)</div>
      <div>4. Прокрутите вниз и нажмите "Конфиденциальность и безопасность" (Privacy and security)</div>
      <div>5. Выберите "Настройки сайтов" (Site settings)</div>
      <hr class="my-2" />
      <h3 class="ttp-text-base font-medium">Разрешения:</h3>
      <div>- Местоположение: Нажмите "Местоположение" и включите "Сайты могут запрашивать ваше местоположение"</div>
      <div>- Камера: Нажмите "Камера" и включите "Сайты могут запрашивать доступ к вашей камере"</div>
      <div>- Микрофон: Нажмите "Микрофон" и включите "Сайты могут запрашивать доступ к вашему микрофону"</div>
      <div>- Уведомления: Нажмите "Уведомления" и включите "Сайты могут запрашивать отправку уведомлений"</div>
      <div>- Фотографии: Если сайт запрашивает доступ к файлам, фото будут управляться через разрешение на доступ к файлам, которое можно настроить для каждого сайта</div>
    `,
    },
    NewsInternalDetailView: {
      not_found_gallery: `Альбом не найден`,
      gallery: `Галерея`,
    },
    NewsDetailView: {
      not_found_gallery: `Альбом не найден`,
    },
    NewsInternalView: {
      gallery: `Галерея`,
    },
    NewsView: {
      title: `Новости`,
      not_found: `Новости не найдены`,
    },
    PreviewView: {
      title: `Предварительный просмотр`,
    },
    HomeView: {
      title: `Главная`,
      hello_txt: `Здравствуйте`,
      welcome_txt: `Добро пожаловать в Таиланд.`,
      welcome_detail_txt: `Туристическая полиция, ваш первый друг`,
      incident_status: `Статус инцидента`,
      popular_places: `Интересные места`,
      news: `Новости`,
      box_title: `Хотите сообщить о происшествии в туристическую полицию?`,
      box_detail: `Пожалуйста, нажмите кнопку, чтобы ответить на вопросы туристической полиции`,
      sos_title: `Нужна экстренная помощь?`,
      sos_detail: `Пожалуйста, нажмите кнопку экстренного вызова`,
      sos_btn: `SOS`,
      see_more: `Подробнее`,
    },
    LoginView: {
      title: "Войти",
      subtitle: "Добро пожаловать в командный центр",
      agent_subtitle: "Welcome to Agent",
      lang: "Выберите язык",
    },
    ForgotPasswordView: {
      title: "Забыли пароль",
      detail_step_1: "Don't worry, we'll get you back! Your email.",
      detail_step_2: "Пожалуйста, введите OTP, отправленный на вашу электронную почту.",
      detail_step_3: "Установите новый пароль прямо сейчас!",
      detail_step_verify: "Проверьте свою личность",
      create: "Создайте новый пароль",
      desc: "Введите свой e-mail для проверки",
      email: "We'll send a 6-digit code to your email.",
      otp: "Код проверки OTP",
      otp_send_to: "Код был отправлен вам",
      otp_resend: "Отправьте код еще раз по электронной почте",
    },
    ResetPasswordView: {
      title: "Пароль изменен",
      detail: " Ваш пароль был успешно изменен",
    },
    NotFoundView: {
      status_number: "404",
      title: `Это неловко...`,
      detail: `Похоже, мы не смогли найти страницу, которую вы искали. Возможно, она была перемещена или удалена. Извините за это! Попробуйте воспользоваться поиском в нашем веб-приложении.`,
    },
    MaintenanceView: {
      title: "Улучшение в процессе",
      detail: `Подождите, наш сайт обновляется и скоро вернется, выглядя лучше, чем свежий поджаренный бейгл. Спасибо за ваше терпение. Мы скоро представим вам что-то действительно крутое!`,
    },
    TouristManageView: {
      record_title: " История контактов (последняя)",
    },
    OverviewView: {
      title_left: "Уведомления",
      title_right: "Срочное уведомление SOS",
      tab_left: "Новый отчет о происшествии",
      tab_right: "Управляется",
      not_found: "Нет новых событий",
      tab_new: "Новое",
      tab_in_progress: "Прогресс",
      tab_successful: "Редактировать Успешно",
    },
    IncidentManageView: {
      title: "Управление инцидентами",
      document_number: "Номер документа",
      receive_date: "Дата получения",
      information: "Информация",
      history: "Управление историей",
      emergency_contact: "Экстренный контакт",
    },
    IncidentNoticeView: {
      history: "История",
      number_code: "Регистр номера",
    },
    RegisterView: {
      title: "Зарегистрироваться",
      desc: "По электронной почте",
      create: "Создать учетную запись",
      login: "У вас уже есть учетная запись? ",
    },
    ChatView: {
      title: "Защитник",
      location_title: "Локация",
      reply_message: `Ответить на сообщение`,
      make_a_call: `Позвонить`,
      hang_up: `Повесить трубку`,
      answer_a_call: `Ответить на звонок`,
      response_voice_call: `Ответить голосовым вызовом`,
      response_bot: `Ответ от защитника (бот)`,
      response_police: `Ответ от полиции`,
      end_conversation: `Конец разговора`,
      detail: `Если есть новый отчет о происшествии, пожалуйста, свяжитесь с нами`,
    },
    ChatManageView: {
      title: "Опекун",
      location_title: " Местоположение",
    },
    MessageView: {
      title: "Сообщение",
    },
    PreChatView: {
      title: "Сообщить об инциденте",
      sub_title: "Сообщить об инциденте",
      detail: "Сообщите об инциденте и обратитесь за помощью в туристическую полицию Таиланда. Это не информ.",
    },
    VoiceCallView: {
      hungUp: {
        hero: 'The call has ended.',
        title: 'Have you finished reporting your issue?',
        thankYou: {
          title: 'Thank you for information',
          reportOn: 'Your report no. is',
          description: 'Our tourist police will assist you as soon as possible. You can check the status of your report in history',
        },
        button: {
          confirm: 'Yes, I have',
          callAgain: 'Call 1155',
        }
      }
    },
    HistoryPoliceTouristAndAgentView: {
      title: " История инцидентов",
    },
    HistoryDetailPoliceTouristAndAgentView: {
      title: " История инцидентов",
      report_no: " Отчет №",
      report_info: "Информация об отчете",
      not_found_detail: "У вас здесь нет никакой истории инцидентов.",
    },
    ReportIncidentView: {
      title: "Отчет",
      guardian_chatbot: 'Чат-бот "Guardian"',
      report_incident_with_chatbot: "Сообщить об инциденте с помощью чат-бота",
      sos_report: "SOS-отчет",
      share_location: "Поделиться местоположением прямо сейчас для туристической полиции.",
    },
    ProfileMenuView: {
      title: "Профиль",
    },
    ProfileEditTouristView: {
      title: "Редактировать профиль",
    },
    ProfileSocialMediaAccountView: {
      title: "Аккаунт в социальных сетях.",
      link_txt: "Ссылка",
    },
    ProfileUnlinkView: {
      link_google_txt: `Вы подключены к аккаунту Google`,
      link_fb_txt: `Вы подключены к аккаунту Facebook`,
      link_apple_txt: `Вы подключены к аккаунту Apple`,
    },
    SettingContactUsView: {
      title: `Свяжитесь с нами`,
    },
    SettingLanguageView: {
      title: `Язык`,
    },
    SettingNotificationView: {
      title: `Уведомления`,
      push_notifications_txt: `Push-уведомления`,
      chat_txt: `Чат-уведомления`,
    },
    SettingSecurityPrivacyChangePasswordView: {
      title: `Изменить пароль`,
    },
    SettingSecurityPrivacyView: {
      title: `Безопасность и конфиденциальность`,
      allow_location: `Разрешить отслеживание моего местоположения`,
      allow_camera: `Разрешить доступ к камере`,
      allow_photo: `Разрешить доступ к фотоальбому`,
      allow_mic: `Разрешить доступ к микрофону`,
    },
    PopularPlaceView: {
      not_found: `Место не найдено`,
    },
    RegisterSuccessView: {
      head: `Регистрация успешна!`,
      title: `Подтверждение успешно!`,
      welcome: `Добро пожаловать`,
      detail_1: `Мы сделаем все возможное, чтобы помочь вам. Спасибо, что доверили нам заботу о вас`,
      detail_2: `Ваш аккаунт готов к использованию. Вы будете перенаправлены на главную страницу после нажатия`,
    },
  },
  btn: {
    btn_permission: `Настройки конфиденциальности`,
    btn_readmore: `Читать далее`,
    btn_skip: `Пропустить`,
    btn_go_home: `Вернуться на главную страницу`,
    btn_background_check: `Проверка данных`,
    btn_unlink: `Отменить подключение`,
    btn_destination_stoped_incident: `Прибыл на место и устранил инцидент`,
    btn_repair_report: `Редактировать`,
    btn_google_map: `Перейти на карту Google`,
    btn_reset: `Сброс`,
    btn_receieve_report: `Закрыть задачу`,
    btn_map: `Карта`,
    btn_share: `Поделиться`,
    btn_register: `Зарегистрироваться`,
    btn_create_user: `Создать пользователя`,
    btn_submit: `Отправить`,
    btn_confirm: `Подтвердить`,
    btn_select: `Выбрать файл`,
    btn_delete: `Удалить данные`,
    btn_delete_file: `Удалить файл`,
    btn_delete_image: `Удалить изображение`,
    btn_delete_account: `Удалить аккаунт`,
    btn_send_email: `Отправить приглашение по email`,
    btn_cancel: `Отменить`,
    btn_save: `Сохранить`,
    btn_next: `Далее`,
    btn_accept: `Принять`,
    btn_confirm_save: `Подтвердить сохранение`,
    btn_incident: `Инцидент`,
    btn_record: `История управления`,
    btn_assign: `Назначить`,
    btn_report: `Отправить отчет`,
    btn_edit: `Редактировать`,
    btn_back: `Назад`,
    btn_manage: `Управлять`,
    btn_export: `Экспортировать данные`,
    btn_new_case: `Создать инцидент`,
    btn_view_report: `Просмотреть отчет`,
    btn_back_dashboard: `Вернуться к панели управления`,
    btn_forgot_password: `Забыли пароль`,
    btn_sign_in: `Войти`,
    btn_change_password: `Изменить пароль`,
    btn_back_login: `Вернуться на страницу входа`,
    btn_not_receive: `Не получили код?`,
    btn_resend_code: `Отправить код повторно`,
    btn_search: `Поиск`,
    btn_reject: `Отклонить`,
    btn_all: `Показать все`,
    btn_receive: `Принять`,
    btn_view_notification: `Просмотреть уведомление`,
    btn_manage_history: `История управления`,
    btn_chat: `Перейти в чат`,
    btn_login_with_phone: `Продолжить с номером телефона`,
    btn_fb: `Продолжить с Facebook`,
    btn_google: `Продолжить с Google`,
    btn_apple: `Продолжить с Apple`,
    btn_successful: `Написать отчет о событии`,
    btn_edit_completed: `Сохранить результаты`,
    btn_location_completed: `Прибыл на место и устранил инцидент`,
    btn_edit_report: `Отправить отчет`,
    btn_approve_report: `Утвердить отчет`,
    btn_cancel_report: `Отменить отчет`,
    btn_select_default: `Выбрать`,
    btn_non: `Не указано`,
    btn_no: `Нет`,
    btn_fill_in_manually: `Заполнить вручную`,
    btn_start_report: `Начать отчет`,
    btn_get_report: `Сообщить об инциденте`,
    btn_logout: `Да, выйти`,
    btn_news: `Создать новость`,
    btn_submit_work: `Сохранить результаты`,
    btn_alert: `Создать уведомление`,
  },
  popup: {
    cancel: {
      cancel_title: "Отмена",
      cancel_detail: "Вы уверены? Отменить это обновление?",
    },
    reject: {
      reject_title: `Подтвердить отмену отчета`,
      reject_detail: `Вы уверены, что хотите отменить этот отчет?`,
    },
    error: {
      delete_title: "Подтвердить удаление",
      delete_detail: "Вы уверены? удалить эти данные?",
      delete_file_image_title: "Подтвердить удаление изображения",
      delete_file_image_detail: "Вы уверены? удалить эту картинку?",
    },
    success: {
      no_criminal: `Не найдено данных о криминальной истории`,
      title: `Сохранение успешно`,
      detail: `Вы можете продолжить следующий шаг`,
      history_detail: `Вы можете просмотреть историю отчетов о происшествиях`,
      receive_title: `Успешно получено`,
      receive_detail: `Вы можете продолжить следующий шаг`,
      edit_receive_title: `Успешно исправлен ответственный`,
      edit_receive_detail: `Ответственный может продолжить следующий шаг`,
      reject_title: `Отчет успешно отклонен`,
      email_title: `Электронная почта успешно изменена`,
      email_detail: `Вы можете войти, используя новый адрес электронной почты, пожалуйста, войдите снова`,
      delete_title: `Удаление данных успешно`,
      delete_detail: `Данные были удалены`,
      delete_user_title: `Аккаунт успешно удален`,
      delete_user_detail: `Аккаунт был удален`,
      delete_image_title: `Изображение успешно удалено`,
      delete_image_detail: `Изображение было удалено`,
      close_title: `Работа успешно завершена`,
      close_detail: ``,
      edit_title: `Успешно отредактировано`,
      edit_detail: ``,
      send_title: `Отчет успешно отправлен`,
      send_detail: ``,
      waiting_title: `Успешно отправлено`,
      closed_title: `Работа успешно завершена`,
      closed_detail: `Вы можете продолжить следующий шаг`,
      confirm_title: `Отчет успешно одобрен`,
      confirm_detail: ``,
      receive_suppressed_title: `Успешно остановлено`,
      receive_suppressed_detail: `Вы можете продолжить следующий шаг`,
      submit_title: `Отчет успешно получен`,
      submit_detail: `Вы можете продолжить следующий шаг`,
      copy_title: `Успешно скопировано`,
      send_wait_title: `Сохранение успешно`,
      save_receive_title: `Работа успешно сохранена`,
      tel1155_title: `Уведомление`,
      tel1155_detail: `Этот звонок платный. Вы принимаете или нет?`,
    },
    caution: {
      default_title: "Извините",
      delete_title: "Извините",
      default_detail: "На данный момент отчетов нет",
      recipient_detail: "Тема уже существует. или информация изменилась",
      completed_detail: "Невозможно завершить, поскольку этот элемент\nуже был отредактирован.",
      delete_image_detail: "Невозможно удалить изображение",
      delete_detail: "Невозможно удалить",
      delete_user_detail: "Невозможно удалить этот аккаунт",
      error_detail: "Произошла ошибка: невозможно завершить транзакцию.",
      duplicate_detail: "Данные уже существуют в системе",
    },
    textarea: {
      process_title: "Результаты обработки",
      process_placeholder: "Пожалуйста, введите результаты процесса",
      reject_title: "Отклонить результаты операции",
    },
    logout: {
      title: "Выход из системы",
      detail: "Вы уверены, что хотите выйти из системы?",
    },
  },
  form: {
    address: {
      label: "Адрес",
      placeholder: "Пожалуйста, введите адрес",
    },
    opening_hours: {
      label: "Часы работы",
      placeholder: "Пожалуйста, введите часы работы",
    },
    tourist_types: {
      label: "Типы туристов",
      placeholder: "Пожалуйста, введите типы туристов",
    },
    types: {
      label: "Типы",
      placeholder: "Пожалуйста, введите типы",
    },
    map: {
      label: "Карта",
      placeholder: "Пожалуйста, введите карту",
    },
    latitude: {
      label: "Широта",
      placeholder: "Пожалуйста, введите широту",
    },
    longitude: {
      label: "Долгота",
      placeholder: "Пожалуйста, введите долготу",
    },
    area: {
      label: "Площадь",
      placeholder: "Пожалуйста, введите площадь",
    },
    metadata: {
      label: "Сколько минут до места происшествия (введите цифру в минутах)",
      placeholder: "Пожалуйста, введите сколько минут до места происшествия",
    },
    contentAt: {
      label: "Дата новости",
      placeholder: "Пожалуйста, выберите дату новости",
    },
    publishedAt: {
      label: "Дата публикации",
      placeholder: "Пожалуйста, выберите дату публикации",
    },
    thumbnail: {
      label: "Изображение",
      placeholder: "Пожалуйста, выберите изображение",
    },
    gallery: {
      label: "Фотоальбом",
      placeholder: "Пожалуйста, выберите изображение",
    },
    published: {
      label: "Статус публикации",
      placeholder: "Пожалуйста, выберите статус публикации",
    },
    isInternal: {
      label: "Внутренний статус новости",
      placeholder: "Пожалуйста, выберите внутренний статус новости",
    },
    translations: {
      label: "Заголовок на тайском языке",
      placeholder: "Пожалуйста, введите заголовок на тайском языке",
    },
    translations_cn: {
      label: "Статья на китайском языке",
      placeholder: "Пожалуйста, введите статью на китайском языке",
    },
    translations_en: {
      label: "Статья на английском языке",
      placeholder: "Пожалуйста, введите статью на английском языке",
    },
    translations_jp: {
      label: "Статья на японском языке",
      placeholder: "Пожалуйста, введите статью на японском языке",
    },
    translations_kr: {
      label: "Статья на корейском языке",
      placeholder: "Пожалуйста, введите статью на корейском языке",
    },
    translations_ru: {
      label: "Статья на русском языке",
      placeholder: "Пожалуйста, введите статью на русском языке",
    },
    translations_th: {
      label: "Статья на тайском языке",
      placeholder: "Пожалуйста, введите статью на тайском языке",
    },
    channel: {
      label: `Канал`,
      placeholder: `Пожалуйста, выберите`,
    },
    is_track_location: {
      label: `Разрешить доступ к отслеживанию местоположения`,
      placeholder: `Пожалуйста, выберите`,
    },
    is_camera: {
      label: `Разрешить доступ к камере`,
      placeholder: `Пожалуйста, выберите`,
    },
    is_photo: {
      label: `Разрешить доступ к фото`,
      placeholder: `Пожалуйста, выберите`,
    },
    is_microphone: {
      label: `Разрешить доступ к микрофону`,
      placeholder: `Пожалуйста, выберите`,
    },
    is_push_notification: {
      label: `Разрешить доступ к push-уведомлениям`,
      placeholder: `Пожалуйста, выберите`,
    },
    is_chat: {
      label: `Разрешить доступ к чату`,
      placeholder: `Пожалуйста, выберите`,
    },
    emergency_contact: {
      label: `Экстренный контакт`,
      placeholder: `Пожалуйста, введите экстренный контакт`,
    },
    images: {
      label: `Изображения`,
      placeholder: `Пожалуйста, выберите изображение`,
    },
    region: {
      label: `Регион`,
      placeholder: `Пожалуйста, выберите регион`,
    },
    message: {
      label: `Aa`,
      placeholder: `Aa`,
    },
    numberic: {
      label: ``,
      placeholder: ``,
    },
    location_search: {
      label: `Место происшествия`,
      placeholder: `Искать место происшествия`,
    },
    notification_date: {
      label: `Дата уведомления`,
      placeholder: `Пожалуйста, выберите дату уведомления`,
    },
    case_number: {
      label: `Номер отчета`,
      placeholder: `Пожалуйста, введите номер отчета`,
    },
    friendly_category: {
      label: `Категория инцидента от туриста`,
      placeholder: `-`,
    },
    story_type: {
      label: `Тип инцидента`,
      placeholder: `Пожалуйста, выберите тип инцидента`,
    },
    province_area: {
      label: `Провинция`,
      placeholder: `Пожалуйста, выберите провинцию`,
    },
    recipient: {
      label: `Ответственное лицо`,
      placeholder: `Пожалуйста, выберите ответственное лицо`,
    },
    passport_number: {
      label: `Номер паспорта`,
      placeholder: `Пожалуйста, введите номер паспорта`,
    },
    number: {
      label: `Номер`,
      placeholder: `Пожалуйста, введите номер`,
    },
    gender: {
      label: `Пол`,
      placeholder: `Пожалуйста, выберите пол`,
    },
    nationality: {
      label: `Национальность`,
      placeholder: `Пожалуйста, выберите национальность`,
    },
    country: {
      label: `Страна`,
      placeholder: `Пожалуйста, выберите страну`,
    },
    app_channel: {
      label: `Канал регистрации`,
      placeholder: `Пожалуйста, выберите канал регистрации`,
    },
    notification_channel: {
      label: `Канал уведомлений`,
      placeholder: `Пожалуйста, выберите канал уведомлений`,
    },
    phone: {
      label: `Номер телефона`,
      placeholder: `Пожалуйста, введите номер телефона`,
    },
    informer_phone: {
      label: `Номер телефона заявителя`,
      placeholder: `Пожалуйста, введите номер телефона заявителя`,
    },
    identification_type: {
      label: `Тип удостоверения личности`,
      placeholder: `Пожалуйста, выберите тип удостоверения личности`,
    },
    type_code: {
      label: ``,
      placeholder: ``,
    },
    id: {
      label: `ID`,
      placeholder: `Пожалуйста, введите ID`,
    },
    email: {
      label: `Электронная почта`,
      placeholder: `Пожалуйста, введите электронную почту`,
    },
    nowPassword: {
      label: `Текущий пароль`,
      placeholder: `Пожалуйста, введите текущий пароль`,
    },
    password: {
      label: `Пароль`,
      placeholder: `Пожалуйста, введите пароль`,
    },
    newPassword: {
      label: `Новый пароль`,
      placeholder: `Пожалуйста, введите новый пароль`,
    },
    repeatPassword: {
      label: `Подтвердите новый пароль`,
      placeholder: `Пожалуйста, подтвердите новый пароль`,
    },
    remember_me: {
      label: `Запомнить меня`,
      placeholder: ``,
    },
    status: {
      label: `Статус`,
      placeholder: `Пожалуйста, выберите статус`,
    },
    role: {
      label: `Роль`,
      placeholder: `Пожалуйста, выберите роль`,
    },
    division: {
      label: "Отдел",
      placeholder: "Пожалуйста, выберите отдел",
    },
    subdivision: {
      placeholder: "Пожалуйста, выберите подразделение",
    },
    station: {
      placeholder: "Пожалуйста, выберите станцию",
    },
    responsibleArea: {
      label: "Ответственная область",
      placeholder: "Пожалуйста, выберите ответственную область",
    },
    keyword: {
      label: `Поиск...`,
      placeholder: ``,
    },
    image_name: {
      label: `Изображение`,
      placeholder: `Пожалуйста, введите название изображения`,
    },
    informer_name: {
      label: `Имя заявителя`,
      placeholder: `Пожалуйста, введите имя заявителя`,
    },
    informer_type: {
      label: `Тип заявителя`,
      placeholder: `Пожалуйста, выберите тип заявителя`,
    },
    name: {
      label: `Имя`,
      placeholder: `Пожалуйста, введите имя`,
    },
    title: {
      label: `Заголовок`,
      placeholder: `Пожалуйста, введите заголовок`,
    },
    title_news: {
      label: `Заголовок`,
      placeholder: `Пожалуйста, введите текст`,
    },
    title_alert: {
      label: `Заголовок`,
      placeholder: `Пожалуйста, введите текст`,
    },
    description: {
      label: `Описание`,
      placeholder: `Пожалуйста, введите текст`,
    },
    detail: {
      label: `Статья`,
      placeholder: `Пожалуйста, введите текст`,
    },
    firstName: {
      label: `Имя`,
      placeholder: `Пожалуйста, введите имя`,
    },
    lastName: {
      label: `Фамилия`,
      placeholder: `Пожалуйста, введите фамилию`,
    },
    idCard: {
      label: `Номер удостоверения личности`,
      placeholder: `Пожалуйста, введите номер удостоверения личности`,
    },
    isOpenAgent: {
      label: `Включить агентскую платформу`,
      placeholder: ``,
    },
    touristDetail: {
      label: `Детали инцидента (от туриста)`,
      placeholder: `Пожалуйста, введите детали инцидента (от туриста)`,
    },
    notificationChannel: {
      label: `Канал уведомлений`,
      placeholder: ``,
    },
    officerDetail: {
      label: `Детали инцидента (для сотрудников)`,
      placeholder: `Пожалуйста, введите детали`,
    },
    no: {
      label: `Код`,
      placeholder: `Пожалуйста, введите код`,
    },
    subject: {
      label: `Тема`,
      placeholder: `Пожалуйста, выберите тему`,
    },
    important: {
      label: `Важность`,
      placeholder: `Пожалуйста, выберите важность`,
    },
    district: {
      label: `Район`,
      placeholder: `Пожалуйста, выберите район`,
    },
    sub_district: {
      label: `Подрайон`,
      placeholder: `Пожалуйста, выберите подрайон`,
    },
    location_detail: {
      label: `Детали местоположения`,
      placeholder: `Пожалуйста, введите детали местоположения`,
    },
    pin_location: {
      label: `Местоположение`,
      placeholder: ``,
    },
    submit_story: {
      label: `Отправить историю`,
      placeholder: ``,
    },
    close_case: {
      label: `Закрыть дело`,
      placeholder: ``,
    },
    send_responsibility: {
      label: `Передать ответственную область`,
      placeholder: `Пожалуйста, выберите ответственную область`,
    },
    agency: {
      label: `Агентство`,
      placeholder: ``,
    },
    police_inspector: {
      label: `Инспектор полиции`,
      placeholder: ``,
    },
    result: {
      label: `Результат`,
      placeholder: ``,
    },
    responsible_person: {
      label: `Ответственный`,
      placeholder: `Пожалуйста, выберите ответственного`,
    },
    updated_date: {
      label: `Последнее обновление`,
      placeholder: ``,
    },
    estimate_time: {
      label: `Примерное время`,
      placeholder: ``,
    },
    subject_user: {
      label: `Ответственный пользователь`,
      placeholder: ``,
    },
    assign_user: {
      label: `Назначивший пользователь`,
      placeholder: ``,
    },
    inspector_user: {
      label: `Инспектор станции`,
      placeholder: ``,
    },
    type: {
      label: `Тип инцидента`,
      placeholder: `Пожалуйста, выберите тип инцидента`,
    },
    startAt: {
      label: `Дата начала`,
      placeholder: `Пожалуйста, выберите дату начала`,
    },
    endAt: {
      label: `Дата окончания`,
      placeholder: `Пожалуйста, выберите дату окончания`,
    },
    information_user: {
      label: `Данные заявителя`,
      placeholder: `Пожалуйста, выберите данные заявителя`,
    },
    admin_user: {
      label: `Администратор`,
      placeholder: `Пожалуйста, выберите администратора`,
    },
    control_user: {
      label: `Контролирующий пользователь`,
      placeholder: `Пожалуйста, выберите контролирующего пользователя`,
    },
    responsibleAreaCodes: {
      label: "Ответственная область",
      placeholder: "Пожалуйста, выберите ответственную область",
    },
    locales: {
      label: "Язык",
      placeholder: "Пожалуйста, выберите язык",
    },
  },
  error: {
    password: "Пароль должен состоять не менее чем из 6 символов",
    invalid_credential: "Невозможно войти в систему. Пожалуйста, попробуйте еще раз",
  },
  caution: {
    user_1: `Обратите внимание: если включена система операций агентов, необходимо выбрать ответственную область`,
    password_1: `Пароль должен содержать не менее 6 символов, включая прописные и строчные буквы, а также цифры`,
    picture_1: `Рекомендация: размер изображения 200 x 200 пикселей`,
    picture_2: `(максимум 5 МБ) Тип файла: JPG (JPEG), PNG`,
    picture_1_2: `Рекомендация: размер изображения 490 x 385 пикселей`,
    picture_2_2: `(максимум 5 МБ) Тип файла: JPG (JPEG), PNG`,
    delete_title: ``,
    email_1: `Мы будем использовать этот адрес электронной почты для проверки вашей учетной записи`,
  },
  role: {
    ADMIN: "Администратор",
    CENTRAL_OFFICER: "Центральный офицер",
    OFFICER: "Офицер",
    REPORTER: "Репортер",
    AGENT: "Агент",
  },
  incident_status: {
    waiting: "Ожидающий",
    in_progress: "В процессе",
    canceled: "Завершено",
  },
  lang: {
    th: "ไทย",
    en: "Английский",
    cn: "中国大陆",
    ru: "Россия",
    kr: "대한민국",
    jp: "日本",
  },
  progress: {
    processing_time: "Время обработки",
    period_time: "Период времени",
  },
  go_to_chat: "Go back to chat",
  sos: {
    title: 'SOS (сос) - система может быть использована для сообщения о происшествиях только вследующих случаях:',
    conditions: [
      'Получение серьезных травм или физического вреда, например, физическое нападение.',
      'Серьезный ущерб имуществу, например. Если у вас кража, грабеж и разбой.',
      'Серьезный несчастный случай.',
      'Самые опасные заболевания.',
    ],
    description: 'Если вы не соответствуете вышеуказанным критериям, офицер попросит вас вернуться киспользованию в канал чата приложения туристической полиции Таиланда или горячей линии 1155',
    button: {
      confirm: 'Подтвердить',
      cancel: 'Отменить',
    },
  }
};
