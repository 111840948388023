import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import { ENUM_GENDER, ENUM_IDENTIFICATION_TYPE, ENUM_MUTATION, ENUM_INFORMER_TYPE, ENUM_MUTATION_STATUS, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { convertEnumToArray, convertToValues, inArray, deleteKeys, renameKeys, wrapConvertValues } from "@/configs/helpers/MainHelper";
import type { $string } from "@/configs/types/Shared/typeShare";
import { useIncidentAssigneeFormStore } from "./useIncidentAssigneeFormStore";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";
import { ENUM_BUTTON_STATE } from "../../../../configs/enums/enum";

const storeName = `useIncidentFormStore-${import.meta.env.VITE_APP_VERSION}`;

const pick = {
  id: {
    is: false,
    name: `id`,
    label: `form.id.label`,
    placeholder: `form.id.placeholder`,
    value: null,
  },
  incidentNumber: {
    is: false,
    name: `incidentNumber`,
    label: `form.incidentNumber.label`,
    placeholder: `form.incidentNumber.placeholder`,
    value: null,
  },
  createdAt: {
    is: false,
    name: `createdAt`,
    label: `form.createdAt.label`,
    placeholder: `form.createdAt.placeholder`,
    value: null,
  },
  status: {
    is: false,
    name: `id`,
    label: `form.status.label`,
    placeholder: `form.status.placeholder`,
    value: null,
  },
  guestID: {
    is: false,
    name: `guestID`,
    label: `form.id.label`,
    placeholder: `form.id.placeholder`,
    value: null,
  },
};
const isRequired = {
  ...pick,
  images: {
    is: false,
    name: `images`,
    label: `form.images.label`,
    placeholder: `form.images.placeholder`,
    value: null,
  },
  informerType: {
    is: true,
    name: `informerType`,
    label: `form.informer_type.label`,
    placeholder: `form.informer_type.placeholder`,
    value: ENUM_INFORMER_TYPE.GUEST,
  },
  touristID: {
    is: false,
    name: `touristID`,
    label: `form.id.label`,
    placeholder: `form.id.placeholder`,
    value: null,
  },
  responsibleID: {
    is: false,
    name: `responsibleID`,
    label: `form.responsibleID.label`,
    placeholder: `form.responsibleID.placeholder`,
    value: "",
  },
  responsible: {
    is: false,
    name: `responsible`,
    label: `form.responsible.label`,
    placeholder: `form.responsible.placeholder`,
    value: "",
  },
  responsibleAreaCode: {
    is: true,
    name: `responsibleAreaCode`,
    label: `form.responsibleArea.label`,
    placeholder: `form.responsibleArea.placeholder`,
    value: "",
  },
  externalDepartmentID: {
    is: true,
    name: `externalDepartment`,
    label: `form.externalDepartment.label`,
    placeholder: `form.externalDepartment.placeholder`,
    value: "",
  },
  channel: {
    is: false,
    name: `channel`,
    value: null,
  },
  guest: {
    name: {
      is: true,
      name: `guest.name`,
      label: `form.name.label`,
      placeholder: `form.name.placeholder`,
      value: null,
    },
    nationality: {
      is: false,
      name: `guest.nationality`,
      label: `form.nationality.label`,
      placeholder: `form.nationality.placeholder`,
      value: null,
    },
    country: {
      is: false,
      name: `guest.country`,
      label: `form.country.label`,
      placeholder: `form.country.placeholder`,
      value: null,
    },
    contactNumber: {
      is: true,
      name: `guest.contactNumber`,
      label: `form.phone.label`,
      placeholder: `form.phone.placeholder`,
      value: null,
    },
    email: {
      is: false,
      name: `guest.email`,
      label: `form.email.label`,
      placeholder: `form.email.placeholder`,
      value: null,
    },
    gender: {
      is: false,
      name: `guest.gender`,
      label: `form.gender.label`,
      placeholder: `form.gender.placeholder`,
      value: null,
    },
    identificationType: {
      is: false,
      name: `guest.identificationType`,
      label: `form.identification_type.label`,
      placeholder: `form.identification_type.placeholder`,
      value: ENUM_IDENTIFICATION_TYPE.PASSPORT,
    },
    identificationNumber: {
      is: false,
      name: `guest.identificationNumber`,
      label: `form.passport_number.label`,
      placeholder: `form.passport_number.placeholder`,
      value: null,
    },
    emergencyContact: {
      name: {
        is: false,
        name: `guest.emergencyContact.name`,
        label: `form.name.label`,
        placeholder: `form.name.placeholder`,
        value: null,
      },
      contactNumber: {
        is: false,
        name: `guest.emergencyContact.contactNumber`,
        label: `form.phone.label`,
        placeholder: `form.phone.placeholder`,
        value: null,
      },
      identificationType: {
        is: false,
        name: `guest.emergencyContact.identificationType`,
        label: `form.identification_type.label`,
        placeholder: `form.identification_type.placeholder`,
        value: ENUM_IDENTIFICATION_TYPE.PASSPORT,
      },
      identificationNumber: {
        is: false,
        name: `guest.emergencyContact.identificationNumber`,
        label: `form.passport_number.label`,
        placeholder: `form.passport_number.placeholder`,
        value: null,
      },
    },
  },
  // data: {
  //   content: {
  //     is: false,
  //     label: ``,
  //     placeholder: ``,
  //     value: null,
  //   },
  //   images: {
  //     is: false,
  //     label: `form.image_name.label`,
  //     placeholder: `form.image_name.placeholder`,
  //     value: null,
  //   },
  // },
  information: {
    detail: {
      is: true,
      name: `information.detail`,
      label: `form.touristDetail.label`,
      placeholder: `form.touristDetail.placeholder`,
      value: null,
    },
    incidentCode: {
      is: true,
      name: `information.incidentCode`,
      label: `form.no.label`,
      placeholder: `form.no.placeholder`,
      value: null,
    },
    typeCode: {
      is: false,
      name: `information.typeCode`,
      label: `form.type_code.label`,
      placeholder: `form.type_code.placeholder`,
      value: null,
    },
    friendlyCategoryCode: {
      is: true,
      name: `information.friendlyCategoryCode`,
      label: `form.friendly_category.label`,
      placeholder: `form.friendly_category.placeholder`,
      value: "",
    },
    categoryCode: {
      is: true,
      name: `information.categoryCode`,
      label: `form.story_type.label`,
      placeholder: `form.story_type.placeholder`,
      value: "",
    },
    topicCode: {
      is: false,
      name: `information.topicCode`,
      label: `form.subject.label`,
      placeholder: `form.subject.placeholder`,
      value: "",
    },
    priority: {
      is: true,
      name: `information.priority`,
      label: `form.important.label`,
      placeholder: `form.important.placeholder`,
      value: null,
    },
    location: {
      is: false,
      name: `information.location`,
      label: `form.location_detail.label`,
      placeholder: `form.location_detail.label`,
      value: null,
    },
    latitude: {
      is: false,
      name: `information.latitude`,
      label: `form.latitude.label`,
      placeholder: `form.latitude.placeholder`,
      value: 0,
    },
    longitude: {
      is: false,
      name: `information.longitude`,
      label: `form.longitude.label`,
      placeholder: `form.longitude.placeholder`,
      value: 0,
    },
    provinceID: {
      is: false,
      name: `information.provinceID`,
      label: `form.province_area.label`,
      placeholder: `form.province_area.placeholder`,
      value: "",
    },
    districtID: {
      is: false,
      name: `information.districtID`,
      label: `form.district.label`,
      placeholder: `form.district.placeholder`,
      value: "",
    },
    subDistrictID: {
      is: false,
      name: `information.subDistrictID`,
      label: `form.sub_district.label`,
      placeholder: `form.sub_district.placeholder`,
      value: "",
    },
  },
};
export const useIncidentFormStore = defineStore(storeName, {
  state: () => ({
    id: null as $string,
    fetchIncident: new fetchIncident(),
    fetchFile: new fetchFile(),
    isOpenComplete: false,
    isDialogName: false,
    isDialogEmail: false,
    isDialogContactNumber: false,
    isDialogIdentificationNumber: false,
    isBackgroundCheck: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    isHistory: false,
    isReject: false,
    isMap: false,
    isDetail: false,
    isMapGenerate: false,
    isChat: false,
    currentPage: 0,
    totalPage: 1,
    metadata: { eta: undefined, arrived: undefined, createdAt: undefined } as any,
    tableResults: null as any,
    isButtonState: ENUM_BUTTON_STATE.SAVE as ENUM_BUTTON_STATE,
    isRequired,
    loading: true,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    results: [] as any,
    result: convertToValues(isRequired) as typeGetIncident,
    sendResult: {} as typeGetIncident,
    error: {} as { [key: string]: string | null | any },
    validationSchema: yup.object().shape({
      informerType: isRequired.informerType.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      touristID: isRequired.touristID.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      responsibleAreaCode: isRequired.responsibleAreaCode.is
        ? yup
            .string()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === null ? "" : value;
            })
            .test((value: any): any => {
              return inArray(useIncidentAssigneeFormStore().result.status, [ENUM_INCIDENT_STATUS.ACCEPTED, ENUM_INCIDENT_STATUS.WAITING]) ? value : true;
            })
        : yup.string().nullable().notRequired(),
      externalDepartmentID: isRequired.externalDepartmentID.is
        ? yup
            .string()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === null ? "" : value;
            })
            .test((value: any): any => {
              return inArray(useIncidentAssigneeFormStore().result.status, [ENUM_INCIDENT_STATUS.FORWARDED]) ? value : true;
            })
        : yup.string().nullable().notRequired(),
      guest: yup.object().shape({
        name: isRequired.guest.name.is
          ? yup
              .string()
              .nullable()
              .test(function (value) {
                return (value && inArray((this.options as any).from[1].value.informerType, [ENUM_INFORMER_TYPE.GUEST, ENUM_INFORMER_TYPE.TOURIST])) || inArray((this.options as any).from[1].value.informerType, [ENUM_INFORMER_TYPE.ANONYMOUS]);
              })
          : yup.string().nullable().notRequired(),
        nationality: isRequired.guest.nationality.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        country: isRequired.guest.country.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        email: isRequired.guest.email.is
          ? yup
              .string()
              .nullable()
              .test(function (value) {
                return (value && inArray((this.options as any).from[1].value.informerType, [ENUM_INFORMER_TYPE.GUEST, ENUM_INFORMER_TYPE.TOURIST])) || inArray((this.options as any).from[1].value.informerType, [ENUM_INFORMER_TYPE.ANONYMOUS]);
              })
              .email()
          : yup.string().nullable().notRequired().email(),
        gender: isRequired.guest.gender.is ? yup.string().nullable().oneOf(convertEnumToArray(ENUM_GENDER)).required() : yup.string().oneOf(convertEnumToArray(ENUM_GENDER)).nullable().notRequired(),
        identificationType: isRequired.guest.identificationType.is ? yup.string().nullable().oneOf(convertEnumToArray(ENUM_IDENTIFICATION_TYPE)).required() : yup.string().oneOf(convertEnumToArray(ENUM_IDENTIFICATION_TYPE)).nullable().notRequired(),
        identificationNumber: isRequired.guest.identificationNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        emergencyContact: yup.object().shape({
          name: isRequired.guest.emergencyContact.name.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
          contactNumber: isRequired.guest.emergencyContact.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
          identificationType: isRequired.guest.emergencyContact.identificationType.is ? yup.string().nullable().oneOf(convertEnumToArray(ENUM_IDENTIFICATION_TYPE)).required() : yup.string().oneOf(convertEnumToArray(ENUM_IDENTIFICATION_TYPE)).nullable().notRequired(),
          identificationNumber: isRequired.guest.emergencyContact.identificationNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        }),
      }),
      information: yup.object().shape({
        detail: isRequired.information.detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        incidentCode: isRequired.information.incidentCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        typeCode: isRequired.information.typeCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        categoryCode: isRequired.information.categoryCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        topicCode: isRequired.information.topicCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        priority: isRequired.information.priority.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        location: isRequired.information.location.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        latitude: isRequired.information.latitude.is
          ? yup.number().nullable().required()
          : yup
              .number()
              .nullable()
              .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
              })
              .notRequired(),
        longitude: isRequired.information.longitude.is
          ? yup.number().nullable().required()
          : yup
              .number()
              .nullable()
              .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
              })
              .notRequired(),
        provinceID: isRequired.information.provinceID.is
          ? yup.number().nullable().required()
          : yup
              .number()
              .nullable()
              .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
              })
              .notRequired(),
        districtID: isRequired.information.districtID.is
          ? yup.number().nullable().required()
          : yup
              .number()
              .nullable()
              .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
              })
              .notRequired(),
        subDistrictID: isRequired.information.subDistrictID.is
          ? yup.number().nullable().required()
          : yup
              .number()
              .nullable()
              .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
              })
              .notRequired(),
      }),
    }),
    assignField: ["information.provinceID", "information.districtID", "information.subDistrictID", "guestID", "guest", "guest.name", "guest.nationality", "guest.country", "guest.contactNumber", "guest.email", "guest.gender", "guest.identificationNumber", "guest.emergencyContact", "guest.emergencyContact.name", "guest.emergencyContact.contactNumber", "guest.emergencyContact.identificationNumber", "responsibleAreaCode"],
    ignoreEmptyField: ["guestID", "guest", "guest.name", "guest.nationality", "guest.country", "guest.contactNumber", "guest.email", "guest.gender", "guest.identificationNumber", "guest.emergencyContact", "guest.emergencyContact.name", "guest.emergencyContact.contactNumber", "guest.emergencyContact.identificationNumber", "responsibleAreaCode"],
    convertNumberField: ["information.latitude", "information.longitude", "information.provinceID", "information.districtID", "information.subDistrictID"],
    removeAnonymousField: ["id", "channel", "status", "type", "responsible", "responsibleID", "createdAt", "updatedAt", "progress", "reports", "guestID", "guest", "information.incidentID"],
    removeField: ["id", "channel", "status", "type", "responsible", "responsibleID", "createdAt", "updatedAt", "progress", "reports", "guest.id", "guest.createdAt", "guest.updatedAt", "guest.deletedAt", "guest.contactNumberCountryCode", "information.incidentID", "guest.incidents"],
  }),
  actions: {
    async closeHistoryModal() {
      this.isHistory = false;
    },
    async openHistoryModal() {
      this.isHistory = true;
    },
    async settingResult() {
      this.sendResult = wrapConvertValues(this.result, this.ignoreEmptyField, this.convertNumberField);

      if (inArray(this.sendResult.informerType, [ENUM_INFORMER_TYPE.ANONYMOUS])) {
        this.sendResult = deleteKeys({ ...this.sendResult }, this.removeAnonymousField);
      } else {
        this.sendResult = deleteKeys({ ...this.sendResult }, this.removeField);
      }

      if (inArray(this.sendResult.informerType, [ENUM_INFORMER_TYPE.TOURIST])) {
        this.sendResult = renameKeys(this.sendResult, [
          { name: "guestID", rename: "touristID" },
          { name: "guest", rename: "tourist" },
        ]);
        this.sendResult.guestID = null;
      } else if (inArray(this.sendResult.informerType, [ENUM_INFORMER_TYPE.GUEST])) {
        this.sendResult.touristID = null;
      } else {
        this.sendResult.guestID = null;
        this.sendResult.touristID = null;
      }
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      await this.settingResult();
      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        return true;
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return false;
      }
    },
    async create(result: typeGetIncident) {
      try {
        this.loading = true;
        const data = await this.fetchIncident.Create(result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;
        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
        this.isSuccess = true;
        throw error;
      }
    },
    async update(id: $string, result: typeGetIncident): Promise<typeGetIncident> {
      try {
        this.loading = true;
        const data = await this.fetchIncident.Update(id || "", result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;
        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
        this.isSuccess = true;
        throw error;
      }
    },
  },
});
