<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";

import { Swiper, SwiperSlide } from "swiper/vue";
import { useNewsInternalSearchStore } from "./stores/useNewsInternalSearchStore";
import type { $string } from "@/configs/types/Shared/typeShare";

export default defineComponent({
  name: "NewsInternalDetailView",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      componentName: this.$options.name,
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href,
      isVisibleSearch: false,
      result: null as any,
      image_urls: [] as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.newsSearchStore.error.keyword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                classIcon: "ttp-icon-inside-box-03 text-[#2C3483]",
                componentIcon: this.$solidIcons.NewspaperIcon,
                isDisable: this.loading(),
                isRequired: this.newsSearchStore.isRequired.keyword.is,
                textError: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.newsSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                value: this.newsSearchStore.result.keyword,
                name: this.newsSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: async (value: $string) => {
                this.newsSearchStore.result.keyword = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async fetch() {
      this.newsSearchStore.loading = true;
      if (this.$route.params.id) {
        await Promise.all([
          (async () => {
            const result = await this.newsSearchStore.fetchNews.Get(this.$route.params.id as string);
            this.newsSearchStore.getResult = result;

            // Get Thumbnail
            if (this?.newsSearchStore?.getResult?.thumbnail) {
              this.newsSearchStore.getResult.thumbnail = await Promise.all(await this.newsSearchStore.fetchFile.Get([this?.newsSearchStore?.getResult?.thumbnail]));
            }

            // Get Images
            if (this?.newsSearchStore?.getResult?.images?.length) {
              this.newsSearchStore.getResult.images = await Promise.all(await this.newsSearchStore.fetchFile.Get(this?.newsSearchStore?.getResult?.images));
            }

            this.$configLightbox();
          })(),
        ]);
      }
      this.newsSearchStore.loading = false;
    },
    async newsSearchFetch() {
      if (!this.loading()) this.newsSearchStore.onUpdateSearchCurrentPage(Number(this.$route.query.page) || 1);
    },
    loading() {
      return false;
    },
    async reset() {
      await this.newsSearchStore.$reset();
    },
    async setting() {
      await this.fetch();
      await this.$configLightbox();
    },
  },
  created() {
    watch(
      () => this.$route.params.id,
      () => {
        this.fetch();
      }
    );
  },
  async mounted() {
    await this.reset();
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const newsSearchStore = useNewsInternalSearchStore();

    return {
      authStore,
      newsSearchStore,
      masterDataStore,
      swiperOptions: ref({
        navigation: true,
        pagination: true,
        breakpoints: {
          320: {
            slidesPerView: 4,
          },
          425: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 4,
          },
          1440: {
            slidesPerView: 6,
          },
        },
      }),
    };
  },
});
</script>

<template>
  <div
    v-if="isVisibleSearch"
    @click="
      () => {
        isVisibleSearch = !isVisibleSearch;
      }
    "
    class="absolute top-0 left-0 bg-black flex w-full h-full z-[10] opacity-50"
  ></div>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto pb-20 mt-24">
            <div class="flex w-full justify-center items-center">
              <button
                class="w-10 text-black text-left z-[11]"
                @click="
                  () => {
                    $router.push({ name: 'NewsInternalView' });
                  }
                "
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
              </button>
              <div class="w-full">
                <time class="w-full ttp-text-xs"
                  ><span class="w-full ttp-text-xs font-medium">{{ $t("layout.date") }}</span
                  >: {{ $dayjs(new Date(newsSearchStore?.getResult?.contentAt)).format("DD MMMM YYYY") }}</time
                >
                <h1 class="w-full ttp-text-lg font-bold">{{ $convertString($filterNewsByLocale(newsSearchStore?.getResult?.translations, masterDataStore?.lang())?.title) }}</h1>
              </div>
            </div>
            <hr />
            <p class="ttp-text-xs" v-html="$convertString($filterNewsByLocale(newsSearchStore?.getResult?.translations, masterDataStore?.lang())?.detail)"></p>
            <hr />
            <template v-if="newsSearchStore?.getResult?.images?.length">
              <h3 class="w-full ttp-text-md font-bold">{{ $t("page.NewsInternalDetailView.gallery") }}</h3>
              <div class="swipe-photo flex gap-2 w-full">
                <div class="flex flex-col justify-center items-center w-full">
                  <div><LoadingFormComponent /></div>
                  <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
                </div>
                <a v-for="(item, index) in (newsSearchStore?.getResult?.images || []).slice(0, 4)" :href="item.url" target="_blank">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="item.url" alt="" />
                  <template v-if="index === 3 && newsSearchStore?.getResult?.images?.length > 4">
                    <div class="absolute top-0 left-0 bg-black opacity-50 w-full h-full"></div>
                    <div class="absolute flex top-0 left-0 w-full h-full text-white justify-center items-center ttp-text-xl font-bold">{{ newsSearchStore?.getResult?.images.length - 3 }}+</div>
                  </template>
                </a>
                <a v-for="item in (newsSearchStore?.getResult?.images || []).slice(4)" :href="item.url" target="_blank">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="item?.url" alt="" />
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss">
.pswp img {
  max-width: none;
  object-fit: contain;
}
.pswp__img--placeholder {
  display: none !important;
}
</style>
