import { defineStore } from "pinia";
import { Geolocation, type PermissionStatus as GeolocationPermissionStatus } from "@capacitor/geolocation";
import { Camera, type CameraPermissionState } from "@capacitor/camera";
import { Capacitor, type PermissionState } from "@capacitor/core";
import { type MicrophonePermissionState } from '@mozartec/capacitor-microphone'
import { PushNotifications } from "@capacitor/push-notifications";

type State = {
  geolocationPermission: GeolocationPermissionStatus
  cameraPermission: CameraPermissionState
  photoPermission: CameraPermissionState
  pushNotificationPermission: PermissionState
  microphonePermission: MicrophonePermissionState
}

export const usePermissionStore = defineStore('permission', {
  state: (): State => {
    return {
      geolocationPermission: {
        location: 'denied',
        coarseLocation: 'denied',
      },
      cameraPermission: 'denied',
      photoPermission: 'denied',
      pushNotificationPermission: 'denied',
      microphonePermission: 'denied',
    }
  },
  actions: {
    async requestCameraPermission() {
      if (Capacitor.isNativePlatform()) {
        const permission = await Camera.requestPermissions({ permissions: ['camera', 'photos'] })
        this.cameraPermission = permission.camera
        this.photoPermission = permission.photos
      } else {
        await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        this.cameraPermission = 'granted'
      }
    },
    async requestGeolocationPermission() {
      if (Capacitor.isNativePlatform()) {
        const permission = await Geolocation.requestPermissions()
        this.geolocationPermission = permission
      } else {
        await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
        this.geolocationPermission = {
          location: 'granted',
          coarseLocation: 'granted',
        }
      }
    },
    async requestMicrophonePermission() {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      this.microphonePermission = 'granted'
    },
    async requestPushNotificationPermission() {
      const permission = await PushNotifications.requestPermissions()
      this.pushNotificationPermission = permission.receive
    },
    async getGeolocation(): Promise<{ latitude: number; longitude: number; }> {
      if (Capacitor.isNativePlatform()) {
        const { coords } = await Geolocation.getCurrentPosition({ enableHighAccuracy: true })
        return {
          latitude: coords.latitude,
          longitude: coords.longitude,
        }
      }

      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      return {
        latitude: (pos as any)?.coords?.latitude as number,
        longitude: (pos as any)?.coords?.longitude as number,
      }
    }
  },
  getters: {
    hasCameraPermission(): boolean {
      return ['granted', 'limited'].includes(this.cameraPermission)
    },
    hasGalleryPermission(): boolean {
      return ['granted', 'limited'].includes(this.photoPermission)
    },
    hasLocationPermission(): boolean {
      return this.geolocationPermission.location === 'granted' || this.geolocationPermission.coarseLocation === 'granted'
    },
    hasMicrophonePermission(): boolean {
      return this.microphonePermission === 'granted'
    },
    hasPushNotificationPermission(): boolean {
      return this.pushNotificationPermission === 'granted'
    }
  }
})
