<template>
  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <h2>ภาพรวม</h2>
      <router-link v-if="hasCreatePermission" :to="{ name: 'IncidentManageView' }" class="ml-2">
        <ButtonDefaultComponent :classEnumName="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.SMALL" classTagName="w-full" title="สร้างใบแจ้งเหตุ" is-visible />
      </router-link>
    </div>
  </div>

  <div class="flex flex-col md:flex-row gap-2">
    <div v-for="group in incidentGroup" :key="group.key" class="ttp-layout md:w-1/2 w-full">
      <div class="ttp-page-header-2 flex flex-col gap-0">
        <!-- Header -->
        <div class="flex w-full justify-center items-center border-b-2 p-5">
          <div class="w-full text-gray-800 ttp-text-base font-semibold text-left">
            {{ t(`page.OverviewView.group.${group.key}`) }}
          </div>
          <router-link
            :to="{
              name: 'IncidentView',
            }"
            class="w-full text-right text-indigo-900 ttp-text-sm font-semibold"
          >
            ดูทั้งหมด
          </router-link>
        </div>

        <!-- Tab -->
        <div class="flex gap-2 w-full border-b-2 pt-2">
          <div
            v-for="tab in group.tabs"
            :key="tab.key"
            :class="{
              'text-indigo-900': tab.key === group.activeTab,
              'text-gray-500': tab.key !== group.activeTab,
            }"
            class="w-full ttp-text-sm font-semibold"
          >
            <a href="#" class="flex gap-2 justify-center items-center px-3 h-10" @click="setActiveTab(group.key, tab.key)">
              <component :is="tab.key === ENUM_INCIDENT_STATUS.NEW ? BellIcon : ClipboardDocumentCheckIcon" class="inline-block ttp-icon-inside-box-03" />
              {{ t(`page.OverviewView.tab.${tab.key}`) }}
            </a>
            <div v-if="tab.key === group.activeTab" class="self-stretch h-[0px] border-2 border-indigo-900" />
          </div>
        </div>

        <!-- Content -->
        <template v-for="tab in group.tabs">
          <div v-if="group.activeTab === tab.key" :key="tab.key" class="h-[320px] overflow-auto w-full border-b-2">
            <div v-if="tab.loading" class="flex flex-col h-full justify-center items-center">
              <div class="flex gap-2">
                <LoadingFormComponent class="m-auto" />
                <div>{{ $t("layout.loading") }}</div>
              </div>
            </div>
            <div v-else-if="!tab.data.total">
              <div class="text-center">
                <BookOpenIcon class="inline-block m-auto ttp-icon-box-01" />
                <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
              </div>
            </div>
            <div v-else v-for="item in tab.data.items" :key="item.id as string" class="flex flex-col md:flex-row gap-2 p-2 w-full justify-center items-center border-b-2">
              <div class="w-[100px]">
                <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                  <UserIcon class="inline-block ttp-icon-inside-box-02" />
                </div>
              </div>

              <div class="w-full">
                <template v-if="item.incidentCategory?.length">
                  <div class="text-indigo-800 ttp-text-base font-semibold text-left">
                    <template v-if="item.incidentCategory?.length">
                      {{ masterDataStore.getIncidentCategoryByCodeAndLocale(item.incidentCategory, ENUM_FIELD_LOCALE.TH) }}
                    </template>
                    <template v-else>
                      {{ masterDataStore.getIncidentFriendlyCategoryByCodeAndLocale(item.incidentFriendlyCategory, ENUM_FIELD_LOCALE.TH) }}
                    </template>
                  </div>
                </template>
                <div class="text-gray-600 text-opacity-90 ttp-text-xs font-medium text-left">
                  <IdentificationIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_no") }} {{ item.incidentNumber }}</span>
                </div>
                <div class="text-gray-600 text-opacity-90 ttp-text-xs font-normal text-left">
                  <UserCircleIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" />
                  <span class="ttp-text-xs font-medium ml-1">
                    {{ item.informerType === ENUM_INFORMER_TYPE.ANONYMOUS ? t("layout.anonymous") : item.informerName }}
                  </span>
                </div>
                <div class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left">
                  <ClockIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(item.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
                </div>
                <div class="text-left">
                  <BadgeDefaultComponent :title="$formattedStatus($classStatus(item.status))" :classEnumName="$classStatus(item.status)" :size="ENUM_SIZE.NORMAL" is-visible is-icon />
                </div>
              </div>
              <div class="w-[265px] flex flex-col items-center justify-center">
                <img :src="item.locale ? (getFlagImage(item.locale) || '') : ''" width="64" height="auto" />
                <router-link
                  :to="{
                    name: hasCreatePermission && !isSuppervisor ? 'IncidentManageView' : 'IncidentNoticeView',
                    params: { id: item.id },
                  }"
                  class="mt-4 w-[128px]"
                >
                  <ButtonDefaultComponent :title="t('btn.btn_manage')" :classEnumName="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.SMALL" classTagName="w-full" is-visible is-icon>
                    <template #icon>
                      <PencilSquareIcon v-if="hasCreatePermission && !isSuppervisor" class="ttp-icon-inside-box-03 inline-block" />
                      <EyeIcon v-else class="ttp-icon-inside-box-03 inline-block" />
                    </template>
                  </ButtonDefaultComponent>
                </router-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">
          {{ $convertNumber(incidentReport?.ACCEPTED) }}
        </div>
        <div class="ttp-text-xs">รับแจ้งเหตุแล้ว</div>
        <div class="ttp-text-xs">(Accepted)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-orange-400 ttp-text-xl font-bold">
          {{ $convertNumber(incidentReport?.WAITING) }}
        </div>
        <div class="ttp-text-xs">รอดำเนินการ</div>
        <div class="ttp-text-xs">(Waiting)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-orange-400 ttp-text-xl font-bold">
          {{ $convertNumber(incidentReport?.IN_PROGRESS) }}
        </div>
        <div class="ttp-text-xs">กำลังดำเนินการ</div>
        <div class="ttp-text-xs">(In Progress)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-green-600 ttp-text-xl font-bold">
          {{ $convertNumber(incidentReport?.COMPLETED) }}
        </div>
        <div class="ttp-text-xs">แก้ไขเรียบร้อย</div>
        <div class="ttp-text-xs">(Completed)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-gray-800 ttp-text-xl font-bold">
          {{ $convertNumber(incidentReport?.REPORTED) }}
        </div>
        <div class="ttp-text-xs">รับรายงาน</div>
        <div class="ttp-text-xs">(Reported)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-gray-800 ttp-text-xl font-bold">
          {{ $convertNumber(incidentReport?.CLOSED) }}
        </div>
        <div class="ttp-text-xs">ปิดงาน</div>
        <div class="ttp-text-xs">(Closed)</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { io } from "socket.io-client";
import { computed, onBeforeUnmount, onMounted } from "vue";
import { PERMISSION_INCIDENT_CREATE } from "@/configs/permission";
import { ENUM_COLOR, ENUM_FIELD_LOCALE, ENUM_FIELD_ROLE, ENUM_INCIDENT_STATUS, ENUM_INCIDENT_TYPE, ENUM_INFORMER_TYPE, ENUM_SIZE } from "@/configs/enums/enum";
import { useOverviewStore, type IncidentGroup } from "@/stores/PoliceCommandCenter/useOverviewStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useI18n } from "vue-i18n";
import { BellIcon, ClipboardDocumentCheckIcon, ClockIcon, EyeIcon, IdentificationIcon, PencilSquareIcon, UserCircleIcon, UserIcon } from "@heroicons/vue/24/solid";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import { BookOpenIcon } from "@heroicons/vue/24/outline";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import type { SimpleReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";

const store = useOverviewStore();
const authStore = useAuthStore();
const masterDataStore = useMasterDataStore();
const { t } = useI18n();

const socket = io(import.meta.env.VITE_INCIDENT_SOCKET_URL, {
  path: "/incident",
  secure: true,
  transports: ["websocket", "polling"],
  forceNew: true,
  upgrade: true,
});

const hasCreatePermission = computed(() => PERMISSION_INCIDENT_CREATE.includes(authStore.displayRole as ENUM_FIELD_ROLE));
const isSuppervisor = computed(() => ENUM_FIELD_ROLE.SUPERVISOR === authStore.displayRole);
const incidentGroup = computed((): IncidentGroup[] => store.incidentGroup);
const incidentReport = computed((): SimpleReport => store.incidentReport);

const setActiveTab = (group: ENUM_INCIDENT_TYPE, activeTab: ENUM_INCIDENT_STATUS) => {
  store.setActiveTab(group, activeTab);
  store.fetch(group, activeTab, 1);
};

const getFlagImage = (locale: string) => {
  switch (locale) {
    case "TH":
      return "https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/th.svg";
    case "EN":
      return "https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb-eng.svg";
    case "CN":
      return "https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/cn.svg";
    case "JP":
      return "https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg";
    case "KR":
      return "https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg";
    case "RU":
      return "https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/ru.svg";
    default:
      return null;
  }
};

onMounted(() => {
  store.fetch(ENUM_INCIDENT_TYPE.DEFAULT, ENUM_INCIDENT_STATUS.NEW, 1);
  store.fetch(ENUM_INCIDENT_TYPE.SOS, ENUM_INCIDENT_STATUS.NEW, 1);
  store.report();

  socket.connect();
  socket.on("updated", () => {
    Promise.all(
      incidentGroup.value.map((group) => {
        const currentPage = group.tabs?.find(({ key }) => key === group.activeTab)?.data?.page || 1;
        return store.fetch(group.key, group.activeTab, currentPage);
      })
    );
  });
});

onBeforeUnmount(() => {
  socket.off("updated");
  socket.disconnect();
});
</script>
