import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetAlert } from "@/configs/types/Alert/typeAlert";
import type { typeGetPagination } from "@/configs/types/Shared/typePagination";

export class fetchAlert {
  async List(params: object): Promise<typeGetPagination<typeGetAlert>> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get("/v1/alerts", { params });
    return data;
  }
  async Get(params: object): Promise<typeGetAlert> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get("/v1/alert", { params });
    return data;
  }
  async GetById(id: string): Promise<typeGetAlert> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get(`/v1/alert/${id}`);
    return data;
  }
}
