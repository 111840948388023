import { convertToValues, convertToNumber, removeLocalStorage } from "@/configs/helpers/MainHelper";
import type { typeGetRegisterUser } from "@/configs/types/User/typeRegisterUser";
import { defineStore } from "pinia";
import * as yup from "yup";
import YupPassword from "yup-password";
import { auth } from "@/configs/plugins/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { AUTH_TOKEN_KEY, useAuthStore } from "./useAuthStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";

YupPassword(yup);

const storeName = `useRegisterStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  email: {
    is: true,
    name: `email`,
    label: `form.email.label`,
    placeholder: `form.email.placeholder`,
    value: null,
  },
  contactNumber: {
    is: true,
    name: `guest.contactNumber`,
    label: `form.phone.label`,
    placeholder: `form.phone.placeholder`,
    value: null,
  },
  newPassword: {
    is: true,
    type: "password",
    name: `newPassword`,
    label: `form.newPassword.label`,
    placeholder: `form.newPassword.placeholder`,
    value: null,
  },
  repeatPassword: {
    is: true,
    type: "password",
    name: `repeatPassword`,
    label: `form.repeatPassword.label`,
    placeholder: `form.repeatPassword.placeholder`,
    value: null,
  },
  locale: {
    is: true,
    name: `locale`,
    label: `form.locale.label`,
    placeholder: `form.locale.placeholder`,
    value: null,
  },
};

export const useRegisterStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as typeGetRegisterUser,
    provider: null,
    uid: null,
    error: {} as { [key: string]: string | null },
    validationSchema: {
      email: yup.object().shape({
        email: isRequired.email.is ? yup.string().nullable().required().email() : yup.string().nullable().notRequired().email(),
        contactNumber: isRequired.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        newPassword: yup.string().min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร").minLowercase(1).minUppercase(1).minNumbers(1),
        repeatPassword: yup.string().when(["newPassword"], (newPassword, schema) => {
          return newPassword[0] !== undefined ? schema.oneOf([yup.ref("newPassword")], "รหัสผ่านไม่ตรงกัน").required("กรุณายืนยันรหัสผ่าน") : schema.notRequired();
        }),
      }),
      thirdParty: yup.object().shape({
        email: isRequired.email.is ? yup.string().nullable().required().email() : yup.string().nullable().notRequired().email(),
        contactNumber: isRequired.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      }),
    },
  }),
  actions: {
    async onSubmit() {
      if (this.provider && this.provider !== "EMAIL") {
        return {
          status: true,
          error: null,
        }
      }

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.email.validate(this.result, {
          abortEarly: false,
        });

        const { status, error } = await this.loginFirebase(this.result)

        if (error) {
          this.error.email = 'Email has been used. Please use other email or try to login.'
        }

        // Login
        return {
          status,
          error,
        };
      } catch (e: any) {
        (e.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return {
          status: false,
          error: e,
        };
      }
    },
    async loginFirebase(data: typeGetRegisterUser): Promise<{ status: boolean, error: any }> {
      this.loading = true;
      if (!data.email) {
        return {
          status: false,
          error: null,
        }
      }

      try {
        await signInWithEmailAndPassword(auth, data.email, data.repeatPassword)
        const status = await this.loginTourist(data, true)
        return {
          status,
          error: null,
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }

      this.loading = true;
      try {
        const status = await this.registerFirebase(data)
        return {
          status,
          error: null,
        }
      } catch(e) {
        console.error('failed to register', e)
        return {
          status: false,
          error: e,
        }
      } finally {
        this.loading = false;
      }
    },
    async loginTourist(data: typeGetRegisterUser, isLastStep: boolean) {
      if (!data.email) {
        return false
      }

      this.loading = true;

      const authStore = useAuthStore();

      try {
        await authStore.login({
          email: data.email,
          password: data.repeatPassword,
          rememberMe: false,
        });

        return isLastStep
      } catch (e) {
        throw e
      } finally {
        this.loading = false
      }
    },
    async registerFirebase(data: typeGetRegisterUser) {
      if (!data.email) {
        return false
      }

      await createUserWithEmailAndPassword(auth, data.email, data.repeatPassword)
      return true
    },
    async logout() {
      const authStore = useAuthStore();
      await auth.signOut();

      authStore.token = null;
      authStore.user = null;

      removeLocalStorage(AUTH_TOKEN_KEY);

      window.location.href = window.location.href;
    },
  },
});
