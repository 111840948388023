import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { IncidentInformation, IncidentReport, typeGetIncident } from "@/configs/types/Incident/typeIncident";
import type { typeIncidentAssignee } from "@/configs/types/Incident/typeIncidentAssignee";
import type { Paginate } from "@/configs/types/Shared/typePagination";
import { defineStore } from "pinia";

export type GetIncidentQuery = {
  status?: string
  page?: number
  size?: number
}

export type Incident = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: any
  incidentNumber: string
  informerType: string
  informerName: string
  contactNumber: string
  incidentCategory: IncidentCategory
  province: Province
  responsible?: Responsible
  status: string
}

type Responsible = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: any
  email: string
  idCard: string
  titleCode: string
  role: string
  type: string
  status: string
  content: {
    locale: string
    firstName: string
    lastName: string
    shortName: string
    fullName: string
  }[]
  locales: any
  hasPassword: boolean
  image: string
  gdccUserID: string
  gender: string
  contactNumber: string
  title: any
  organization: any
  incidents: any
  devices: any
  responsibleAreas: any
  safetyStandardAppraisalResults: any
  organizationCode?: string
  isAgent?: boolean
}

type IncidentCategory = {
  code: string
  content: Content[]
}

type Content = {
  locale: string
  title: string
}

type Province = {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: any
  name: Name
  iso3166: number
}

type Name = {
  EN: string
  TH: string
}

export const contentToTitle = (content: Content[], locale: string): string | null => {
  if (!content.length) {
    return null
  }

  let c = content.find(c => c.locale === locale)
  if (!c) {
    c = content[0]
  }

  return c.title
}

const axios = axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT)

export const useIncidentStore = defineStore('useIncidentStore', {
  state: (): { incident?: typeGetIncident } => ({
    incident: undefined
  }),
  actions: {
    async getIncidents(params: GetIncidentQuery = {}): Promise<Paginate<Incident>> {
      const { data } = await axios.get<Paginate<Incident>>('v1/incidents', {
        params
      })

      return data
    },
    async findIncident(id: string): Promise<typeGetIncident> {
      const { data } = await axios.get<typeGetIncident>(`v1/incidents/${id}`)
      this.incident = data
      return data
    },
    async updateProgress(
      id: string,
      status: ENUM_INCIDENT_STATUS,
      detail?: string,
      assigneeID?: string,
      metadata?: { [key: string]: string },
    ): Promise<typeIncidentAssignee> {
      const { data } = await axios.post<typeIncidentAssignee>(`v1/incidents/${id}/progress`, {
        status,
        assigneeID,
        detail,
        metadata,
      })

      return data
    },
    async putReport(
      id: string,
      detail: string,
      images: string[],
    ): Promise<IncidentReport> {
      const { data } = await axios.put<IncidentReport>(`v1/incidents/${id}/report`, {
        detail,
        images,
      })

      return data
    },
    async updateLocation(
      id: string,
      latitude: number,
      longitude: number,
    ): Promise<IncidentInformation> {
      const { data } = await axios.post<IncidentInformation>(`v1/incidents/${id}/information/location`, {
        latitude,
        longitude,
      })

      return data
    }
  }
})
