import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { Paginate } from "@/configs/types/Shared/typePagination";
import { defineStore } from "pinia";

export type ListFilter = {
  distance?: number;
  latitude?: number;
  longitude?: number;
  keyword?: string;
  page?: number;
  size?: number;
};

export type Activity = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  refCreatedAt: string;
  refUpdatedAt: string;
  locale: string;
  name: string;
  description: string;
  type: string[];
  images: string[];
  videos: string[];
  touristTypes: string[];
  maximumCapacity: number;
  eventStatus: string;
  address: string;
  proviceID?: number;
  latitude?: number;
  longitude?: number;
  contactNumber: string;
  email: string;
  contactChannels: { name: string; url: string }[];
  distance: number;
};

export const storeName = `useActivityStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;
export const useActivityStore = defineStore(storeName, {
  state: () => ({}),
  actions: {
    async list(params: ListFilter, locale = "EN"): Promise<Paginate<Activity>> {
      const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<Paginate<Activity>>("/v1/activities", { params: { ...params }, headers: { "Accept-Language": locale } });

      return data;
    },
    async get(id: string, locale = "EN"): Promise<Activity> {
      const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<Activity>(`/v1/activities/${id}`, { headers: { "Accept-Language": locale } });

      return data;
    },
  },
});
