<script lang="ts">
import { defineComponent } from "vue";
import { useRegisterStore } from "./stores/useRegisterStore";
import { useResetPasswordStore } from "./stores/useResetPasswordStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { AUTH_TOKEN_KEY, useAuthStore } from "./stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useProfileEditTouristStore } from "../Tourist/Profile/stores/useProfileEditTouristStore";
import { useRegisterInformationStore } from "./stores/useRegisterInformationStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { storeName } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";

export default defineComponent({
  name: "ForgotPasswordNewPasswordView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          popup: {
            success: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.resetPasswordStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.confirm_detail"),
                functionProp: () => {
                  this.resetPasswordStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.resetPasswordStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            caution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.resetPasswordStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.error_detail"),
                functionProp: () => {
                  this.resetPasswordStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.resetPasswordStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
          input: {
            newPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.resetPasswordStore.error.newPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isDisable: this.loading(),
                isRequired: this.resetPasswordStore.isRequired.newPassword.is,
                textError: this.$t(this.resetPasswordStore.isRequired.newPassword.placeholder),
                title: this.$t(this.resetPasswordStore.isRequired.newPassword.label),
                placeholder: this.$t(this.resetPasswordStore.isRequired.newPassword.placeholder),
                value: this.resetPasswordStore.result.newPassword,
                name: this.resetPasswordStore.isRequired.newPassword.name,
                type: this.resetPasswordStore.isRequired.newPassword.type,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.resetPasswordStore.result.newPassword = value;
              },
              onType: () => {
                this.resetPasswordStore.isRequired.newPassword.type = this.resetPasswordStore.isRequired.newPassword.type == "password" ? "text" : "password";
              },
            },
            repeatPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.resetPasswordStore.error.repeatPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isDisable: this.loading(),
                isRequired: this.resetPasswordStore.isRequired.repeatPassword.is,
                textError: this.$t(this.resetPasswordStore.isRequired.repeatPassword.placeholder),
                title: this.$t(this.resetPasswordStore.isRequired.repeatPassword.label),
                placeholder: this.$t(this.resetPasswordStore.isRequired.repeatPassword.placeholder),
                value: this.resetPasswordStore.result.repeatPassword,
                name: this.resetPasswordStore.isRequired.repeatPassword.name,
                type: this.resetPasswordStore.isRequired.repeatPassword.type,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: string) => {
                this.resetPasswordStore.result.repeatPassword = value;
              },
              onType: () => {
                this.resetPasswordStore.isRequired.repeatPassword.type = this.resetPasswordStore.isRequired.repeatPassword.type == "password" ? "text" : "password";
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                const result = await this.resetPasswordStore.onSubmit(e);
                if (result) {
                  const auth = getAuth();
                  signInWithCustomToken(auth, result?.token).then(async (userCredential) => {
                    this.authStore.token = result?.token;
                    const user = userCredential.user;

                    await setLocalStorage(AUTH_TOKEN_KEY, result?.token);
                    await setLocalStorage(`${storeName}:getProjectType`, this.$getProjectType());
                    await setLocalStorage(
                      AUTH_TOKEN_KEY,
                      JSON.stringify({
                        email: user?.email,
                        phoneNumber: user?.phoneNumber,
                        displayName: user?.displayName,
                        provider: "EMAIL",
                      })
                    );

                    this.$router.push({ name: 'HomeView' })
                  });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.resetPasswordStore.loading;
    },
    async fetch() {
      this.profileEditTouristStore.loading = true;

      await Promise.all([
        (async () => {
          const fetch = new fetchAuth();
          const user = await fetch.Get();
          this.profileEditTouristStore.result = user;
        })(),
      ]);
      this.profileEditTouristStore.loading = false;
    },
    async reset() {
      await this.profileEditTouristStore.$reset();
      await this.authStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const registerStore = useRegisterStore();
    const resetPasswordStore = useResetPasswordStore();
    const profileEditTouristStore = useProfileEditTouristStore();
    const registerInformationStore = useRegisterInformationStore();
    const masterDataStore = useMasterDataStore();
    const authStore = useAuthStore();
    return {
      registerStore,
      resetPasswordStore,
      profileEditTouristStore,
      registerInformationStore,
      masterDataStore,
      authStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">Forgot Password</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl">{{ $t("page.ForgotPasswordView.create") }}</h1>
                <div>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.newPassword.component" v-bind="defaultFormComponents.form.input.newPassword.props" @updateValue="defaultFormComponents.form.input.newPassword.onUpdateValue" @type="defaultFormComponents.form.input.newPassword.onType" />
                    <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ $t("caution.password_1") }}</small>
                    <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.repeatPassword.component" v-bind="defaultFormComponents.form.input.repeatPassword.props" @updateValue="defaultFormComponents.form.input.repeatPassword.onUpdateValue" @type="defaultFormComponents.form.input.repeatPassword.onType" />
                  </div>
                  <div class="ttp-between border-t-2 pt-4">
                    <div class="flex gap-2 w-full">
                      <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="resetPasswordStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.popup.success.component" v-bind="defaultFormComponents.form.popup.success.props" />
  <component v-else-if="resetPasswordStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.popup.caution.component" v-bind="defaultFormComponents.form.popup.caution.props" />
</template>
