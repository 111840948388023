<script lang="ts">
import { defineComponent } from "vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $enum, $string } from "@/configs/types/Shared/typeShare";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useRegisterInformationStore } from "./stores/useRegisterInformationStore";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useRegisterStore } from "./stores/useRegisterStore";
import type { ENUM_GENDER } from "@/configs/enums/enum";
import { useAuthStore } from "./stores/useAuthStore";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";

export default defineComponent({
  name: "RegisterInformationView",
  components: {
    ButtonDefaultComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
      profile_image: "" as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            name: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: true,
                isSpecial: false,
                isVisible: true,
                isError: (this.registerInformationStore.error.name || "").length > 0,
                isDisable: this.loading(),
                isRequired: this.registerInformationStore.isRequired.name.is,
                textError: this.$t(this.registerInformationStore.isRequired.name.placeholder),
                title: `${this.$t(this.registerInformationStore.isRequired.name.label)} (English Only)`,
                placeholder: this.$t(this.registerInformationStore.isRequired.name.placeholder),
                value: this.registerInformationStore.result.name,
                name: this.registerInformationStore.isRequired.name.name,
                isKeydownEnglish: true,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: string) => {
                this.registerInformationStore.result.name = value;
              },
            },
            identificationNumber: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: true,
                isSpecial: false,
                isVisible: this.registerInformationStore.hiddenFields.identificationNumber,
                isError: (this.registerInformationStore.error[this.registerInformationStore.isRequired.identificationNumber.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.registerInformationStore.hiddenFields.identificationNumber,
                textError: this.$t(this.registerInformationStore.isRequired.identificationNumber.placeholder),
                title: this.$t(this.registerInformationStore.isRequired.identificationNumber.label),
                placeholder: this.$t(this.registerInformationStore.isRequired.identificationNumber.placeholder),
                value: this.registerInformationStore?.result?.identificationNumber,
                name: this.registerInformationStore.isRequired.identificationNumber.name,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerInformationStore.result.identificationNumber = value;
              },
            },
          },
          select: {
            nationality: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: true,
                isSpecial: false,
                isVisible: !!this.registerInformationStore.registerForm["nationality"],
                isError: (this.registerInformationStore.error[this.registerInformationStore.isRequired.nationality.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: !!this.registerInformationStore.registerForm["nationality"] && this.registerInformationStore.isRequired.nationality.is,
                textError: this.$t(this.registerInformationStore.isRequired.nationality.placeholder),
                title: this.$t(this.registerInformationStore.isRequired.nationality.label),
                placeholder: this.$t(this.registerInformationStore.isRequired.nationality.placeholder),
                value: this.registerInformationStore?.result?.nationality,
                items: this.masterDataStore.itemNationalities(),
                name: this.registerInformationStore.isRequired.nationality.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerInformationStore.result.nationality = value;
              },
            },
            country: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: true,
                isSpecial: false,
                isVisible: !!this.registerInformationStore.registerForm["country"],
                isError: (this.registerInformationStore.error[this.registerInformationStore.isRequired.country.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: !!this.registerInformationStore.registerForm["country"] && this.registerInformationStore.isRequired.country.is,
                textError: this.$t(this.registerInformationStore.isRequired.country.placeholder),
                title: this.$t(this.registerInformationStore.isRequired.country.label),
                placeholder: this.$t(this.registerInformationStore.isRequired.country.placeholder),
                value: this.registerInformationStore?.result?.country,
                items: this.masterDataStore.itemCountries(),
                name: this.registerInformationStore.isRequired.country.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerInformationStore.result.country = value;
              },
            },
            gender: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: true,
                isSpecial: false,
                isVisible: true,
                isError: (this.registerInformationStore.error[this.registerInformationStore.isRequired.gender.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.registerInformationStore.isRequired.gender.is,
                textError: this.$t(this.registerInformationStore.isRequired.gender.placeholder),
                title: this.$t(this.registerInformationStore.isRequired.gender.label),
                placeholder: this.$t(this.registerInformationStore.isRequired.gender.placeholder),
                value: this.registerInformationStore?.result?.gender,
                items: this.masterDataStore.itemGenders(),
                name: this.registerInformationStore.isRequired.gender.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: ENUM_GENDER | $enum) => {
                this.registerInformationStore.result.gender = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_submit"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                if (e) e.preventDefault();
                await this.registerInformationStore
                  .onSubmit(e)
                  .then(async (status: any) => {
                    if (status) {
                      this.$router.push({ name: "TermConditionsView" });
                      // this.registerStore.loading = false;
                    }
                  })
                  .catch((error) => {
                    console.error("error", error);
                  });
              },
            },
            btnUploadImage: {
              click: () => {
                (document?.getElementById("file") as HTMLButtonElement).click();
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async uploadImage(e: any) {
      let listFiles = e.target.files;

      if (!listFiles?.length) {
        return;
      }

      const files: File[] = [];
      for (const f of listFiles) {
        files.push(f);
      }

      const f = new fetchFile();
      const response = await f.Upload(files);
      this.registerStore.result.image = response[0].id;
      this.profile_image = response[0].url;

      this.$configLightbox();

      e.target.value = "";
    },
    loading() {
      return this.registerInformationStore.loading;
    },
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const registerStore = useRegisterStore();
    const registerInformationStore = useRegisterInformationStore();
    const sharedStore = useSharedStore();

    registerStore.loading = false;

    // Hidden
    registerInformationStore.getHiddenFields();

    return {
      authStore,
      registerInformationStore,
      masterDataStore,
      registerStore,
      sharedStore,
    };
  },
  async created() {
    this.masterDataStore.listNationality();
    this.masterDataStore.listCountry();
    this.registerInformationStore.getRegisterForm();

    if (this.authStore.registerProfile && this.authStore.registerProfile.provider !== "EMAIL") {
      this.registerStore.provider = this.authStore.registerProfile.provider;
      this.registerStore.uid = this.authStore.registerProfile.uid;
      this.registerStore.result.email = this.registerStore.result.email || this.authStore.registerProfile.email;
      this.registerStore.result.contactNumber = this.registerStore.result.contactNumber || this.authStore.registerProfile.phoneNumber;
      this.registerInformationStore.result.name = this.authStore.registerProfile.displayName;
    }

    if (!this.registerStore.result.email) this.$router.push({ name: "RegisterView" });
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">{{ $t("page.RegisterView.title") }}</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl">{{ $t("page.RegisterView.create") }}</h1>
                <p class="text-black">{{ $t("page.RegisterView.login") }} <RouterLink :to="{ name: 'LoginView' }" class="w-full m-auto">Login</RouterLink></p>
                <div>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <div class="flex items-center justify-center col-start-1 col-end-13 md:col-start-1 md:col-end-13 mb-6">
                      <div class="swipe-photo relative inline-flex h-36 w-36 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                        <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block h-20 w-20" />
                        <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center w-36 h-36 max-w-full rounded-full float-right" :src="profile_image" alt="" /></a>
                        <input id="file" type="file" accept="image/*" hidden @change="uploadImage" />
                        <div @click="defaultFormComponents.form.button.btnUploadImage.click" class="cursor-pointer text-center absolute bottom-0 right-0 bg-gray-100 rounded-full bg-ttp-primary ttp-icon-inside-box-02"><component :is="$solidIcons.PencilIcon" class="inline-block ttp-icon-inside-box-03 text-white" /></div>
                      </div>
                    </div>
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.name.component" v-bind="defaultFormComponents.form.input.name.props" @updateValue="defaultFormComponents.form.input.name.onUpdateValue" />
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.nationality.component" v-bind="defaultFormComponents.form.select.nationality.props" @updateValue="defaultFormComponents.form.select.nationality.onUpdateValue" />
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-7'" :is="defaultFormComponents.form.select.country.component" v-bind="defaultFormComponents.form.select.country.props" @updateValue="defaultFormComponents.form.select.country.onUpdateValue" />
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-7 md:col-end-13'" :is="defaultFormComponents.form.select.gender.component" v-bind="defaultFormComponents.form.select.gender.props" @updateValue="defaultFormComponents.form.select.gender.onUpdateValue" />
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.identificationNumber.component" v-bind="defaultFormComponents.form.input.identificationNumber.props" @updateValue="defaultFormComponents.form.input.identificationNumber.onUpdateValue" />
                  </div>
                  <div class="ttp-between border-t-2 pt-4">
                    <div class="flex gap-2 w-full">
                      <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
