<script lang="ts">
import { defineComponent } from "vue";
import Swal from "sweetalert2";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";

import { useAuthStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { useHistorySearchStore } from "@/views/PoliceTouristAndAgent/Tourist/History/stores/useHistorySearchStore";
import { useChatFormStore } from "@/views/PoliceTouristAndAgent/Tourist/Chat/stores/useChatFormStore";

import VoiceDialog from "@/components/Tourist/VoiceDialog.vue";
import HungUpDialog from "@/components/Tourist/HungUpDialog.vue";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import { usePermissionStore } from "@/stores/PoliceTouristAndAgent/usePermissionStore";

export default defineComponent({
  name: "PageFooterComponent",
  components: {
    VoiceDialog,
    HungUpDialog,
  },
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  methods: {
    async fetch() {
      if (this.$route.params.id) {
        const result = await this.historySearchStore.fetchIncident.Get(this.$route.params.id as string);
        this.historySearchStore.result = result;
      }

      this.historySearchStore.loading = false;
    },
    loading() {
      return this.historySearchStore.loading;
    },
    async reset() {
      await this.historySearchStore.$reset();
    },
    async setting() {
      await this.reset();
    },
    async makeSOSCall() {
      await Promise.all([this.permissionStore.requestMicrophonePermission(), this.permissionStore.requestGeolocationPermission()]);

      if (!this.permissionStore.hasMicrophonePermission || !this.permissionStore.hasLocationPermission) {
        this.sharedStore.isShowPermission = true
        this.sharedStore.isLoadingPermission = false;
      }

      const { isConfirmed } = await Swal.fire({
        imageUrl: "../../../images/icon-sos-modal.png",
        title: `<p class="text-[#2C3483] leading-9">${this.$t("sos.title")}</p>`,
        html: `
          <ol class="list-decimal list-outside text-[#CC2B34] text-left pl-6 leading-6">
            ${(this.$tm("sos.conditions") as any).map((s: any) => `<li>${s}</li>`).join("")}
          </ol>
          <div class="flex border border-[#FAA500] rounded-lg bg-[#FFD88C94] px-4 py-6 mt-6">
            <div>
              <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4847 1.70861C9.74696 -0.569537 12.9047 -0.569538 14.167 1.70861L22.2079 16.2209C23.4694 18.4978 21.8913 21.343 19.3667 21.343H3.28495C0.760426 21.343 -0.817753 18.4978 0.44381 16.2209L8.4847 1.70861ZM11.3261 7.68157C11.779 7.68157 12.1461 8.06384 12.1461 8.5354V12.8046C12.1461 13.2761 11.779 13.6584 11.3261 13.6584C10.8732 13.6584 10.5061 13.2761 10.5061 12.8046V8.5354C10.5061 8.06384 10.8732 7.68157 11.3261 7.68157ZM11.3261 17.0737C11.779 17.0737 12.1461 16.6914 12.1461 16.2199C12.1461 15.7483 11.779 15.3661 11.3261 15.3661C10.8732 15.3661 10.5061 15.7483 10.5061 16.2199C10.5061 16.6914 10.8732 17.0737 11.3261 17.0737Z" fill="#FAA500"/>
              </svg>
            </div>
            <p class="color-[#545454] text-left ml-4">${this.$t("sos.description")}</p>
          </div>`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: `
          <span class="text-white inline-flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
            </svg>
            ${this.$t("sos.button.confirm")}
          </span>
        `,
        confirmButtonColor: "#CC2B34",
        cancelButtonText: `
          <span class="text-[#2C3483]">${this.$t("sos.button.cancel")}</span>
        `,
        cancelButtonColor: "#E9F0FF",
      });

      if (isConfirmed) {
        let information = {}
        if (this.permissionStore.hasLocationPermission) {
          const coord = await this.permissionStore.getGeolocation()
          information = {
            latitude: coord.latitude,
            longitude: coord.longitude,
          }
        }

        try {
          const result = await this.chatFormStore.fetchIncident.CreateIncident({
            type: "SOS",
            information,
          });

          (this.$refs as any).voice.start(result);

          this.sharedStore.isShowPermission = false;
        } catch (e) {
        } finally {
          this.sharedStore.isLoadingPermission = false;
        }
      }
    },
    hungUp({ incident, counter }: { incident: typeGetIncident; counter: number }) {
      (this.$refs as any).hungUp.open({ incident, counter });
    },
  },
  async mounted() {
    await this.setting();
  },
  setup() {
    const sharedStore = useSharedStore();
    const chatFormStore = useChatFormStore();
    const authStore = useAuthStore();
    const historySearchStore = useHistorySearchStore();
    const permissionStore = usePermissionStore();

    return {
      sharedStore,
      chatFormStore,
      authStore,
      historySearchStore,
      permissionStore,
    };
  },
});
</script>

<template>
  <footer v-if="$inArray($getProjectType(), [$enums.ENUM_APP_CATEGORY_NAME.TOURIST]) && !$route?.meta?.hideFooter" id="page-footer" class="fixed w-full z-10 bottom-0 flex flex-none items-center lg:hidden shadow-md drop-shadow-md">
    <ul class="flex bg-gray-100 w-full text-center items-end justify-center">
      <li class="w-full py-3">
        <router-link
          :to="{ name: 'HomeView' }"
          :class="{
            'text-ttp-primary': $route.name == 'HomeView',
            'text-[#4E4E4E]': $route.name != 'HomeView',
          }"
        >
          <component v-if="$route.name != 'HomeView'" :is="$outlineIcons.HomeIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <component v-else :is="$solidIcons.HomeIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $route.name == 'HomeView',
            }"
          >
            {{ $t("layout.menu_bottom.home_title") }}
          </div>
        </router-link>
      </li>
      <li
        class="w-full py-3"
        :class="{
          'bg-ttp-primary': $route.name == 'PreChatView',
          'text-[#FFFFFF]': $route.name == 'PreChatView',
          'text-[#4E4E4E]': $route.name != 'PreChatView',
        }"
      >
        <router-link :to="{ name: 'PreChatView' }" class="flex flex-col justify-center items-center">
          <svg class="ttp-icon-inside-box-02" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_117_15553" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="78" height="78">
              <rect width="78" height="78" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_117_15553)">
              <path :fill="$route.name == 'PreChatView' ? '#FFFFFF' : '#4E4E4E'" d="M39 71.5005L38.1875 61.7505H37.375C29.6833 61.7505 23.1562 59.0692 17.7938 53.7067C12.4313 48.3442 9.75 41.8172 9.75 34.1255C9.75 26.4338 12.4313 19.9067 17.7938 14.5442C23.1562 9.18174 29.6833 6.50049 37.375 6.50049C41.2208 6.50049 44.8094 7.2182 48.1406 8.65361C51.4719 10.089 54.3969 12.0661 56.9156 14.5849C59.4344 17.1036 61.4115 20.0286 62.8469 23.3599C64.2823 26.6911 65 30.2797 65 34.1255C65 38.188 64.3365 42.088 63.0094 45.8255C61.6823 49.563 59.8677 53.0297 57.5656 56.2255C55.2635 59.4213 52.5281 62.3192 49.3594 64.9192C46.1906 67.5192 42.7375 69.713 39 71.5005ZM37.2938 51.9192C38.2146 51.9192 39 51.5942 39.65 50.9442C40.3 50.2942 40.625 49.5088 40.625 48.588C40.625 47.6672 40.3 46.8817 39.65 46.2317C39 45.5817 38.2146 45.2567 37.2938 45.2567C36.3729 45.2567 35.5875 45.5817 34.9375 46.2317C34.2875 46.8817 33.9625 47.6672 33.9625 48.588C33.9625 49.5088 34.2875 50.2942 34.9375 50.9442C35.5875 51.5942 36.3729 51.9192 37.2938 51.9192ZM34.9375 41.6005H39.8125C39.8125 39.9755 39.975 38.838 40.3 38.188C40.625 37.538 41.6542 36.3463 43.3875 34.613C44.3625 33.638 45.175 32.5817 45.825 31.4442C46.475 30.3067 46.8 29.088 46.8 27.788C46.8 25.0255 45.8656 22.9536 43.9969 21.5724C42.1281 20.1911 39.9208 19.5005 37.375 19.5005C34.9917 19.5005 32.9875 20.164 31.3625 21.4911C29.7375 22.8182 28.6 24.4297 27.95 26.3255L32.5 28.113C32.7708 27.1922 33.2854 26.2849 34.0437 25.3911C34.8021 24.4974 35.9125 24.0505 37.375 24.0505C38.8375 24.0505 39.9344 24.4567 40.6656 25.2692C41.3969 26.0817 41.7625 26.9755 41.7625 27.9505C41.7625 28.8713 41.4917 29.6974 40.95 30.4286C40.4083 31.1599 39.7583 31.9047 39 32.663C37.1042 34.288 35.9531 35.5744 35.5469 36.5224C35.1406 37.4703 34.9375 39.163 34.9375 41.6005Z" />
            </g>
          </svg>
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $route.name == 'PreChatView',
            }"
          >
            {{ $t("layout.menu_bottom.report_title") }}
          </div>
        </router-link>
      </li>
      <li class="w-full py-3">
        <button :style="{ height: '20vmin', ['margin-top']: '-20vmin' }" class="w-full rounded-full flex flex-col justify-center items-center bg-[#CC2B34] text-white" @click.prevent="makeSOSCall">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
          </svg>
          <div class="ttp-text-xs font-medium">
            {{ $t("page.HomeView.sos_btn") }}
          </div>
        </button>
      </li>
      <li class="w-full py-3">
        <router-link
          :to="{ name: 'HistoryPoliceTouristAndAgentView' }"
          :class="{
            'text-ttp-primary': $inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView']),
            'text-[#4E4E4E]': !$inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView']),
          }"
        >
          <component v-if="!$inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView'])" :is="$outlineIcons.ClockIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <component v-else :is="$solidIcons.ClockIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView']),
            }"
          >
            {{ $t("layout.menu_bottom.history_title") }}
          </div>
        </router-link>
      </li>
      <li class="w-full py-3">
        <router-link
          :to="{ name: 'SettingMenuView' }"
          :class="{
            'text-ttp-primary': $inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView']),
            'text-[#4E4E4E]': !$inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView']),
          }"
        >
          <component v-if="!$inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView'])" :is="$outlineIcons.UserIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <component v-else :is="$solidIcons.UserIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView']),
            }"
          >
            {{ $t("layout.menu_bottom.profile_title") }}
          </div>
        </router-link>
      </li>
    </ul>
  </footer>
  <VoiceDialog ref="voice" @hungUp="hungUp" />
  <HungUpDialog ref="hungUp" />
</template>
