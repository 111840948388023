<script lang="ts">
import { defineComponent } from "vue";

import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";

import { useTouristFormStore } from "./stores/useTouristFormStore";
import { useTouristDeleteStore } from "./stores/useTouristDeleteStore";
import { useTouristSearchStore } from "./stores/useTouristSearchStore";
import type { ENUM_GENDER } from "@/configs/enums/enum";
import type { $enum, $string } from "@/configs/types/Shared/typeShare";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

export default defineComponent({
  name: "TouristManageView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      profile_image: "" as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            name: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.name.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.name.is,
                textError: this.$t(this.touristUserFormStore.isRequired.name.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.name.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.name.placeholder),
                value: this.touristUserFormStore?.result?.name,
                name: this.touristUserFormStore?.isRequired?.name?.name,
                isKeydownText: true,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristUserFormStore.result.name = value;
              },
            },
            contactNumber: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.contactNumber.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.contactNumber.is,
                textError: this.$t(this.touristUserFormStore.isRequired.contactNumber.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.contactNumber.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.contactNumber.placeholder),
                value: this.touristUserFormStore?.result?.contactNumber,
                name: this.touristUserFormStore.isRequired.contactNumber.name,
                isKeydownNumber: true,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristUserFormStore.result.contactNumber = value;
              },
            },
            email: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.email.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.email.is,
                textError: this.$t(this.touristUserFormStore.isRequired.email.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.email.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.email.placeholder),
                value: this.touristUserFormStore?.result?.email,
                name: this.touristUserFormStore.isRequired.email.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristUserFormStore.result.email = value;
              },
            },
            identificationNumber: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.identificationNumber.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.identificationNumber.is,
                textError: this.$t(this.touristUserFormStore.isRequired.identificationNumber.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.identificationNumber.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.identificationNumber.placeholder),
                value: this.touristUserFormStore?.result?.identificationNumber,
                name: this.touristUserFormStore.isRequired.identificationNumber.name,
                isKeydownNumber: true,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristUserFormStore.result.identificationNumber = value;
              },
            },
          },
          select: {
            nationality: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.nationality.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.nationality.is,
                textError: this.$t(this.touristUserFormStore.isRequired.nationality.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.nationality.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.nationality.placeholder),
                value: this.touristUserFormStore?.result?.nationality,
                items: this.masterDataStore.itemNationalities(),
                name: this.touristUserFormStore.isRequired.nationality.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristUserFormStore.result.nationality = value;
              },
            },
            country: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.country.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.country.is,
                textError: this.$t(this.touristUserFormStore.isRequired.country.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.country.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.country.placeholder),
                value: this.touristUserFormStore?.result?.country,
                items: this.masterDataStore.itemNationalities(),
                name: this.touristUserFormStore.isRequired.country.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristUserFormStore.result.country = value;
              },
            },
            gender: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.touristUserFormStore.error[this.touristUserFormStore.isRequired.gender.name] || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.touristUserFormStore.isRequired.gender.is,
                textError: this.$t(this.touristUserFormStore.isRequired.gender.placeholder),
                title: this.$t(this.touristUserFormStore.isRequired.gender.label),
                placeholder: this.$t(this.touristUserFormStore.isRequired.gender.placeholder),
                value: this.touristUserFormStore?.result?.gender,
                items: this.masterDataStore.itemGenders(),
                name: this.touristUserFormStore.isRequired.gender.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: ENUM_GENDER | $enum) => {
                this.touristUserFormStore.result.gender = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: (this.$route.params.id && this.touristUserFormStore?.result?.responsibleID === null) || this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: (e: Event) => {
                this.touristUserFormStore.onSubmit(e);
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.touristUserFormStore.loading || this.deleteStore.loading || this.searchStore.loading;
    },
    async fetch() {
      this.touristUserFormStore.loading = true;
      if (this.$route.params.id) {
        await Promise.all([
          (async () => {
            const user = await this.touristUserFormStore.fetchTourist.Get(this.$route.params.id as string);
            this.touristUserFormStore.result = user;

            if (user.image) {
              const f = new fetchFile();
              this.profile_image = await f.GetUrl(user.image);
            }
          })(),
        ]);
      }
      this.touristUserFormStore.loading = false;
    },
    async reset() {
      await this.touristUserFormStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const touristUserFormStore = useTouristFormStore();
    const deleteStore = useTouristDeleteStore();
    const searchStore = useTouristSearchStore();
    const masterDataStore = useMasterDataStore();

    return {
      touristUserFormStore,
      deleteStore,
      searchStore,
      masterDataStore,
    };
  },
});
</script>

<template>
  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <h2>{{ $t(($route?.meta as any).i18n) }}</h2>
    </div>

    <form @submit="touristUserFormStore.onSubmit">
      <div class="grid grid-cols-12 gap-2 p-4">
        <div class="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4">
          <div class="text-gray-800 ttp-text-xs leading-tight">รูปภาพ</div>
        </div>
        <div class="flex col-start-1 col-end-13 md:col-start-1 md:col-end-13">
          <div class="inline-flex h-36 w-36 items-center justify-center rounded-lg bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
            <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-pencil-box" />
            <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center w-36 h-36 max-w-full rounded-full float-right" :src="profile_image" alt="" /></a>
          </div>
        </div>
        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.name.component" v-bind="defaultFormComponents.form.input.name.props" @updateValue="defaultFormComponents.form.input.name.onUpdateValue" />
        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.nationality.component" v-bind="defaultFormComponents.form.select.nationality.props" @updateValue="defaultFormComponents.form.select.nationality.onUpdateValue" />
        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.country.component" v-bind="defaultFormComponents.form.select.country.props" @updateValue="defaultFormComponents.form.select.country.onUpdateValue" />
        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.contactNumber.component" v-bind="defaultFormComponents.form.input.contactNumber.props" @updateValue="defaultFormComponents.form.input.contactNumber.onUpdateValue" />
        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.email.props" @updateValue="defaultFormComponents.form.input.email.onUpdateValue" />

        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.gender.component" v-bind="defaultFormComponents.form.select.gender.props" @updateValue="defaultFormComponents.form.select.gender.onUpdateValue" />
        <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.identificationNumber.component" v-bind="defaultFormComponents.form.input.identificationNumber.props" @updateValue="defaultFormComponents.form.input.identificationNumber.onUpdateValue" />
      </div>
    </form>
  </div>
</template>
